import "../Pages-4/Discounts.css";

const Discounts = () => {
  const discounts = [
    {
      discountPurpose: "Seasonal Sale",
      discountGross: "$20.00",
      amount: "$180.00",
      manualDiscount: "$10.00",
      couponId: "CPN001",
      title: "10% off",
      instanceId: "INST123",
    },
    {
      discountPurpose: "Holiday Discount",
      discountGross: "$15.00",
      amount: "$135.00",
      manualDiscount: "$5.00",
      couponId: "CPN002",
      title: "BOGO Offer",
      instanceId: "INST124",
    },
    {
      discountPurpose: "Black Friday",
      discountGross: "$30.00",
      amount: "$270.00",
      manualDiscount: "$20.00",
      couponId: "CPN003",
      title: "Free Shipping",
      instanceId: "INST125",
    },
    {
      discountPurpose: "VIP Member Discount",
      discountGross: "$50.00",
      amount: "$450.00",
      manualDiscount: "$25.00",
      couponId: "CPN004",
      title: "Exclusive Discount",
      instanceId: "INST126",
    },
  ];

  return (
    <div className="h-discounts-container">
      <table className="h-discounts-table">
        <thead>
          <tr>
            <th>Discount Purpose</th>
            <th>Discount Gross</th>
            <th>Amount</th>
            <th>Manual Discount</th>
            <th>Coupon ID</th>
            <th>Title</th>
            <th>Instance ID</th>
          </tr>
        </thead>
        <tbody>
          {discounts.map((item, index) => (
            <tr key={index}>
              <td>{item.discountPurpose}</td>
              <td>{item.discountGross}</td>
              <td>{item.amount}</td>
              <td>{item.manualDiscount}</td>
              <td>{item.couponId}</td>
              <td>{item.title}</td>
              <td>{item.instanceId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Discounts;
