import React, { createContext, useState, useEffect, useContext } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp, getApps } from 'firebase/app';
import toast, { Toaster } from 'react-hot-toast';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
    const [taxRates, setTaxRates] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [creditCards, setCreditCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Items, setItems] = useState([]);
    const [Coupons, setCoupons] = useState('');
    const [taxData, setTaxData] = useState([]);
    const [defaultTaxs, setDefaultTaxs] = useState(null);
    const [selectedTaxPercentage, setSelectedTaxPercentage] = useState(null);




    useEffect(() => {
        if (!taxRates || taxRates.length === 0) return;

        const activeTaxes = taxRates[0].rates
            .filter(rate => rate.isDefault && rate.percentage !== -1)
            .map(rate => ({
                percentage: rate.percentage,
                taxChar: rate.taxChar,
            }));

        setTaxData(activeTaxes);

        if (activeTaxes.length > 0) {
            setDefaultTaxs(activeTaxes[0].percentage);
        }
    }, [taxRates]);

    useEffect(() => {
        if (defaultTaxs !== null) {
            setSelectedTaxPercentage(defaultTaxs);
        }
    }, [defaultTaxs]);

    useEffect(() => {
        console.log('default tax', defaultTaxs);
        console.log('selected tax percentage', selectedTaxPercentage);
    }, [defaultTaxs, selectedTaxPercentage]);



    const couponDiscount = Coupons || 0;

    const safeNumber = (value) => {
        const num = Number(value);
        return isNaN(num) ? 0 : num;
    };



    const totalArticles = Items.length;

    const totalAmount = Items.reduce((sum, item) => {
        const price = safeNumber(item.price);
        const quantity = safeNumber(item.quantity) || 1;
        const toppingPrice = safeNumber(item.toppingPrice);
        const totalPrice = (price + toppingPrice) * quantity;
        return sum + totalPrice;
    }, 0);

    const totalAmountWithCoupon = totalAmount - (totalAmount * (couponDiscount / 100));

    const totalDiscount = Items.reduce((sum, item) => {
        const discount = safeNumber(item.discount);
        return sum + discount;
    }, 0);

    const gstAmount = (totalAmountWithCoupon * (selectedTaxPercentage / 100)).toFixed(2);

    const openAmountWithCoupon = (totalAmountWithCoupon + parseFloat(gstAmount) - totalDiscount).toFixed(2);


    useEffect(() => {
        if (Items.length > 0) {
            localStorage.setItem('cartItems', JSON.stringify(Items));
            localStorage.setItem('Total', openAmountWithCoupon);
            localStorage.setItem('GST', gstAmount);
            localStorage.setItem('Discount', totalDiscount);

        }
    }, [Items, gstAmount, openAmountWithCoupon, totalDiscount]);

    const CancelItemAllP = () => {
        setItems([]);
        toast.success('Canceled')
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const taxRatesSnapshot = await getDocs(collection(firestore3, 'TaxRates'));
                setTaxRates(taxRatesSnapshot.docs.map(doc => doc.data()));

                const currencySnapshot = await getDocs(collection(firestore3, 'Currency'));
                setCurrency(currencySnapshot.docs.map(doc => doc.data()));

                const creditCardsSnapshot = await getDocs(collection(firestore3, 'CreditCards'));
                setCreditCards(creditCardsSnapshot.docs.map(doc => doc.data()));

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <CheckoutContext.Provider value={{ taxRates, currency, creditCards, loading, selectedTaxPercentage, setSelectedTaxPercentage, Items, setItems, CancelItemAllP, Coupons, setCoupons, openAmountWithCoupon, gstAmount, totalDiscount, totalAmountWithCoupon, totalAmount, totalArticles }}>
            <Toaster/>
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckoutContext = () => {
    return useContext(CheckoutContext);
};
