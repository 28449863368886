import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './amount.css'; 
import axios from 'axios';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {  getDocs, query, where, updateDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom'; 
import toast, {     Toaster  } from 'react-hot-toast';
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const CashInPage = ({ setCashInPopup }) => {
  const [Branch, setBranch] = useState('DHA phase 1');
  const [Counter, setCounter] = useState('Counter 1');
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState('');
  const [adding, setAdding] = useState(true);
    const [Type, setType] = useState('');

  const navigate = useNavigate();

    const location = useLocation();
    const isCancelButtonVisible = location.pathname === '/Terminal';

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(
          'https://api.currencyfreaks.com/v2.0/rates/latest?apikey=8de920c7dc304382832c702c961ac582'
        );
        const rates = response.data.rates;
        const options = Object.keys(rates).map((key) => ({
          value: key,
          label: key,
        }));
        setCurrency(options);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCurrencies()
  }, []);

  const handleNumberClick = (value) => {
    setAmount((prev) => {
      if (prev === '0,00') {
        return value; 
      } else {
        return prev + value; 
      }
    });
  };

  const handleBackspace = () => {
    setAmount((prev) => {
      if (prev.length === 1 || prev === '0,00') {
        return '0,00'; 
      } else {
        return prev.slice(0, -1); 
      }
    });
  };



  const handleAmountChange = (e) => {
  
    const value = e.target.value;
    if (/^[0-9,]*$/.test(value)) {
      setAmount(value);
    }
  };



    const handleSubmit = async () => {
        setAdding(false);
        const currentDateTime = new Date().toLocaleString();

        const email = localStorage.getItem('email') || "user@example.com";
        const newAmountS = amount


        if (Type === 'none') {
            toast.error("Currency is required");
            setAdding(true);
            return;
        }
        if (!amount || isNaN(newAmountS) || newAmountS <= 0) {
            toast.error("Amount is required and must be a valid number greater than 0");
            setAdding(true);
            return;
        }
        if (!currency) {
            toast.error("Currency is required");
            setAdding(true);
            return;
        }

        if (!reason) {
            toast.error("Reason is required");
            setAdding(true);
            return;
        }
        const newAmount = parseFloat(amount.replace(',', '.'));

        const data = {
            type: Type,
            branch: Branch,
            counter: Counter,
            amount: newAmount,
            currency: currency ? currency.value : "PKR",
            reason: reason,
            email: email,
            date: currentDateTime,
            updatedDate: currentDateTime,
        };

        try {
            const posCollection = collection(firestore, 'POS');
            const q = query(
                posCollection,
                where('email', '==', email),
                where('branch', '==', Branch),
                where('counter', '==', Counter)
            );
            const querySnapshot = await getDocs(q);
            let updatedAmount;
            let updatedCashInAmount = 0;
            let updatedCashOutAmount = 0;

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                const existingData = querySnapshot.docs[0].data();

                const currentCashInAmount = isNaN(existingData.cashInAmount) ? 0 : existingData.cashInAmount;
                const currentCashOutAmount = isNaN(existingData.cashOutAmount) ? 0 : existingData.cashOutAmount;

                if (Type === 'cash-in') {
                    updatedAmount = existingData.amount + newAmount;
                    updatedCashInAmount = currentCashInAmount + newAmount;
                }
                else if (Type === 'cash-out') {
                    updatedAmount = existingData.amount - newAmount;
                    updatedCashOutAmount = currentCashOutAmount + newAmount;
                }

                await updateDoc(docRef, {
                    type: Type,
                    amount: updatedAmount,
                    updatedDate: currentDateTime,
                    cashInAmount: updatedCashInAmount,
                    cashOutAmount: updatedCashOutAmount,
                });

                const actions = `Updated the ${Type} with ${newAmount}`;
                handleLogs(actions);
                toast.success('Amount updated successfully!');
            } else {
                const action = `Added ${Type} amount: ${newAmount}`;
                handleLogs(action);

                await addDoc(posCollection, {
                    ...data,
                    cashInAmount: Type === 'cash-in' ? newAmount : 0,
                    cashOutAmount: Type === 'cash-out' ? newAmount : 0,
                });
                toast.success('Amount updated successfully!');
            }

            setAdding(true);
            navigate('/Terminal');
            setAmount('0,00');
            setReason('');
        } catch (error) {
            setAdding(true);
            toast.error("Error saving or updating data in Database");
            console.error(':', error);
        }
    };





  const handleLogs=async(Actions,Action)=>{
    const currentDateTime = new Date().toLocaleString(); 
    const email = localStorage.getItem('email') || "user@example.com";
      const newAmount = parseFloat(amount.replace(',', '.'));

    const data = {
      email: email,
      branch: Branch,  
      counter: Counter,
      date: currentDateTime,  
      action: Action || Actions,
      type: Type,
      amount: newAmount,

    };
  
    try{
      const Logs = collection(firestore, 'Logs');
      await addDoc(Logs, data);

    }catch(error){
      console.error('Error saving or updating data in logs:', error);

    }

  }
  return (
      <div className="cash-in-page">
          <Toaster

          />

      <div className="cash-in-page-2">
        <div className="form-container-cashing">
        <div className="form-groups" style={{display:'flex',width:'100%'}}>
          <div style={{width:'50%'}}>

            <label>Branch</label>
           <select  onChange={(e) => setBranch(e.target.value)}>
            <option disabled>select a branch</option>
            <option value='DHA phase 1'>DHA phase 1</option>
            <option value='DHA phase 2'>DHA phase 2</option>

           </select>
                       
          </div>
          <div style={{width:'50%'}}>

            <label>Counter</label>
            <select  onChange={(e) => setCounter(e.target.value)}>
            <option disabled>select the Counter</option>
            <option value='Counter 1'>Counter 1</option>
            <option value='Counter 2'>Counter 2</option>

           </select>
           
            
          </div>
          </div>
          <div className="form-groups">
            <label>Expected amount in drawer</label>
            <input type="text" value="0,00 PKR" readOnly className='inputs-amount'/>
          </div>

          <div className="form-groups">
            <label>Type</label>
                      <select onChange={(e) => setType(e.target.value)}>
                          <option value='none'>select the Type</option>
                          <option value='cash-in'>cash-in / pay-in</option>
                          <option value='cash-out'>cash-out / pay-out</option>

                      </select>
          </div>

          <div className="form-groups">
            <label>Amount</label>
            <div className="amount-container">
              <input
                type="text"
                value={amount}
                onChange={handleAmountChange} 
                className='inputs-amount'
              />
              <Select
                options={currency}
                onChange={(selectedOption) => setCurrency(selectedOption)}
                className="currency-select"
                isLoading={loading}
                placeholder={loading ? "Loading..." : "Select Currency"}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: 0,
                    border: '0.5px solid #c84347',
                    backgroundColor: 'transparent',
                    color: 'white',
                  }),
                }}
              />
            </div>
          </div>

          <div className="form-groups">
            <label>Reason/Comment</label>
            <textarea 
              placeholder="Enter reason or comment here" 
              value={reason} 
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="keypad-container">
          <div className="keypad">
            {['1', '2', '3', '4', '5', '6', '7', '8', '9', ',00', '0'].map((key) => (
              <button
                key={key}
                onClick={() => handleNumberClick(key)}
                className="keypad-button"
              >
                {key}
              </button>
            ))}
            <button
              onClick={handleBackspace}
              className="keypad-button backspace"
            >
              ←
            </button>
            <button
              onClick={handleSubmit}
              className="keypad-button confirm"
            >
              {adding ? '✓' : 'addings...'}
             
                      </button>
                      {
                          isCancelButtonVisible && (
                              <button
                                  onClick={() => setCashInPopup(false)}
                                  className="keypad-button backspace"
                              >
                                  Cancel
                              </button>
                          )
                      }

          </div>
        </div>
      </div>
    </div>
  );
};

export default CashInPage;
