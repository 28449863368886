import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getApps, initializeApp } from "firebase/app";
import '../../Styles/Addtional.css'


// Firebase configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX",
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find((app) => app.name === "checkoutApp") || initializeApp(checkoutFirebaseConfig, "checkoutApp");
const firestore = getFirestore(checkoutApp);

const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  // Fetch employee data from Firestore
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "Employees"));
        const employeeData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setEmployees(employeeData);
      } catch (error) {
        console.error("Error fetching employees: ", error);
      }
    };
    fetchEmployees();
  }, []);

  return (
    <div className="form-container">
    <div className="employee-container">
      <h1 className="title">Employees</h1>
      <div className="employee-list">
        {employees.map((employee) => (
          <div
            key={employee.id}
            className="employee-card"
            onClick={() => setSelectedEmployee(employee)}
          >
            <h4>{`${employee.firstName} ${employee.lastName}`}</h4>
            <p>{employee.position || "Position: None"}</p>
          </div>
        ))}
      </div>
      {selectedEmployee && (
        <div className="employee-daba" style={{width:'100vw',height:'100vh',backgroundColor:'#00000084'}}>
<div className="employee-details">
  <h2>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h2>
  <p><strong>Email:</strong> {selectedEmployee.email}</p>
  <p><strong>Phone:</strong> {selectedEmployee.phone}</p>
  <p><strong>Address:</strong> {selectedEmployee.address || "None"}</p>
  <p><strong>Date of Birth:</strong> {selectedEmployee.dateOfBirth}</p>
  <p><strong>Department:</strong> {selectedEmployee.department || "None"}</p>
  <p><strong>Position:</strong> {selectedEmployee.position || "None"}</p>
  <p><strong>Salary:</strong> {selectedEmployee.salary}</p>
  <p><strong>Join Date:</strong> {selectedEmployee.joinDate}</p>

  {/* New Payroll Fields */}
  <p><strong>Tax Identification Number (TIN):</strong> {selectedEmployee.taxId || "None"}</p>
  <p><strong>Bank Account Number:</strong> {selectedEmployee.bankAccount || "None"}</p>
  <p><strong>Payment Method:</strong> {selectedEmployee.paymentMethod || "None"}</p>
  <p><strong>Overtime Rate:</strong> {selectedEmployee.overtimeRate ? `$${selectedEmployee.overtimeRate}/hour` : "None"}</p>
  <p><strong>Deductions:</strong> {selectedEmployee.deductions ? `$${selectedEmployee.deductions}` : "None"}</p>
  <p><strong>Benefits:</strong> {selectedEmployee.benefits || "None"}</p>

  <button className="close-button" onClick={() => setSelectedEmployee(null)}>
    Close
  </button>
</div>

        </div>
      )}
    </div>
    </div>
  );
};

export default Employee;
