

// import React, { useEffect, useState } from 'react'; 
// import { initializeApp,getApps } from 'firebase/app';
// import { getFirestore, collection, getDocs,addDoc,doc,updateDoc,getDoc } from 'firebase/firestore';
// import '../../Styles/Orders.css';

// const checkoutFirebaseConfig = {
//   apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
//   authDomain: "hungrie-5ac9a.firebaseapp.com",
//   projectId: "hungrie-5ac9a",
//   storageBucket: "hungrie-5ac9a.firebasestorage.app",
//   messagingSenderId: "289726512774",
//   appId: "1:289726512774:web:0f7757539451129551db07",
//   measurementId: "G-S5757JJMHX"
// };

// // Initialize Firebase app for checkout with a unique name
// const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
// const firestore = getFirestore(checkoutApp);

// const Orders = () => {
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [firestoreDetails, setFirestoreDetails] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [Web, setWeb] = useState(true);
//   const [App, setApp] = useState(true);
//   const [selectedBilling, setSelectedBilling] = useState(null);
//   const [ confirmed , setconfirmed] = useState(true);
//   const [filteredFirestoreDetails, setFilteredFirestoreDetails] = useState([]);

//     const [selectedBilling2, setSelectedBilling2] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//   const [filters, setFilters] = useState({
//     showCompleted: false,
//     showPending: true,
//     showAll: false,
//     showConfirmed: false, // Default to showing all orders
//   });
//   const [sortBy, setSortBy] = useState('dateNewest'); // Default sorting by date (newest first)
//   const handleShowModal = (billingDetails, order) => {
//     // Ensure billing details are available when the modal is triggered
//     setSelectedBilling(billingDetails || {});
//     setSelectedBilling2(order);
//     // setShowModal(true);  // Open the modal
//   };
//   const handleShowModal22 = (billingDetails, order) => {
//     // Ensure billing details are available when the modal is triggered
//     setSelectedBilling(billingDetails || {});
//     setSelectedBilling2(order);
//     // setShowModal(true);  // Open the modal
//   };
//   const updateOrderStatus = async (orderId, newStatus) => {
//     try {
//       const orderRef = doc(firestore, 'HungrieStore', orderId);
//       await updateDoc(orderRef, { status: newStatus });
//       console.log(`Order ${orderId} status updated to ${newStatus}`);
//     } catch (error) {
//       console.error('Error updating order status:', error);
//     }
//   };
//   const updateOrderStatus2 = async (orderId, newStatus) => {
//     const orderDocRef = doc(firestore, 'OrderDetails', orderId);
//     const orderDoc = await getDoc(orderDocRef);
  
//     if (!orderDoc.exists()) {
//       console.error(`No order found with ID: ${orderId}`);
//       return; // Exit the function if the document doesn't exist
//     }
  
//     // Proceed to update the document if it exists
//     await updateDoc(orderDocRef, { status: newStatus });
//   };
  
// const handleShowModal2 = (order) => {
//     setSelectedBilling2(order);
   
//     setShowModal(true);
// };
// const handleCloseModal = () => {
//   setShowModal(false);
//   setSelectedBilling(null);
// };
// useEffect(() => {
//   const fetchOrders = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(firestore, 'Orders'));
//       const details = querySnapshot.docs.map(doc => {
//         const order = { id: doc.id, ...doc.data() };
//         console.log('Order Status:', order.status);

//         // Check if order status or billingDetails status is 'confirmed'
//         const isConfirmed = order.status === 'confirmed' || order.billingDetails?.status === 'confirmed';
        
//         if (isConfirmed) {
//           setconfirmed(false);  // If confirmed, hide the "Confirm Order" button
//         } else {
//           setconfirmed(true);  // If not confirmed, show the "Confirm Order" button
//         }

//         // Ensure `createdAt` exists and is a Firestore Timestamp
//         if (order.createdAt && order.createdAt.seconds !== undefined) {
//           return order;
//         } else {
//           order.createdAt = { seconds: 0 };  // Default value if `createdAt` is not available
//           return order;
//         }
//       });

//       // Sort orders by `createdAt` (newest first)
//       const sortedOrders = details.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
//       setOrders(sortedOrders);

//       console.log('Sorted Orders:', sortedOrders);
//       setLoading(false);  // Stop loading after orders are fetched
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       setLoading(false);  // Stop loading even if there's an error
//     }
//   };

//   const fetchFirestoreDetails = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
//       const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setFirestoreDetails(details);
//       console.log('Fetched Firestore Details:', details);
//     } catch (error) {
//       console.error('Error fetching Firestore details:', error);
//     } finally {
//       setLoading(false);  // Stop loading after fetching details
//     }
//   };

//   // Fetch data from both collections
//   const fetchData = async () => {
//     await Promise.all([fetchOrders(), fetchFirestoreDetails()]);
//   };

//   // Initial data fetch
//   fetchData();

//   // Set up an interval to refresh data every 2 seconds (2000 ms)
//   const intervalId = setInterval(fetchData, 2000);

//   // Cleanup interval when the component unmounts
//   return () => clearInterval(intervalId);
// }, []);  // Empty dependency array means this effect runs once when the component mounts
//  // Empty dependency array means this effect runs once when the component mounts

//   const handleCheckboxChange = (e) => {
//     const { name, checked } = e.target;

//     setFilters(prevState => {
//       const newFilters = { ...prevState, [name]: checked };

//       // If both Completed and Pending are unchecked, show All
//       if (!newFilters.showCompleted && !newFilters.showPending && !newFilters.showConfirmed) {
//         newFilters.showAll = true;
//       } else {
//         newFilters.showAll = false;
//       }

//       return newFilters;
//     });
//   };

//   useEffect(() => {
//     const { showCompleted, showPending, showAll, showConfirmed } = filters;
  
//     // Filter `orders`
//     let filteredOrdersList = [...orders];
//     if (!showAll) {
//       filteredOrdersList = filteredOrdersList.filter((order) => {
//         if (showPending && order.status === "pending") return true;
//         if (showCompleted && order.status === "completed") return true;
//         if (showConfirmed && order.status === "confirmed") return true;
//         return false;
//       });
//     }
//     setFilteredOrders(filteredOrdersList);
  
//     // Filter `firestoreDetails`
//     let filteredFirestoreList = [...firestoreDetails];
//     if (!showAll) {
//       filteredFirestoreList = filteredFirestoreList.filter((order) => {
//         if (showPending && order.status === "pending") return true;
//         if (showCompleted && order.status === "completed") return true;
//         if (showConfirmed && order.status === "confirmed") return true;
//         return false;
//       });
//     }
//     setFilteredFirestoreDetails(filteredFirestoreList);
//   }, [filters, orders, firestoreDetails]);
  
  

//   // Sorting function
//   const handleSortChange = (e) => {
//     const sortValue = e.target.value; // Get the selected value
//     setSortBy(sortValue); // Update the sorting value

//     let sortedOrders = [...filteredOrders];

//     // Toggle between Web and App based on selected option
//     if (sortValue === "webOrders") {
//       setWeb(true);
//       setApp(false);
//     } else if (sortValue === "appOrders") {
//       setWeb(false);
//       setApp(true);
//     } else {
//       // Sorting logic
//       if (sortValue === 'dateNewest') {
//         sortedOrders = sortedOrders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds); // Sort by recent date
//       } else if (sortValue === 'dateOldest') {
//         sortedOrders = sortedOrders.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds); // Sort by oldest date
//       } else if (sortValue === 'priceLowToHigh') {
//         sortedOrders = sortedOrders.sort((a, b) => a.totalAmount - b.totalAmount); // Sort by price low to high
//       } else if (sortValue === 'priceHighToLow') {
//         sortedOrders = sortedOrders.sort((a, b) => b.totalAmount - a.totalAmount); // Sort by price high to low
//       }
//     }

//     // After sorting, update filtered orders
//     setFilteredOrders(sortedOrders);
//   };
//   const addItemToConfirmedOrder = async (order) => {
//     setconfirmed(false); // Set confirmed state to false temporarily
  
//     try {
//       const confirmInOrdersCollection = collection(firestore, 'ConfirmInOrders');
    
//       // Structure the confirmed order object
//       const confirmedOrder = {
//         billingDetails: {
//           additionalNote: order.billingDetails?.additionalNote || '',
//           city: order.billingDetails?.city || '',
//           companyName: order.billingDetails?.companyName || '',
//           email: order.billingDetails?.email || '',
//           firstName: order.billingDetails?.firstName || '',
//           lastName: order.billingDetails?.lastName || '',
//           phone: order.billingDetails?.phone || '',
//           postcode: order.billingDetails?.postcode || '',
//           state: order.billingDetails?.state || '',
//           streetAddress: order.billingDetails?.streetAddress || '',
//         },
//         status: 'confirmed',
//         createdAt: new Date(), // Timestamp when the order is confirmed
//         items: order.items.map(item => ({
//           ...item,
//           status: 'confirmed',
//         })),
//         totalAmount: order.totalAmount || 0,
//       };
    
//       // Add the confirmed order to the Firestore collection
//       await addDoc(confirmInOrdersCollection, confirmedOrder);
//       alert('Order Confirmed!');
      
//       // Update order status to "confirmed" in the "HungrieStore" collection
//       await updateOrderStatus(order.id, 'confirmed');
    
//       // Automatically set the billing details to show in the modal
//       setSelectedBilling(order.billingDetails); // Set the billing details
//       // setShowModal(true);  // Uncomment if you want the modal to open after confirmation
  
//     } catch (error) {
//       console.error('Error confirming order:', error);
//       setconfirmed(true); // Reset confirmed state in case of error
//     }
//   };
  
//   const addItemToConfirmedOrder2 = async (order) => {
//     setconfirmed(false);  // Reset confirmed state temporarily
  
//     try {
//       const confirmInOrdersCollection = collection(firestore, 'ConfirmInOrders');
  
//       // Structure the confirmed order object based on your provided data
//       const confirmedOrder = {
//         billingDetails: {
//           address: order.Address || '',
//           city: order.City || '',
//           email: order.Email || '',
//           name: order.Name || '',
//           phone: order.Phone || '',
//           postal: order.Postal || '',
//           state: order.State || '',
//         },
//         cartItems: order.cartItems || [],  // Ensure the cart items are correctly added
//         status: order.status || 'confirmed',
//         createdAt: new Date(),  // Use the current timestamp for when the order is confirmed
//         totalAmount: order.totalPrice || 0,  // Ensure this is a valid number
//         userId: order.userId || '',  // Add userId if needed
//       };
  
//       // Log the confirmedOrder to check if data is correct
//       console.log('Confirmed Order:', confirmedOrder);
  
//       // Add the confirmed order to the Firestore collection
//       await addDoc(confirmInOrdersCollection, confirmedOrder);
//       console.log('Order confirmed and added to Firestore!');
      
//       // Optionally, update order status to "confirmed" in the "HungrieStore" collection
//       await updateOrderStatus2(order.id, 'confirmed');
//       alert('Order Confirmed!');
      
//       // Optionally, set selected billing details to show in the modal
//       setSelectedBilling(order.billingDetails); 
//       // setShowModal(true);  // Uncomment if you want the modal to open after confirmation
  
//     } catch (error) {
//       console.error('Error confirming order:', error);
//       setconfirmed(true);  // Reset confirmed state in case of error
//     }
//   };
  
  
  
  
  
  
//   return (
//     <div className="orders-container">
//       <h1 className="title">Orders Management</h1>

//       <div className="filter-checkboxes">
//         <div style={{ display: 'flex' }}>
//           <label className="checkbox-label">
//             <input 
//               type="checkbox" 
//               name="showPending" 
//               checked={filters.showPending} 
//               onChange={handleCheckboxChange} 
//               className="filter-checkbox"
//             />
//             <span className="checkbox-text">Pending</span>
//           </label>
//           {/* <label className="checkbox-label">
//             <input 
//               type="checkbox" 
//               name="showCompleted" 
//               checked={filters.showCompleted} 
//               onChange={handleCheckboxChange} 
//               className="filter-checkbox"
//             />
//             <span className="checkbox-text">Completed</span>
//           </label> */}
//           <label className="checkbox-label">
//             <input 
//               type="checkbox" 
//               name="showConfirmed" 
//               checked={filters.showConfirmed} 
//               onChange={handleCheckboxChange} 
//               className="filter-checkbox"
//             />
//             <span className="checkbox-text">Confirmed</span>
//           </label>
//           <label className="checkbox-label">
//             <input 
//               type="checkbox" 
//               name="showAll" 
//               checked={filters.showAll} 
//               onChange={handleCheckboxChange} 
//               className="filter-checkbox"
//             />
//             <span className="checkbox-text"> All</span>
//           </label>
//         </div>
//         <div className="sort-dropdown">
//           <label htmlFor="sortOrders" style={{ color: '#c84347', fontSize: '20px' }}>Sort by: </label>
//           <select id="sortOrders" value={sortBy} onChange={handleSortChange} style={{ border: '1px solid #c84347', height: '30px' }}>
//             <option value="dateNewest">Date: Newest to Oldest</option>
//             <option value="dateOldest">Date: Oldest to Newest</option>
//             <option value="priceLowToHigh">Price: Low to High</option>
//             <option value="priceHighToLow">Price: High to Low</option>
//             <option value="webOrders">Website Orders</option>
//             <option value="appOrders">App Orders</option>
//           </select>
//         </div>
//       </div>

//       {loading ? (
//         <p className="loading-text">Loading orders...</p>
//       ) : (
//         <>
//           {Web ? (
//             <ul className="orders-list">
//               {Array.isArray(filteredOrders) && filteredOrders.map((order) => (
//                 <li key={order.id} className="order-item">
//                   <p className="order-details">
//                   Order <span className="order-number">#{order.items[0].orderNumber}</span> -
//                     Total: <span className="total">{order.totalAmount} {order.currency}</span> - 
//                     {
//                       confirmed ? 
//                       <span className="status">{order.billingDetails?.status || 'not available'}</span>

//                       :
//                       <span className="status">{order.status || 'not available'}</span>

//                     }


//                   </p>
//                   <p className="order-date">Created At: {new Date(order.createdAt.seconds * 1000).toLocaleString()}</p> {/* Format the timestamp */}
//                   <ul className="line-items">
//                   {Array.isArray(order.items) && order.items.map((item, index) => (
//   <li key={index} className="line-item">
//     <div className="for-img">
//       <div>
//         <strong>{item.title}</strong> - {item.size}<br />
//         <strong>Price</strong>: {item.price} - Quantity: {item.quantity}<br />
        
//         {/* Check if the title contains 'pizza' and if crust exists */}
//         {item.title.toLowerCase().includes('pizza') && item.crust && (
//           <>
//             <strong>Crust</strong> {item.crust} <br />
//           </>
//         )}

//         {item.toppings?.length > 0 && (
//           <span><strong>Toppings:</strong> {item.toppings.join(', ')}</span>
//         )}
//       </div>
//       <img src={item.image} alt={item.title} className="item-image" />
//     </div>
//     <div className="buttons">
//       {order.status === 'pending' && (
//         <button 
//           className="status-button completed"
//           onClick={() => { 
//             handleShowModal(order.billingDetails, order); 
//             addItemToConfirmedOrder(order); 
//           }}
//         >
//           Confirm Order
//         </button>
//       )}
//       <button className="status-button completed" onClick={() => { handleShowModal(order.billing || order.billingDetails , order); handleShowModal2(order); }}>
//         Show Billing Info
//       </button>
//     </div>
//   </li>
// ))}

//                   </ul>
//                 </li>
//               ))}
//             </ul>
//           ) : null}

// {App ? (
//   <ul className="firestore-list" style={{ listStyle: 'none' }}>
//     {Array.isArray(filteredFirestoreDetails) && filteredFirestoreDetails.map((order) => (
//       <li key={order.id} className="order-item">
//         <p className="order-details">
//           Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.totalPrice || order.totalAmount} {order.currency}</span> - Status: <span className="status">{order.status}</span>
//         </p>
//         <ul className="line-items">
//           {Array.isArray(order.cartItems) && order.cartItems.map((item) => (
//             <li key={item.id} className="line-item">
//               <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
//             </li>
            
//           ))}
//           {order.status === 'pending' && (
//         <button 
//           className="status-button completed"
//           onClick={() => { 
//             handleShowModal(order.billingDetails, order); 
//             addItemToConfirmedOrder2(order); 
//           }}
//           style={{marginTop:'20px'}}
//         >
//           Confirm Order
//         </button>
//       )}
//            <button className="status-button completed" onClick={() => { handleShowModal(order.billing || order.billingDetails , order); handleShowModal2(order); }}>
//         Show Billing Info
//       </button>
//         </ul>
        
       
//       </li>
//     ))}
//   </ul>
// ) : null}


//         </>
//       )}
//        {showModal && (
//                 <div className="modal-overlay">
//                     <div className="modal">
//                         <h2>Billing Information</h2>
//                         {selectedBilling ? (
//                             <div>
//                                 <p><strong>First Name:</strong> {selectedBilling.first_name || selectedBilling.firstName || selectedBilling2.Name}</p>
//                                 <p><strong>Last Name:</strong> {selectedBilling.last_name || selectedBilling.lastName}</p>
//                                 <p><strong>Address 1:</strong> {selectedBilling.address_1 || selectedBilling.streetAddress || selectedBilling2.Address}</p>
//                                 <p><strong>Address 2:</strong> {selectedBilling.address_2 || selectedBilling.streetAddress || selectedBilling2.Address}</p>
//                                 <p><strong>City:</strong> {selectedBilling.city || selectedBilling2.City}</p>
//                                 <p><strong>State:</strong> {selectedBilling.state || selectedBilling2.State}</p>
//                                 <p><strong>Postcode:</strong> {selectedBilling.postcode || selectedBilling2.Postal}</p>
//                                 {/* <p><strong>Country:</strong> {selectedBilling.country || selectedBilling.state}</p> */}
//                                 <p><strong>Phone:</strong> {selectedBilling.phone || selectedBilling2.Phone }</p>
//                                 <p><strong>Email:</strong> {selectedBilling.email || selectedBilling2.Email}</p>
//                             </div>
//                         ) : (
//                             <p>No billing information available.</p>
//                         )}
//                         <button onClick={handleCloseModal}>Close</button>
//                     </div>
//                 </div>
//             )}
//     </div>
//   );
// };

// export default Orders;

import React, { useEffect, useState, useCallback } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import '../../Styles/Orders.css';

const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    showCompleted: false,
    showPending: true,
    showAll: false,
    showConfirmed: false,
  });
  const [sortBy, setSortBy] = useState('dateNewest'); // default sorting to 'dateNewest'
  const [originalOrders, setOriginalOrders] = useState([]);
  const [showBillingPopup, setShowBillingPopup] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 };  // Default value if `createdAt` is not available
          return order;
        }
      });
      setOriginalOrders(ordersData);
      setOrders(ordersData); // Initially, show unsorted orders
      console.log('all data' , ordersData)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); 

    return () => clearInterval(interval);
  }, []);


  const handleCheckboxChange = useCallback((e) => {
    const { name, checked } = e.target;

    setFilters(prevState => {
      const newFilters = { ...prevState, [name]: checked };

      // If both Completed and Pending are unchecked, show All
      if (!newFilters.showCompleted && !newFilters.showPending && !newFilters.showConfirmed) {
        newFilters.showAll = true;
      } else {
        newFilters.showAll = false;
      }

      return newFilters;
    });
  }, []);

  useEffect(() => {
    const { showCompleted, showPending, showAll, showConfirmed } = filters;
    let filteredOrdersList = [...orders];
    if (!showAll) {
      filteredOrdersList = filteredOrdersList.filter((order) => {
        if (showPending && order.status === 'pending') return true;
        if (showCompleted && order.status === 'completed') return true;
        if (showConfirmed && order.status === 'processing') return true;
        return false;
      });
    }
    setFilteredOrders(filteredOrdersList);
  }, [filters, orders]);

  const handleSortChange = useCallback((e) => {
    const selectedSortOption = e.target.value;
    setSortBy(selectedSortOption);

    let sortedOrders = [...orders];
    
    switch (selectedSortOption) {
      case 'dateNewest':
        sortedOrders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        break;
      case 'dateOldest':
        sortedOrders.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
        break;
      case 'priceLowToHigh':
        sortedOrders.sort((a, b) => a.totalAmount - b.totalAmount);
        break;
      case 'priceHighToLow':
        sortedOrders.sort((a, b) => b.totalAmount - a.totalAmount);
        break;
      case 'webOrders':
        sortedOrders = sortedOrders.filter(order => order.From === 'website');
        break;
      case 'appOrders':
        sortedOrders = sortedOrders.filter(order => order.From === 'app');
        break;
      case 'resetSort':
        sortedOrders = [...originalOrders]; // Reset to original order
        break;
      default:
        break;
    }

    setOrders(sortedOrders);
  }, [orders, originalOrders]);

  const addItemToConfirmedOrder = async (order) => {
    try {
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, { status: 'processing' });
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status: 'processing' } : o
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  const addItemToCancelOrder = async (order) => {
    try {
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, { status: 'canceled' });
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status: 'canceled' } : o
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  const handleShowModal = (order) => {
    setSelectedOrder(order); // Set selected order for billing details
    setShowBillingPopup(true);  // Open the modal
  };

  const handleCloseModal = () => {
    setShowBillingPopup(false);  // Close the modal
  };

  return (
    <div className="orders-container">
      <h1 className="title">Orders Management</h1>

      <div className="filter-checkboxes">
        <div style={{ display: 'flex' }}>
          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showPending" 
              checked={filters.showPending} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text">Pending</span>
          </label>
        
          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showConfirmed" 
              checked={filters.showConfirmed} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text">Confirmed</span>
          </label>

          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showAll" 
              checked={filters.showAll} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text">All</span>
          </label>
        </div>
        <div className="sort-dropdown">
          <label htmlFor="sortOrders" style={{ color: '#c84347', fontSize: '20px' }}>Sort by: </label>
          <select id="sortOrders" value={sortBy} onChange={handleSortChange} style={{ border: '1px solid #c84347', height: '30px' }}>
            <option value="dateNewest">Date: Newest to Oldest</option>
            <option value="dateOldest">Date: Oldest to Newest</option>
            <option value="priceLowToHigh">Price: Low to High</option>
            <option value="priceHighToLow">Price: High to Low</option>
            <option value="webOrders">Website Orders</option>
            <option value="appOrders">App Orders</option>
            <option value="resetSort">Reset Sorting</option>
          </select>
        </div>
      </div>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {Array.isArray(filteredOrders) && filteredOrders.map((order) => (
            <li key={order.id} className="order-item">
              <p className="order-details">
                Order  <span className="order-number">#{order.orderNumber}</span> - 
                {/* {order.items && order.items.length > 0 ? (
                  order.items.map((item) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )} */}
                      Total: <span className="total">{order.totalAmount} {order.currency}</span> - 

                      Discount : <span className="total">{order.appliedDiscount} {order.currency}</span> - 

                <b className="status">{order.status || 'not available'}</b>
              </p>
              <p className="order-date">Created At: {new Date(order.createdAt.seconds * 1000).toLocaleString()}</p>
              <ul className="order-items" style={{display:'flex',flexDirection:'column',gap:'20px'}}>
                {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <li key={index} className="order-item-detail2">
                      <span className="item-name" style={{fontWeight:'bold',fontSize:'18px',paddingTop:'15px',color:'#c84347'}}>{item.title}</span>
                      <ul>
    {/* Render miniData if it exists */}
    {item.miniData && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData}
        </li>
    )}

    {/* Render miniData1 if it exists */}
    {item.miniData1 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData1}
        </li>
    )}

    {/* Render miniData2 if it exists */}
    {item.miniData2 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData2}
        </li>
    )}

    {/* Render miniData3 if it exists */}
    {item.miniData3 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData3}
        </li>
    )}

    {/* Render miniData4 if it exists */}
    {item.miniData4 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData4}
        </li>
    )}

    {/* Render miniData5 if it exists */}
    {item.miniData5 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData5}
        </li>
    )}

    {/* Render miniData6 if it exists */}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData6}
        </li>
    )}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData7}
        </li>
    )}
</ul> - <br></br>
                      <span className="item-quantity"><strong>Qty:</strong> {item.quantity}</span><br></br>
                      {item.title.toLowerCase().includes('pizza') && item.crust && (
          <>
            <strong>Crust</strong> {item.crust} <br />
          </>
        )}

                          {typeof item.toppings === 'string' ? (
                              <span><strong>Toppings:</strong> {item.toppings}</span>
                          ) : Array.isArray(item.toppings) && item.toppings.length > 0 ? (
                              <span><strong>Toppings:</strong> {item.toppings.join(', ')}</span>
                          ) : (
                              <span><strong>Toppings:</strong> No toppings</span>
                          )}

                    </li>
                  ))
                ) : (
                  <p>No items</p>
                )}
              </ul>
             {order.status === 'pending' && (
               <button onClick={() => addItemToConfirmedOrder(order)} className="status-button completed" style={{marginTop:'30px'}}>
               
               Confirm Order
             </button>
             )

             }
             {order.status === 'pending' && (
               <button onClick={() => addItemToCancelOrder(order)} className="status-button completed" style={{marginTop:'30px',backgroundColor:'red'}}>
               
              Cancel
             </button>
             )

             }
              <button onClick={() => handleShowModal(order)} className="status-button completed" style={{marginTop:'30px',backgroundColor:'purple'}}>
                View Billing
              </button>
            </li>
          ))}
        </ul>
      )}

      {showBillingPopup && selectedOrder && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Billing Information</h2>
            <div>
              <p><strong>First Name:</strong> {selectedOrder.billingDetails.firstName}</p>
              <p><strong>Last Name:</strong> {selectedOrder.billingDetails.lastName}</p>
              <p><strong>Street Address:</strong> {selectedOrder.billingDetails.streetAddress}</p>
              <p><strong>City:</strong> {selectedOrder.billingDetails.city}</p>
              <p><strong>State:</strong> {selectedOrder.billingDetails.state}</p>
              <p><strong>Postcode:</strong> {selectedOrder.billingDetails.postcode}</p>
              <p><strong>Phone:</strong> {selectedOrder.billingDetails.phone}</p>
              <p><strong>Email:</strong> {selectedOrder.billingDetails.email}</p>
              <p><strong>Payment Method:</strong> {selectedOrder.billingDetails.paymentMethod}</p>
            </div>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
