import '../Pages-7/CurrenciesDetails.css';
const CurrenciesDetails = ({ currencyCode, decimalPlace }) => {
  // Placeholder data for administrative section
  const adminData = {
    createdAt: "2024-01-29",
    createdBy: "Admin",
    modifiedAt: "2024-01-29",
    modifiedBy: "Admin",
  };

  return (
    <div className="currencies-details-container">
      {/* General Data Section */}
      <div className="section">
        <h2>General Data</h2>
        <div className="field">
          <label>Code:</label>
          <span>{currencyCode}</span>
        </div>
        <div className="field">
          <label>Decimal Place:</label>
          <span>{decimalPlace}</span>
        </div>
      </div>

      {/* Administrative Data Section */}
      <div className="section">
        <h2>Administrative Data</h2>
        <div className="field">
          <label>Created At:</label>
          <span>{adminData.createdAt}</span>
        </div>
        <div className="field">
          <label>Created By:</label>
          <span>{adminData.createdBy}</span>
        </div>
        <div className="field">
          <label>Modified At:</label>
          <span>{adminData.modifiedAt}</span>
        </div>
        <div className="field">
          <label>Modified By:</label>
          <span>{adminData.modifiedBy}</span>
        </div>
      </div>
    </div>
  );
};

export default CurrenciesDetails;
