import { useEffect, useState } from "react";
import "../Component-4/Reciepts.css";
import Reciepts from "../Component-4/Data";
import ReceiptDetails from "../Component-4/ReceiptDetails";

const Receipt = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [receipts, setReceipts] = useState([]);
  const [selectedReceipt, setSelectedReceipt] = useState(null); // State to track selected receipt

  useEffect(() => {
    setReceipts(Reciepts); // Load receipts data
  }, []);

  const filteredReceipts = receipts.filter(
    (receipt) =>
      receipt.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      receipt.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (receipt) => {
    setSelectedReceipt(receipt); // Set selected receipt (both ID and status)
  };

  // If a receipt is selected, render the ReceiptDetails component
  if (selectedReceipt) {
    return (
      <ReceiptDetails
        receiptId={selectedReceipt.id}
        status={selectedReceipt.status}
      />
    );
  }

  return (
    <div className="h-receipt-container">
      {/* Navbar */}
      <div className="h-navbar">
        <h1>Receipts - {receipts.length}</h1>
        <div className="h-search-bar-container">
        <input
          type="text"
          className="h-search-bar"
          placeholder="Search Receipts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        </div>
      </div>

      {/* Table */}
      <div className="h-table-container">
        <table className="h-receipt-table">
          <thead>
            <tr>
              <th>Receipt ID</th>
              <th>Status</th>
              <th>POS System ID</th>
              <th>Sales Summary ID</th>
              <th>Customer</th>
              <th>Type Code</th>
              <th>Business Transaction Data</th>
              <th>Payment Gross Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredReceipts.length > 0 ? (
              filteredReceipts.map((receipt) => (
                <tr
                  key={receipt.id}
                  onClick={() => handleRowClick(receipt)} // Handle row click
                  className="h-clickable-row"
                >
                  <td>{receipt.id}</td>
                  <td>{receipt.status}</td>
                  <td>{receipt.posSystemId}</td>
                  <td>{receipt.salesSummaryId}</td>
                  <td>{receipt.customer}</td>
                  <td>{receipt.typeCode}</td>
                  <td>{receipt.transactionData}</td>
                  <td>{receipt.paymentAmount}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="h-no-data">
                  No receipts found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Receipt;
