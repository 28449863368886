const Reciepts = [
    // Sample data
    {
      id: "R001",
      status: "Completed",
      posSystemId: "POS123",
      salesSummaryId: "SS001",
      customer: "John Doe",
      typeCode: "TC01",
      transactionData: "Transaction A",
      paymentAmount: "$100.00",
    },
    {
      id: "R002",
      status: "Pending",
      posSystemId: "POS124",
      salesSummaryId: "SS002",
      customer: "Jane Smith",
      typeCode: "TC02",
      transactionData: "Transaction B",
      paymentAmount: "$200.00",
    },

    {
      id: "R001",
      status: "Completed",
      posSystemId: "POS101",
      salesSummaryId: "SS001",
      customer: "Alice Johnson",
      typeCode: "TC01",
      transactionData: "Transaction A",
      paymentAmount: "$100.00",
    },
    {
      id: "R002",
      status: "Pending",
      posSystemId: "POS102",
      salesSummaryId: "SS002",
      customer: "Bob Smith",
      typeCode: "TC02",
      transactionData: "Transaction B",
      paymentAmount: "$200.00",
    },
    {
      id: "R003",
      status: "Completed",
      posSystemId: "POS103",
      salesSummaryId: "SS003",
      customer: "Charlie Brown",
      typeCode: "TC03",
      transactionData: "Transaction C",
      paymentAmount: "$150.00",
    },
    {
      id: "R004",
      status: "Failed",
      posSystemId: "POS104",
      salesSummaryId: "SS004",
      customer: "Diana Prince",
      typeCode: "TC04",
      transactionData: "Transaction D",
      paymentAmount: "$300.00",
    },
    {
      id: "R005",
      status: "Completed",
      posSystemId: "POS105",
      salesSummaryId: "SS005",
      customer: "Edward Elric",
      typeCode: "TC05",
      transactionData: "Transaction E",
      paymentAmount: "$250.00",
    },
    {
      id: "R006",
      status: "Pending",
      posSystemId: "POS106",
      salesSummaryId: "SS006",
      customer: "Fiona Harper",
      typeCode: "TC06",
      transactionData: "Transaction F",
      paymentAmount: "$400.00",
    },
    {
      id: "R007",
      status: "Pending",
      posSystemId: "POS107",
      salesSummaryId: "SS007",
      customer: "Qazi Hashim Ali",
      typeCode: "TC07",
      transactionData: "Transaction G",
      paymentAmount: "$600.00",
    },
    {
      id: "R008",
      status: "Completed",
      posSystemId: "POS108",
      salesSummaryId: "SS008",
      customer: "Grace Kelly",
      typeCode: "TC08",
      transactionData: "Transaction H",
      paymentAmount: "$180.00",
    },
    {
      id: "R009",
      status: "Pending",
      posSystemId: "POS109",
      salesSummaryId: "SS009",
      customer: "Hank Pym",
      typeCode: "TC09",
      transactionData: "Transaction I",
      paymentAmount: "$220.00",
    },
    {
      id: "R010",
      status: "Failed",
      posSystemId: "POS110",
      salesSummaryId: "SS010",
      customer: "Ivy Carter",
      typeCode: "TC10",
      transactionData: "Transaction J",
      paymentAmount: "$500.00",
    },
    {
      id: "R011",
      status: "Completed",
      posSystemId: "POS111",
      salesSummaryId: "SS011",
      customer: "Jack Sparrow",
      typeCode: "TC11",
      transactionData: "Transaction K",
      paymentAmount: "$120.00",
    },
    {
      id: "R012",
      status: "Pending",
      posSystemId: "POS112",
      salesSummaryId: "SS012",
      customer: "Karen Page",
      typeCode: "TC12",
      transactionData: "Transaction L",
      paymentAmount: "$310.00",
    },
    {
      id: "R013",
      status: "Completed",
      posSystemId: "POS113",
      salesSummaryId: "SS013",
      customer: "Liam Neeson",
      typeCode: "TC13",
      transactionData: "Transaction M",
      paymentAmount: "$170.00",
    },
    {
      id: "R014",
      status: "Pending",
      posSystemId: "POS114",
      salesSummaryId: "SS014",
      customer: "Maria Hill",
      typeCode: "TC14",
      transactionData: "Transaction N",
      paymentAmount: "$450.00",
    },
    {
      id: "R015",
      status: "Completed",
      posSystemId: "POS115",
      salesSummaryId: "SS015",
      customer: "Nathan Drake",
      typeCode: "TC15",
      transactionData: "Transaction O",
      paymentAmount: "$190.00",
    },
    {
      id: "R016",
      status: "Failed",
      posSystemId: "POS116",
      salesSummaryId: "SS016",
      customer: "Olivia Wilde",
      typeCode: "TC16",
      transactionData: "Transaction P",
      paymentAmount: "$230.00",
    },
    {
      id: "R017",
      status: "Pending",
      posSystemId: "POS117",
      salesSummaryId: "SS017",
      customer: "Paul Atreides",
      typeCode: "TC17",
      transactionData: "Transaction Q",
      paymentAmount: "$320.00",
    },
    {
      id: "R018",
      status: "Completed",
      posSystemId: "POS118",
      salesSummaryId: "SS018",
      customer: "Quincy Adams",
      typeCode: "TC18",
      transactionData: "Transaction R",
      paymentAmount: "$140.00",
    },
    {
      id: "R019",
      status: "Pending",
      posSystemId: "POS119",
      salesSummaryId: "SS019",
      customer: "Rachel Green",
      typeCode: "TC19",
      transactionData: "Transaction S",
      paymentAmount: "$380.00",
    },
    {
      id: "R020",
      status: "Failed",
      posSystemId: "POS120",
      salesSummaryId: "SS020",
      customer: "Steve Rogers",
      typeCode: "TC20",
      transactionData: "Transaction T",
      paymentAmount: "$280.00",
    },
    {
      id: "R021",
      status: "Completed",
      posSystemId: "POS121",
      salesSummaryId: "SS021",
      customer: "Tony Stark",
      typeCode: "TC21",
      transactionData: "Transaction U",
      paymentAmount: "$450.00",
    },
    {
      id: "R022",
      status: "Pending",
      posSystemId: "POS122",
      salesSummaryId: "SS022",
      customer: "Uma Thurman",
      typeCode: "TC22",
      transactionData: "Transaction V",
      paymentAmount: "$240.00",
    },
    {
      id: "R023",
      status: "Completed",
      posSystemId: "POS123",
      salesSummaryId: "SS023",
      customer: "Victor Hugo",
      typeCode: "TC23",
      transactionData: "Transaction W",
      paymentAmount: "$350.00",
    },
    {
      id: "R024",
      status: "Pending",
      posSystemId: "POS124",
      salesSummaryId: "SS024",
      customer: "Wanda Maximoff",
      typeCode: "TC24",
      transactionData: "Transaction X",
      paymentAmount: "$290.00",
    },
    {
      id: "R025",
      status: "Failed",
      posSystemId: "POS125",
      salesSummaryId: "SS025",
      customer: "Xander Cage",
      typeCode: "TC25",
      transactionData: "Transaction Y",
      paymentAmount: "$380.00",
    },
    {
      id: "R026",
      status: "Completed",
      posSystemId: "POS126",
      salesSummaryId: "SS026",
      customer: "Yara Shahidi",
      typeCode: "TC26",
      transactionData: "Transaction Z",
      paymentAmount: "$410.00",
    },
    {
      id: "R027",
      status: "Pending",
      posSystemId: "POS127",
      salesSummaryId: "SS027",
      customer: "Zachary Levi",
      typeCode: "TC27",
      transactionData: "Transaction AA",
      paymentAmount: "$300.00",
    },
    {
      id: "R028",
      status: "Failed",
      posSystemId: "POS128",
      salesSummaryId: "SS028",
      customer: "Annie Walker",
      typeCode: "TC28",
      transactionData: "Transaction BB",
      paymentAmount: "$270.00",
    },
    {
      id: "R029",
      status: "Completed",
      posSystemId: "POS129",
      salesSummaryId: "SS029",
      customer: "Ben Affleck",
      typeCode: "TC29",
      transactionData: "Transaction CC",
      paymentAmount: "$500.00",
    },
    {
      id: "R030",
      status: "Pending",
      posSystemId: "POS130",
      salesSummaryId: "SS030",
      customer: "Cara Dune",
      typeCode: "TC30",
      transactionData: "Transaction DD",
      paymentAmount: "$190.00",
    },
    {
      id: "R031",
      status: "Failed",
      posSystemId: "POS131",
      salesSummaryId: "SS031",
      customer: "Dean Winchester",
      typeCode: "TC31",
      transactionData: "Transaction EE",
      paymentAmount: "$370.00",
    },
  ]

  export default Reciepts