import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Terminal.css';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, Timestamp, getDocs, collection, setDoc, getDoc } from 'firebase/firestore';

import PaymentInterface from './Payment';
import { useCheckoutContext } from '../../Context/Data';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const ItemsTable = ({ Items, onSelectItem, selectedItem, updateItems, Coupons }) => {
    const { selectedTaxPercentage , openAmountWithCoupon, gstAmount, totalAmountWithCoupon,  totalArticles , setItems  } = useCheckoutContext();

    const [rightClickedItem, setRightClickedItem] = useState(null);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [Quantity, setQuantity] = useState(null);
    const [showContextMenu2, setShowContextMenu2] = useState(false);
    const location = useLocation();
    const [terminalId, setTerminalId] = useState(null);
    const [terminalId2, setTerminalId2] = useState(null);

    const isTerminalRoute = location.pathname === '/Terminal' || /^\/Terminal\/r-\d+$/.test(location.pathname);
    const [showContextMenu3, setShowContextMenu3] = useState(false);
    const isTerminalRouteRecepit = location.pathname !== '/Terminal' || /^\/Terminal\/\d+$/.test(location.pathname); 

    const [Tabs, setTabs] = useState('');


    useEffect(() => {
        const routeParts = location.pathname.split('/');
        const terminalIdFromRoute = routeParts[routeParts.length - 1];
        if (terminalIdFromRoute !== 'Terminal') {
            setTerminalId(terminalIdFromRoute);
        }
    }, [location.pathname]);


    useEffect(() => {
        const routeParts = location.pathname.split('/');
        const terminalIdFromRoute = routeParts[routeParts.length - 1];
        if (terminalIdFromRoute !== 'Terminal') {
            setTerminalId2(terminalIdFromRoute);
        }
    }, [location.pathname]);

    const safeNumber = (value) => {
        const num = Number(value);
        return isNaN(num) ? 0 : num;
    };





    const handleDeleteItem = () => {
        updateItems((prevItems) => prevItems.filter(item => item.uniqueId !== rightClickedItem.uniqueId));
        setShowContextMenu(false);
    };

    const handleRightClick = (e, item) => {
        e.preventDefault();
        setRightClickedItem(item);
        setQuantity(item.quantity);
        setContextMenuPosition({ x: e.pageX, y: e.pageY });
        setShowContextMenu(true);
    };

    const handleUpdateQuantity = (increment) => {
        setQuantity((prevQuantity) => {
            const newQuantity = increment ? prevQuantity + 1 : prevQuantity - 1;
            return newQuantity > 0 ? newQuantity : 1;
        });
    };

    const saveUpdatedQuantity = () => {
        if (isNaN(Quantity) || Quantity <= 0) {
            alert("Invalid quantity");
            return;
        }
        updateItems((prevItems) =>
            prevItems.map(item =>
                item.uniqueId === rightClickedItem.uniqueId ? { ...item, quantity: Quantity } : item
            )
        );
        setShowContextMenu2(false);
    };

    const handleOrder = async () => {
        if (!terminalId) {
            alert("Terminal ID not found.");
            return;
        }
        const id = uuidv4();

        try {
            const tableDocRef = doc(firestore3, 'Tables', terminalId);
            const email = localStorage.getItem('email') || "user@example.com";

            await updateDoc(tableDocRef, {
                TheId: id,
                AddedBy: email,
                UpdatedTime: Timestamp.now(),
                Items: Items,
                category: "owned",
            });

            alert("Order updated successfully!");
            handleOrderKitchen();
        } catch (error) {
            console.error("Error updating order:", error);
            alert("Error updating order.");
        }
    };

    const handleOrderKitchen = async () => {
        if (!terminalId) {
            alert("Terminal ID not found.");
            return;
        }

        try {
            const querySnapshot = await getDocs(collection(firestore3, 'Orders'));
            const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const SequenceNumber = details.length > 0 ? details.length + 1 : 1;
            const IsFrom = 'Dinning';

            const tableDocRef = doc(firestore3, 'Orders', terminalId);
            const email = localStorage.getItem('email') || "user@example.com";

            const docSnapshot = await getDoc(tableDocRef);

            if (docSnapshot.exists()) {
                await updateDoc(tableDocRef, {
                    From: IsFrom,
                    AddedBy: email,
                    createdAt: Timestamp.now(),
                    items: Items,
                    status: 'processing',
                    StatuspPOS:'New Items Added'
                });
                toast.success("Order updated in kitchen!");
            } else {
                await setDoc(tableDocRef, {
                    From: IsFrom,
                    SequenceNumber: SequenceNumber,
                    AddedBy: email,
                    createdAt: Timestamp.now(),
                    items: Items,
                    status: 'processing',
                    orderNumber: Math.floor(10000 + Math.random() * 90000),

                });
                toast.success("Order created successfully!");
            }
        } catch (error) {
            console.error("Error handling order:", error);
            alert("Error handling order.");
        }
    };



    const handleUpdateReceipt = async () => {
        //if (isTerminalRouteRecepit && isTerminalRoute) {
        //    alert("Terminal ID not found.");
        //    return;
        //}
        const id = uuidv4();

        try {
            const tableDocRef = doc(firestore3, 'HoldReceipt', terminalId2);
            const email = localStorage.getItem('email') || "user@example.com";

            await updateDoc(tableDocRef, {
                TheId: id,
                AddedBy: email,
                UpdatedTime: Timestamp.now(),
                Items: Items,
                category: "owned",
            });
            toast.success("Receipt updated successfully!");
        } catch (error) {
            console.error("Error updating order:", error);
            alert("Error updating order.");
        }
    };

    const handleReceipt = async () => {

        try {
            const id = uuidv4();
            const currentTime = new Date().toISOString();
            const orderNumber = Math.floor(10000 + Math.random() * 90000);
            const formattedReceiptId = `r-${orderNumber}`; 
            const email = localStorage.getItem('email') || "user@example.com";
            const tableDocRef = doc(firestore3, 'HoldReceipt', formattedReceiptId);


            await setDoc(tableDocRef, {
                ReceiptNo: parseInt(orderNumber),
                TheId: id,
                AddedBy: email,
                UpdatedTime: Timestamp.now(),
                Items: Items,
                category: "owned",
                AddedTime: currentTime,

            });
            setItems([])

            toast.success(`Receipt ${orderNumber } Added successfully!`);

        } catch (error) {
            console.error("Error updating order:", error);
            alert("Error updating order.");
        }
    };


    return (
        <div className="main-actions-tables">
            <Toaster /> 

            <div className="table-section">
                <table className="terminal-table">
                    <thead className="terminal-thead">
                        <tr>
                            <th>Quantity</th>
                            <th>Article</th>
                            <th>Size</th>
                            <th>Crust</th>
                            <th>Toppings</th>
                            <th>Sauce</th>
                            <th>Flavors</th>
                            <th>Discount</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody className="terminal-tbody">
                        {Items.map((item, index) => {
                            const price = safeNumber(item.price);
                            const quantity = safeNumber(item.quantity) || 1;
                            const toppingPrice = safeNumber(item.toppingPrice);
                            const totalPrice = ((price + toppingPrice) * quantity).toFixed(2);

                            return (
                                <tr
                                    key={item.uniqueId}
                                    className={`${index % 2 === 0 ? 'row-red' : 'row'} ${item.uniqueItem === selectedItem?.uniqueItem ? 'selected' : ''}`}
                                    onClick={() => onSelectItem(item)}
                                    onContextMenu={(e) => handleRightClick(e, item)}
                                >
                                    <td>{quantity}</td>
                                    <td>{item.title || 'N/A'}</td>
                                    <td>{item.size || 'none'}</td>
                                    <td>{item.crust || 'none'}</td>
                                    <td>{item.toppings?.join(', ') || 'none'}</td>
                                    <td>{item.sauce || 'none'}</td>
                                    <td>{item.flavor || 'none'}</td>
                                    <td>{item.discount || '0'}</td>
                                    <td>{totalPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {showContextMenu && (
                <div
                    className="context-menu"
                    style={{ top: `${contextMenuPosition.y}px`, left: `${contextMenuPosition.x}px` }}
                    onClick={() => setShowContextMenu(false)}
                >
                    <ul>
                        <li onClick={handleDeleteItem}>Delete Item</li>
                        <li onClick={() => { setShowContextMenu(false); setShowContextMenu2(true) }}>Update Quantity</li>
                        <li onClick={() => { setShowContextMenu(false) }}>Cancel</li>
                    </ul>
                </div>
            )}

            {showContextMenu2 && (
                <div
                    className="context-menu-2"
                    style={{ top: `${contextMenuPosition.y}px`, left: `${contextMenuPosition.x}px` }}
                    onClick={() => setShowContextMenu(false)}
                >
                    <div className="updating">
                        <div>
                            <button onClick={() => handleUpdateQuantity(false)} className="dec-btn">-</button>
                            <span>{Quantity}</span>
                            <button onClick={() => handleUpdateQuantity(true)} className="inc-btn">+</button>
                        </div>
                        <button onClick={saveUpdatedQuantity} className="upd-btn">Update</button>
                    </div>
                </div>
            )}

            <div className="payment-panel">
                <div className="payment-total">
                    <div className="total total-2">
                        <span>Total Articles</span>
                        <span className="amount">{totalArticles}</span>
                    </div>
                    <div className="total total-2">
                        <span>Total Discount</span>
                        <span className="amount">{Coupons}%</span>
                    </div>
                    <div className="total total-2" style={{ paddingBottom: '10px', borderBottom: '1px solid silver', marginBottom: '10px' }}>
                        <span>GST ({selectedTaxPercentage || 0}%)</span>
                        <span className="amount">{gstAmount} PKR</span>
                    </div>
                    <div className="total-2">
                        <div className="total-label">Total</div>
                        <div className="total-amount">{totalAmountWithCoupon.toFixed(2)} PKR</div>
                    </div>
                    <div className="open-amount">
                        <span>Open amount</span>
                        <span className="amount amount-2">{openAmountWithCoupon} PKR</span>
                    </div>
                </div>


                <div className='amount-btns-main'>
                    {!isTerminalRoute && (
                        <button onClick={handleOrder} className='amount-btns'>Update Order</button>
                    )}
                    {isTerminalRouteRecepit && isTerminalRoute && (
                        <button onClick={handleUpdateReceipt} className='amount-btns'>Update Receipt</button>
                    )}
                    {Items.length > 0 && (
                        <>
                     <button className='amount-btns' onClick={() => { setShowContextMenu3(true); setTabs('CASH') }} >Exact Amount</button>
                    <button className='amount-btns' onClick={() => { setShowContextMenu3(true); setTabs('CARD') } }>Card</button>
                      <button className='amount-btns' onClick={() => { setShowContextMenu3(true); setTabs('CASH') }} >Custom</button>
                      </>

                    )

                    }

                    {!isTerminalRouteRecepit && isTerminalRoute && Items.length > 0 && (
                        <button className='amount-btns' onClick={handleReceipt} >Park Receipt</button>
                    )}

                    {/*{isTerminalRoute && (*/}

                        {/*//<button className='amount-btns' style={{ backgroundColor: 'green' }} onClick={() => { setShowContextMenu3(true); setTabs('CASH') }} >Hold</button>*/}
                    {/*)}*/}
                </div>

                {showContextMenu3 && (
                    <div className="payment-popup-overlay">
                        <div className="payment-popup-content-items">

                            <PaymentInterface openAmountWithCoupon={openAmountWithCoupon} Tabs={Tabs} Items={Items} selectedTaxPercentage={selectedTaxPercentage} setShowContextMenu3={setShowContextMenu3 } />
                            <button className='sec-cancel-button' onClick={() => setShowContextMenu3(false)} >Cancel</button>

                        </div>
                </div>
                )}


            </div>
        </div>
    );
};

export default ItemsTable;
