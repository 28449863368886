import "../Pages-4/FeesandServiceCharges.css";

const FeesandServiceCharges = () => {
  const feesServiceCharges = [
    {
      type: "Fee",
      name: "Transaction Fee",
      taxCode: "TX001",
      journalAccount: "JA123",
      netAmount: "$100.00",
      taxAmount: "$10.00",
      grossAmount: "$110.00",
    },
    {
      type: "Service Charge",
      name: "Processing Fee",
      taxCode: "TX002",
      journalAccount: "JA124",
      netAmount: "$50.00",
      taxAmount: "$5.00",
      grossAmount: "$55.00",
    },
    {
      type: "Fee",
      name: "Late Payment Fee",
      taxCode: "TX003",
      journalAccount: "JA125",
      netAmount: "$200.00",
      taxAmount: "$20.00",
      grossAmount: "$220.00",
    },
    {
      type: "Service Charge",
      name: "Subscription Fee",
      taxCode: "TX004",
      journalAccount: "JA126",
      netAmount: "$150.00",
      taxAmount: "$15.00",
      grossAmount: "$165.00",
    },
  ];

  return (
    <div className="h-fees-service-charges-container">
      <table className="h-fees-service-charges-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Tax Code</th>
            <th>Journal Account</th>
            <th>Net Amount</th>
            <th>Tax Amount</th>
            <th>Gross Amount</th>
          </tr>
        </thead>
        <tbody>
          {feesServiceCharges.map((item, index) => (
            <tr key={index}>
              <td>{item.type}</td>
              <td>{item.name}</td>
              <td>{item.taxCode}</td>
              <td>{item.journalAccount}</td>
              <td>{item.netAmount}</td>
              <td>{item.taxAmount}</td>
              <td>{item.grossAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeesandServiceCharges;
