import React, { useState, useEffect } from "react";
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const UniqueTaxTable = () => {
    const [taxRates, setTaxRates] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [newCountry, setNewCountry] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTaxRates = async () => {
            try {
                const taxRatesRef = doc(firestore3, "TaxRates", "taxRatesDocument");
                const docSnap = await getDoc(taxRatesRef);
                if (docSnap.exists()) {
                    setTaxRates(docSnap.data().rates);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching tax rates: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTaxRates();
    }, []);

    const handleCheckboxChange = (id) => {
        setTaxRates((prev) =>
            prev.map((tax) =>
                tax.id === id ? { ...tax, active: !tax.active } : tax
            )
        );
    };

    const handleDefaultChange = (id) => {
        setTaxRates((prev) =>
            prev.map((tax) =>
                tax.id === id
                    ? { ...tax, isDefault: true }
                    : { ...tax, isDefault: false }
            )
        );
    };

    const toggleEditMode = async () => {
        if (isEditMode) {
            try {
                const taxRatesRef = doc(firestore3, "TaxRates", "taxRatesDocument");
                await setDoc(taxRatesRef, { rates: taxRates });
                console.log("Tax rates saved successfully!");
            } catch (error) {
                console.error("Error saving tax rates:", error);
            }
        }
        setIsEditMode((prevMode) => !prevMode);
    };

    const handleAddRow = () => {
        setTaxRates([
            ...taxRates,
            {
                id: taxRates.length + 1,
                country: newCountry || "New Country",
                taxCode: "",
                taxChar: "",
                percentage: 0,
                validFrom: "",
                validTo: "",
                description: "",
                active: false,
                isDefault: false, 
            }
        ]);
        setNewCountry("");
    };

    const handleDeleteRow = (id) => {
        setTaxRates(taxRates.filter((tax) => tax.id !== id));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="unique-tax-table-container">
                <table className="unique-tax-table">
                    <thead>
                        <tr>
                            <th>Country/Region</th>
                            <th>Tax code</th>
                            <th>Tax rate characteristic</th>
                            <th>Percentage</th>
                            <th>Valid from</th>
                            <th>Valid to</th>
                            <th>Description</th>
                            <th>Active</th>
                            <th>Default</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxRates.map((row) => (
                            <tr key={row.id}>
                                <td>
                                    <input
                                        type="text"
                                        className="unique-input"
                                        value={row.country}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, country: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="unique-input"
                                        value={row.taxCode}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, taxCode: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="unique-input"
                                        value={row.taxChar}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, taxChar: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="unique-input"
                                        value={row.percentage}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, percentage: parseFloat(e.target.value) } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        className="unique-input"
                                        value={row.validFrom}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, validFrom: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        className="unique-input"
                                        value={row.validTo}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, validTo: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="unique-input"
                                        value={row.description}
                                        onChange={(e) => {
                                            const updatedRows = taxRates.map((tax) =>
                                                tax.id === row.id ? { ...tax, description: e.target.value } : tax
                                            );
                                            setTaxRates(updatedRows);
                                        }}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="unique-checkbox"
                                        checked={row.active}
                                        onChange={() => handleCheckboxChange(row.id)}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="radio"
                                        className="unique-radio"
                                        checked={row.isDefault}
                                        onChange={() => handleDefaultChange(row.id)}
                                        disabled={!isEditMode}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="delete-btn"
                                        onClick={() => handleDeleteRow(row.id)}
                                        disabled={!isEditMode}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="checkout-bottom-bar">
                <button className="bottom-bar-action-btn">
                    <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                </button>
                <button className="bottom-bar-action-btn edit-btn" onClick={toggleEditMode}>
                    {isEditMode ? "Save" : "Edit"}
                </button>
                {isEditMode && (
                    <div className="add-country-row">
                        <button className="bottom-bar-action-btn" onClick={handleAddRow}>Add Row</button>
                    </div>
                )}
            </div>
        </>
    );
};

export default UniqueTaxTable;
