import React, { useState, useEffect } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, Timestamp, setDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import './Function.css';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const TimeRecording = () => {
    const email = localStorage.getItem('email') || "user@example.com";
    const [orgUnit, setOrgUnit] = useState('');
    const [counter, setCounter] = useState('');
    const [uniqueId, setUniqueId] = useState(localStorage.getItem('timeRecordingId'));
    const [startTime, setStartTime] = useState(localStorage.getItem('startTime'));

    useEffect(() => {
        if (uniqueId) {
            setStartTime(localStorage.getItem('startTime'));
        }
    }, [uniqueId]);

    const handleStart = async () => {
        const id = uuidv4();
        const start = new Date().toISOString();
        setUniqueId(id);
        setStartTime(start);

        localStorage.setItem('timeRecordingId', id);
        localStorage.setItem('startTime', start);

        const timeRecord = {
            id,
            email,
            orgUnit,
            counter,
            startTime: Timestamp.fromDate(new Date(start)),
        };
        const docRef = doc(collection(firestore3, "TimeRecording"), id);
        await setDoc(docRef, timeRecord);
        console.log('Time recording started:', id);
    };

    const handleEnd = async () => {
        if (!uniqueId) return;
        const end = new Date().toISOString();

        const timeRecord = {
            email,
            orgUnit,
            counter,
            startTime: Timestamp.fromDate(new Date(startTime)),
            endTime: Timestamp.fromDate(new Date(end)),
        };

        const docRef = doc(firestore3, "TimeRecording", uniqueId);
        await updateDoc(docRef, timeRecord);
        console.log('Time recording ended:', uniqueId);

        localStorage.removeItem('timeRecordingId');
        localStorage.removeItem('startTime');
        setUniqueId(null);
        setStartTime(null);
    };

    return (
        <div className="time-recording-card">
            <div className="time-recording-card-content">
                <div className='top-bar'>
                    <div className="message">
                        <p className="message-text">
                            Click start to submit the time recording event.
                        </p>
                    </div>
                </div>
                <div className="confirmation-table">
                    <div className="table-header">Confirmation</div>
                    <table className="confirmation-table-main">
                        <tbody>
                            <tr>
                                <td className="table-cell">Employee</td>
                                <td className="table-cell">{email}</td>
                            </tr>
                            <tr>
                                <td className="table-cell">Punched in org. unit</td>
                                <td className="table-cell">
                                    <select value={orgUnit} onChange={e => setOrgUnit(e.target.value)}>
                                        <option value="">Select Org Unit</option>
                                        <option value="Unit1">Unit 1</option>
                                        <option value="Unit2">Unit 2</option>
                                        <option value="Unit3">Unit 3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="table-cell">Punched in time</td>
                                <td className="table-cell">{startTime || 'Not started'}</td>
                            </tr>
                            <tr>
                                <td className="table-cell">Counter</td>
                                <td className="table-cell">
                                    <select value={counter} onChange={e => setCounter(e.target.value)}>
                                        <option value="">Select Counter</option>
                                        <option value="Counter1">Counter 1</option>
                                        <option value="Counter2">Counter 2</option>
                                        <option value="Counter3">Counter 3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="start-again-container">
                    {uniqueId ? (
                        <button className="start-again-button" onClick={handleEnd}>End</button>
                    ) : (
                        <button className="start-again-button" onClick={handleStart}>Start</button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TimeRecording;