

import { useState } from "react";
import "../Pages-6/DrawersAccount.css"; 

const DrawersAccount = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [accounts, setAccounts] = useState([
    { masterAccount: "001", account: "Sales", currency: "USD" },
    { masterAccount: "002", account: "Purchases", currency: "EUR" },
    { masterAccount: "003", account: "Admin Expenses", currency: "GBP" },
    { masterAccount: "004", account: "Inventory", currency: "JPY" },
  ]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredAccounts = accounts.filter((account) =>
    account.account.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="drawers-accounts-page">
      {/* Search Bar */}
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search accounts..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>

      {/* Accounts Table */}
      <div className="accounts-table-container">
        <table className="accounts-table">
          <thead>
            <tr>
              <th>Master Account</th>
              <th>Account</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>
            {filteredAccounts.map((account, index) => (
              <tr key={index}>
                <td>{account.masterAccount}</td>
                <td>{account.account}</td>
                <td>{account.currency}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DrawersAccount;