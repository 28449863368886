// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import '../Styles/Dashboard.css';
import { AiOutlineProduct } from "react-icons/ai";
import { FaKitchenSet } from "react-icons/fa6";
import { MdOutlineCancel, MdHistory, MdAdd } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaRegChartBar } from "react-icons/fa6";
import { PiPicnicTable } from "react-icons/pi";
import { RiStockLine } from "react-icons/ri";
import Orders from './Options/Orders';
import KitchenOrdersList from './Options/KitchenOrders';
import Dinning from './Options/Dinning';
import WebOrders from './Options/Web-Orders';
import AppOrders from './Options/App-Orders';
import Delivered from './Options/Delivered';
import Canceled from './Options/Canceled';
import AddItems from './Options/AddItems';

import Users from './Options/Users';
import Welcome from './Options/Welcome';
import ToDeliver from './Options/ToDeliver'; // Assuming you have this component
import PointofSale from './Options/PointofSale'; // Assuming this component exists
import UpdateItems from './Options/UpdateItems';

import ItemsStock from './Options/ItemsStock';
import OutofStock from './Options/OutofStock';
import Employee from './Options/Employee';
import AddEmployees from './Options/AddEmployees';
import Payroll from './Options/Payroll';
import Attendance from './Options/Attendance';
import Customers from './Options/Customers';
import CustomersDetails from './Options/CustomersDetails';
import LoyalCustomers from './Options/LoyalCustomers';

import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { RiEBike2Line } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { PiChartLineDownThin } from "react-icons/pi";
import { GrUserWorker } from "react-icons/gr";
import { IoMdPersonAdd } from "react-icons/io";
import { MdOutlinePayments } from "react-icons/md";
import { CiCalendar } from "react-icons/ci";
import { HiOutlineUsers } from "react-icons/hi2";
import { HiOutlineUserPlus } from "react-icons/hi2";
import { CgUserList } from "react-icons/cg";
import { FaUsersRectangle } from "react-icons/fa6";
import { MdLoyalty } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import ShowUsers from './Options/ShowUsers';
import TableMang from './Options/TableMang';
import { TbReportSearch } from "react-icons/tb";
import OrderDetails from './Options/SaleReport';
import TableArea from '../Hashim/Component-5/TableArea';
import Receipt from '../Hashim/Component-4/Reciepts';
import Drawers from '../Hashim/Component-6/Drawers';
import Currencies from '../Hashim/Component-7/Currencies';
import DeliveryModes from '../Hashim/Component-8/DeliveryModes';
import TimeRecordingTable from './POS/AdminPOS/TimeRecordingTable';

function Dashboard() {
  const navigate =useNavigate()
  const [selectedOption, setSelectedOption] = useState('Main');
  const [toggle, setToggle] = useState(false);
  const [roles, setRoles] = useState([]);
  const [SwitchIcon, setSwitchIcon] = useState(true);
  const [ordersOptions, setordersOptions] = useState(true);
  const [SwitchIcon2, setSwitchIcon2] = useState(false);
  const [ordersOptions2, setordersOptions2] = useState(false);
  const [SwitchIcon3, setSwitchIcon3] = useState(false);
  const [ordersOptions3, setordersOptions3] = useState(false);
  const [SwitchIcon4, setSwitchIcon4] = useState(false);
  const [ordersOptions4, setordersOptions4] = useState(false);
  const [SwitchIcon5, setSwitchIcon5] = useState(false);
  const [ordersOptions5, setordersOptions5] = useState(false);
  const [SwitchIcon7, setSwitchIcon7] = useState(false);
  const [ordersOptions7, setordersOptions7] = useState(false);
  useEffect(() => {
    const storedRoles = JSON.parse(localStorage.getItem('roles'));
    if (storedRoles) {
      setRoles(storedRoles);
    } else {
      window.location.href = '/'; 
    }
  }, []);

  const renderMenuItems = () => {
    const options = [];



    if (roles.includes('orders' || 'kitchen' || 'dining' ||'website' || 'application' || 'to deliver' || 'delivered' || 'canceled' || 'point of sale' || 'add items' || 'cancel item' || 'add user' || 'update-item')) {
      options.push(
        <li
          key="Main"
          onClick={() => setSelectedOption('Main')}
          className={selectedOption === 'Main' ? 'active' : ''}
        >
          <FaRegChartBar  size={20} /> Dashboard
        </li>
      );
    }
    if (roles.includes('orders')) {
      options.push(
        <li
          key="orders"
          onClick={() => setSelectedOption('Orders')}
          className={selectedOption === 'Orders' ? 'active' : ''}
        >
          <AiOutlineProduct size={20} /> Orders
        </li>
      );
    }

    if (roles.includes('kitchen')) {
      options.push(
        <li
          key="kitchen"
          onClick={() => setSelectedOption('KitchenOrders')}
          className={selectedOption === 'KitchenOrders' ? 'active' : ''}
        >
          <FaKitchenSet size={20} /> In Kitchen
        </li> 
      );
    }

    if (roles.includes('dining')) {
      options.push(
        <li
          key="dining"
          onClick={() => setSelectedOption('Dinning')}
          className={selectedOption === 'Dinning' ? 'active' : ''}
        >
          <PiPicnicTable  size={20} /> In Dinning
        </li>
      );
    }

    // if (roles.includes('website')) {
    //   options.push(
    //     <li
    //       key="web-orders"
    //       onClick={() => setSelectedOption('Web-Orders')}
    //       className={selectedOption === 'Web-Orders' ? 'active' : ''}
    //     >
    //       <CgWebsite size={20} /> Website Orders
    //     </li>
    //   );
    // }

    // if (roles.includes('application')) {
    //   options.push(
    //     <li
    //       key="app-orders"
    //       onClick={() => setSelectedOption('App-Orders')}
    //       className={selectedOption === 'App-Orders' ? 'active' : ''}
    //     >
    //       <CiMobile1 size={20} /> App Orders
    //     </li>
    //   );
    // }

    if (roles.includes('to deliver')) {
      options.push(
        <li
          key="to-deliver"
          onClick={() => setSelectedOption('ToDeliver')}
          className={selectedOption === 'ToDeliver' ? 'active' : ''}
        >
          <MdHistory size={20} /> To Deliver
        </li>
      );
    }

    if (roles.includes('delivered')) {
      options.push(
        <li
          key="delivered"
          onClick={() => setSelectedOption('Delivered')}
          className={selectedOption === 'Delivered' ? 'active' : ''}
        >
          <RiEBike2Line size={20} /> Delivered
        </li>
      );
    }

    if (roles.includes('canceled')) {
      options.push(
        <li
          key="canceled"
          onClick={() => setSelectedOption('Canceled-Orders')}
          className={selectedOption === 'Canceled-Orders' ? 'active' : ''}
        >
          <MdOutlineCancel size={20} /> Canceled Orders
        </li>
      );
    }
     

    // if (roles.includes('point of sale')) {
    //   options.push(
    //     <li
    //       key="point-of-sale"
    //       onClick={() => setSelectedOption('Point-of-sale')}
    //       className={selectedOption === 'Point-of-sale' ? 'active' : ''}
    //     >
    //       <PiInvoice size={20} /> Point of Sale
    //     </li>
    //   );
    // }

    // if (roles.includes('add items')) {
    //   options.push(
    //     <li
    //       key="add-items"
    //       onClick={() => setSelectedOption('Add-Items')}
    //       className={selectedOption === 'Add-Items' ? 'active' : ''}
    //     >
    //       <MdAdd size={20} /> Add Items
    //     </li>
    //   );
    // }
    // if (roles.includes('update-item')) {
    //   options.push(
    //     <li
    //       key="update-item"
    //       onClick={() => setSelectedOption('update-item')}
    //       className={selectedOption === 'update-item' ? 'active' : ''}
    //     >
    //       <MdOutlineTipsAndUpdates size={20} /> Update Items
    //     </li>
    //   );
    // }
    // if (roles.includes('cancel item')) {
    //   options.push(
    //     <li
    //       key="cancel-item"
    //       onClick={() => setSelectedOption('Cancel-Item')}
    //       className={selectedOption === 'Cancel-Item' ? 'active' : ''}
    //     >
    //       <MdOutlineCancel size={20} /> Cancel Item
    //     </li>
    //   );
    // }
    // if (roles.includes('add user')) {
    //   options.push(
    //     <li
    //       key="add-user"
    //       onClick={() => setSelectedOption('add-user')}
    //       className={selectedOption === 'add-user' ? 'active' : ''}
    //     >
    //       <IoIosAddCircleOutline size={20} /> Add User
    //     </li>
    //   );
    // }
  //   <li
  //   onClick={() => {
  //     localStorage.removeItem('roles');  // Remove 'roles' from localStorage
  //     navigate('/');  // Navigate to the home page or another route
  // }}
  
    // className={selectedOption === 'add-user' ? 'active' : ''}
  // >
  //   <CiLogout   size={20} /> Logout
  // </li>
//  if (roles.includes('orders' || 'kitchen' || 'dining' ||'website' || 'application' || 'to deliver' || 'delivered' || 'canceled' || 'point of sale' || 'add items' || 'cancel item' || 'add user' || 'update-item')) {
//   options.push(
//     <li
//       key="Main"
//       onClick={() => {
//         localStorage.removeItem('roles');  // Remove 'roles' from localStorage
//         navigate('/');  // Navigate to the home page or another route
//     }}
//           className={selectedOption === 'Maisn' ? 'active' : ''}
//     >
//       <CiLogout size={20} /> Logout
//     </li>
//   );
// }
   
    return options;
  };
  const renderMenuItems2 = () => {
    const options = [];



    if (roles.includes('customers')) {
      options.push(
        <li
          key="customers"
          onClick={() => setSelectedOption('customers')}
          className={selectedOption === 'customers' ? 'active' : ''}
        >
          <CgUserList size={20} /> Customers
        </li>
      );
    }
    if (roles.includes('customers-details')) {
      options.push(
        <li
          key="customers-details"
          onClick={() => setSelectedOption('customers-details')}
          className={selectedOption === 'customers-details' ? 'active' : ''}
        >
          <FaUsersRectangle  size={20} /> Customers Details
        </li>
      );
    }
    if (roles.includes('loyal-customers')) {
      options.push(
        <li
          key="loyal-customers"
          onClick={() => setSelectedOption('loyal-customers')}
          className={selectedOption === 'loyal-customers' ? 'active' : ''}
        >
          <MdLoyalty size={20} /> Loyal Customers
        </li>
      );
    }



   
    return options;
  };
  const renderMenuItems3 = () => {
    const options = [];



    if (roles.includes('employees')) {
      options.push(
        <li
          key="employees"
          onClick={() => setSelectedOption('employees')}
          className={selectedOption === 'employees' ? 'active' : ''}
        >
          <GrUserWorker  size={20} /> Employees
        </li>
      );
    }
    if (roles.includes('add-employees')) {
      options.push(
        <li
          key="add-employees"
          onClick={() => setSelectedOption('add-employees')}
          className={selectedOption === 'add-employees' ? 'active' : ''}
        >
          <IoMdPersonAdd  size={20} /> Add Employees
        </li>
      );
    }
    if (roles.includes('payroll')) {
      options.push(
        <li
          key="payroll"
          onClick={() => setSelectedOption('payroll')}
          className={selectedOption === 'payroll' ? 'active' : ''}
        >
          <MdOutlinePayments size={20} /> Payroll Management
        </li>
      );
    }
    if (roles.includes('attendance')) {
      options.push(
        <li
          key="attendance"
          onClick={() => setSelectedOption('attendance')}
          className={selectedOption === 'attendance' ? 'active' : ''}
        >
          <CiCalendar size={20} /> Attendance & Time Management
        </li>
      );
    }
    if (roles.includes('users')) {
      options.push(
        <li
          key="users"
          onClick={() => setSelectedOption('users')}
          className={selectedOption === 'users' ? 'active' : ''}
        >
          <HiOutlineUsers  size={20} /> Users
        </li>
      );
    }
    if (roles.includes('add user')) {
      options.push(
        <li
          key="add-user"
          onClick={() => setSelectedOption('add-user')}
          className={selectedOption === 'add-user' ? 'active' : ''}
        >
          <HiOutlineUserPlus size={20} />Add Users
        </li>
      );
    }


   
    return options;
  };
  const renderMenuItems4 = () => {
    const options = [];



    if (roles.includes('add items')) {
      options.push(
        <li
          key="add-items"
          onClick={() => setSelectedOption('Add-Items')}
          className={selectedOption === 'Add-Items' ? 'active' : ''}
        >
          <MdAdd size={20} /> Add Product
        </li>
      );
    }
    if (roles.includes('update-item')) {
      options.push(
        <li
          key="update-item"
          onClick={() => setSelectedOption('update-item')}
          className={selectedOption === 'update-item' ? 'active' : ''}
        >
          <MdOutlineTipsAndUpdates size={20} /> Update Product
        </li>
      );
    }
    if (roles.includes('items-in-stock')) {
      options.push(
        <li
          key="items-in-stock"
          onClick={() => setSelectedOption('items-in-stock')}
          className={selectedOption === 'items-in-stock' ? 'active' : ''}
        >
          <RiStockLine size={20} /> Items in Stock
        </li>
      );
    }
    if (roles.includes('out-of-stock')) {
      options.push(
        <li
          key="out-of-stock"
          onClick={() => setSelectedOption('out-of-stock')}
          className={selectedOption === 'out-of-stock' ? 'active' : ''}
        >
          <PiChartLineDownThin  size={20} /> Out of stocks
        </li>
      );
    }

   
    return options;
  };
  const renderMenuItems5 = () => {
    const options = [];



    if (roles.includes('orders' || 'kitchen' || 'dining' ||'website' || 'application' || 'to deliver' || 'delivered' || 'canceled' || 'point of sale' || 'add items' || 'cancel item' || 'add user' || 'update-item')) {
      options.push(
        <li
          key="orders"
          onClick={() => {
                    localStorage.removeItem('roles');  // Remove 'roles' from localStorage
                    navigate('/');  // Navigate to the home page or another route
                }}
          className={selectedOption === 'Orders' ? 'active' : ''}
        >
          <BiLogOutCircle  size={20} /> Logout
        </li>
      );
    }
   
  


   
    return options;
  };

  const renderMenuItems7 = () => {
    const options = [];





    if (roles.includes('canceled')) {
      options.push(
          <li
              key="canceled"
              onClick={() => setSelectedOption('OrderDetails')}
              className={selectedOption === 'OrderDetails' ? 'active' : ''}
          >
              <TbReportSearch size={20} /> Sales Report 
          </li>
      );
  }






  if (roles.includes('canceled')) {
    options.push(
        <li
            key="canceled"
            onClick={() => setSelectedOption('Table-Area')}
            className={selectedOption === 'Table-Area' ? 'active' : ''}
        >
            <TbReportSearch size={20} /> Table Management 
        </li>
    );
  }

    if (roles.includes('canceled')) {
      options.push(
          <li
              key="canceled"
              onClick={() => setSelectedOption('Receipt')}
              className={selectedOption === 'Receipt' ? 'active' : ''}
          >
              <TbReportSearch size={20} /> Recepit 
          </li>
      );
    }

    if (roles.includes('canceled')) {
      options.push(
          <li
              key="canceled"
              onClick={() => setSelectedOption('Drawers')}
              className={selectedOption === 'Drawers' ? 'active' : ''}
          >
              <TbReportSearch size={20} /> Drawers
          </li>
      );
    }


    if (roles.includes('canceled')) {
      options.push(
          <li
              key="canceled"
              onClick={() => setSelectedOption('Currencies')}
              className={selectedOption === 'Currencies' ? 'active' : ''}
          >
              <TbReportSearch size={20} /> Currencies
          </li>
      );
    }


    if (roles.includes('canceled')) {
      options.push(
          <li
              key="canceled"
              onClick={() => setSelectedOption('DeliveryModes')}
              className={selectedOption === 'DeliveryModes' ? 'active' : ''}
          >
              <TbReportSearch size={20} /> DeliveryModes
          </li>
      );
    }




   return options;

  }


  return (
    <div className="dashboard-wrapper">
      <div className="hamburger">
        <RxHamburgerMenu onClick={() => setToggle(!toggle)} />
      </div>

      {toggle && (
        <div className="sidebar-2">
          <div className="cross" onClick={() => setToggle(false)}>x</div>
          <h3>Hungrie</h3>
          <ul>
            {renderMenuItems()}
          </ul>
        </div>
      )}

      <div className="sidebar">
        <img src="logo2.png" alt="Logo" style={{ height: '120px', width: '160px', marginLeft: '25px' }} />
        
        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions(!ordersOptions);setSwitchIcon(!SwitchIcon)}}> Orders Management</div>
          <div>

            {
              SwitchIcon?
              <IoIosArrowDown style={{marginTop:'5px'}} size={18} onClick={()=>{setordersOptions(!ordersOptions);setSwitchIcon(!SwitchIcon)}}/>
              :
              <IoIosArrowUp  style={{marginTop:'5px'}} size={18} onClick={()=>{setordersOptions(!ordersOptions);setSwitchIcon(!SwitchIcon)}}/>


            }
          </div>
            </div>
{
  ordersOptions?
  <ul>
  {renderMenuItems()}
</ul>
  :
  null
}
        </div>
        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions4(!ordersOptions4);setSwitchIcon4(!SwitchIcon4)}}> Inventory Management</div>
          <div>

            {
              SwitchIcon4?
              <IoIosArrowDown size={18} style={{marginTop:'5px'}} onClick={()=>{setordersOptions4(!ordersOptions4);setSwitchIcon4(!SwitchIcon4)}}/>
              :
              <IoIosArrowUp size={18}  style={{marginTop:'5px'}} onClick={()=>{setordersOptions4(!ordersOptions4);setSwitchIcon4(!SwitchIcon4)}}/>


            }
          </div>
            </div>
{
  ordersOptions4?
  <ul>
  {renderMenuItems4()}
</ul>
  :
  null
}
        </div>
        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions3(!ordersOptions3);setSwitchIcon3(!SwitchIcon3)}}> Employee Management</div>
          <div>

            {
              SwitchIcon3?
              <IoIosArrowDown size={18} style={{marginTop:'5px'}} onClick={()=>{setordersOptions3(!ordersOptions3);setSwitchIcon3(!SwitchIcon3)}}/>
              :
              <IoIosArrowUp size={18}  style={{marginTop:'5px'}} onClick={()=>{setordersOptions3(!ordersOptions3);setSwitchIcon3(!SwitchIcon3)}}/>


            }
          </div>
            </div>
{
  ordersOptions3?
  <ul>
  {renderMenuItems3()}
</ul>
  :
  null
}
        </div>
        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions2(!ordersOptions2);setSwitchIcon2(!SwitchIcon2)}}>  CR Management</div>
          <div>

            {
              SwitchIcon2?
              <IoIosArrowDown size={18}  style={{marginTop:'5px'}} onClick={()=>{setordersOptions2(!ordersOptions2);setSwitchIcon2(!SwitchIcon2)}}/>
              :
              <IoIosArrowUp  size={18} style={{marginTop:'5px'}} onClick={()=>{setordersOptions2(!ordersOptions2);setSwitchIcon2(!SwitchIcon2)}}/>


            }
          </div>
            </div>
{
  ordersOptions2?
  <ul>
  {renderMenuItems2()}
</ul>
  :
  null
}
        </div>
        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions5(!ordersOptions5);setSwitchIcon5(!SwitchIcon5)}}> Account Setting</div>
          <div>

            {
              SwitchIcon5?
              <IoIosArrowDown size={18} style={{marginTop:'5px'}} onClick={()=>{setordersOptions5(!ordersOptions5);setSwitchIcon5(!SwitchIcon5)}}/>
              :
              <IoIosArrowUp size={18}  style={{marginTop:'5px'}} onClick={()=>{setordersOptions5(!ordersOptions5);setSwitchIcon5(!SwitchIcon5)}}/>


            }
          </div>
            </div>
{
  ordersOptions5?
  <ul>
  {renderMenuItems5()}
</ul>
  :
  null
}
        </div>

        <div className='main-option'>
          <div className='main-option-1'>
          <div className='main-option-1-1' onClick={()=>{setordersOptions7(!ordersOptions7);setSwitchIcon7(!SwitchIcon7)}}> POS Management</div>
          <div>

            {
              SwitchIcon7?
              <IoIosArrowDown size={18} style={{marginTop:'5px'}} onClick={()=>{setordersOptions7(!ordersOptions7);setSwitchIcon4(!SwitchIcon7)}}/>
              :
              <IoIosArrowUp size={18}  style={{marginTop:'5px'}} onClick={()=>{setordersOptions7(!ordersOptions7);setSwitchIcon4(!SwitchIcon7)}}/>


            }
          </div>
            </div>
{
  ordersOptions7?
  <ul>
  {renderMenuItems7()}
</ul>
  :
  null
}
        </div>



        <div className='main-option'>
          <div className='main-option-1'>
          <div       style={{fontSize:'20px',cursor:'pointer'}}               className={selectedOption === 'main-option-1-1' ? 'active' : ''}
>
  <Link to='/Cashing' style={{textDecoration:'none',color:'white'}}>  Point of Sale</Link>
</div>
         
            </div>
        </div>
      </div>

      <div className="main-content">
      {selectedOption === 'Main' && <Welcome />}
        {selectedOption === 'Orders' && <Orders />}
        {selectedOption === 'KitchenOrders' && <KitchenOrdersList />}
        {selectedOption === 'Dinning' && <Dinning />}
        {selectedOption === 'Web-Orders' && <WebOrders />}
        {selectedOption === 'App-Orders' && <AppOrders />}
        {selectedOption === 'Delivered' && <Delivered />}
        {selectedOption === 'Canceled-Orders' && <Canceled />}
        {selectedOption === 'ToDeliver' && <ToDeliver />}
        {selectedOption === 'Point-of-sale' && <PointofSale />}
        {selectedOption === 'Add-Items' && <AddItems />}
        {selectedOption === 'add-user' && <Users />}
        {selectedOption === 'update-item' && <UpdateItems />}
        {selectedOption === 'items-in-stock' && <ItemsStock />}
        {selectedOption === 'out-of-stock' && <OutofStock />}
        {selectedOption === 'employees' && <Employee />}
        {selectedOption === 'add-employees' && <AddEmployees />}
        {selectedOption === 'payroll' && <Payroll />}
        {selectedOption === 'attendance' && <Attendance />}
        {selectedOption === 'customers' && <Customers />}
        {selectedOption === 'customers-details' && <CustomersDetails />}
        {selectedOption === 'loyal-customers' && <LoyalCustomers />}

        {selectedOption === 'users' && <ShowUsers />}
        {selectedOption === 'OrderDetails' && <OrderDetails />}

              {selectedOption === 'users' && <ShowUsers />}
              {selectedOption === 'OrderDetails' && <OrderDetails />}
              {selectedOption === 'TimeRecordingTable' && <TimeRecordingTable />}

        {/* {selectedOption === 'users' && <ShowUsers />} */}

        {selectedOption === 'Table-Management' && <TableMang />}



{/* ---------------------------------------------hashim components --------------------------------------------- */}



        {selectedOption === 'Table-Area' && <TableArea />}
        {selectedOption === 'Receipt' && <Receipt />}
        {selectedOption === 'Drawers' && <Drawers />}
        {selectedOption === 'Currencies' && <Currencies />}
        {selectedOption === 'DeliveryModes' && <DeliveryModes />}









      </div>
    </div>
  );
}

export default Dashboard;
