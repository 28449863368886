import React, { useState, useEffect } from 'react';

const PaymentSummary = () => {
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        const savedData = localStorage.getItem('paymentData');
        if (savedData) {
            setPaymentData(JSON.parse(savedData));
        }
    }, []);

    const calculateTotal = (section) => {
        return Object.entries(paymentData[section])
            .reduce((sum, [denomination, value]) => {
                return sum + (parseFloat(value) * parseFloat(denomination));
            }, 0).toFixed(2);
    };

    if (!paymentData) {
        return <div>Loading...</div>;
    }

    const SummaryTable = ({ title, data }) => (
        <div className="summary-table-card">
            <div className="summary-table-content">
                <h2 className="summary-table-title">{title}</h2>
                <table className="summary-table">
                    <thead>
                        <tr className="summary-table-header-row">
                            <th className="summary-table-header">Method</th>
                            <th className="summary-table-header">Actual</th>
                            <th className="summary-table-header">Target</th>
                            <th className="summary-table-header">Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index} className="summary-table-row">
                                <td className="summary-table-cell method-cell">{row.method}</td>
                                <td className="summary-table-cell">{row.actual}</td>
                                <td className="summary-table-cell">{row.target}</td>
                                <td className="summary-table-cell">{row.difference}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    const notesData = Object.entries(paymentData.notes).map(([denomination, value]) => ({
        method: `${denomination} PKR`,
        actual: value,
        target: '0.00',
        difference: (parseFloat(value) * parseFloat(denomination)).toFixed(2),
    }));

    const coinsData = Object.entries(paymentData.coins).map(([denomination, value]) => ({
        method: `${denomination} PKR`,
        actual: value,
        target: '0.00',
        difference: (parseFloat(value) * parseFloat(denomination)).toFixed(2),
    }));

    const paymentsData = Object.entries(paymentData.payments).map(([method, value]) => ({
        method,
        actual: value,
        target: '0.00',
        difference: value, 
    }));

    return (
        <div className="payment-summary-container">
            <div className="summary-tables-row">
                <SummaryTable title="Notes" data={notesData} />
                <SummaryTable title="Coins" data={coinsData} />
                <SummaryTable title="Payments" data={paymentsData} />
            </div>
        </div>
    );
};

export default PaymentSummary;
