import React from "react";
import "../Pages-4/Details.css";

const Details = () => {
  // Data arrays for the different sections
  const generalData = [
    { label: "POS System ID", value: "12345" },
    { label: "Receipt ID", value: "67890" },
    { label: "Sales Summary ID", value: "111213" },
    { label: "Type Code", value: "ABCD" },
    { label: "Business Transaction Date", value: "2025-01-27" },
    {
      label: "Business Transaction Date (Local Time Zone)",
      value: "2025-01-27 10:00 AM",
    },
    { label: "Cashier", value: "John Doe" },
    { label: "Sales Person", value: "Jane Doe" },
    { label: "Discount Purpose", value: "Promotion" },
    { label: "Price List ID", value: "PL123" },
    { label: "Price List Manually Changed", type: "checkbox" },
    { label: "Comment", value: "No comments" },
    { label: "Rounding Used", type: "checkbox" },
    { label: "Rounding Level", value: "0.5" },
    { label: "Rounding Amount", value: "2.00" },
    { label: "Status", value: "Active" },
    { label: "Archived Asset ID", value: "ARCH123" },
    { label: "POS Mode", value: "Normal" },
    { label: "Cash Desk at Productive Mode", type: "checkbox" },
    { label: "Tax Exemption", value: "None" },
    { label: "Remote Calculated", type: "checkbox" },
    { label: "Taxation Method", value: "Standard" },
  ];

  const tableService = [
    { label: "Table ID", value: "TBL001" },
    { label: "No. of Guests", value: "4" },
  ];

  const amountData = [
    { label: "Total Gross Amount", value: "$500.00" },
    { label: "Total Net Amount", value: "$450.00" },
    { label: "Total Gross Amount (After Item Discount)", value: "$480.00" },
    { label: "Discount Gross Amount", value: "$20.00" },
    { label: "Receipt Discount Percentage", value: "10%" },
    { label: "No. of Points", value: "50" },
    { label: "Loyalty Discount", value: "$5.00" },
    { label: "Fee Gross Amount", value: "$10.00" },
    { label: "Service Charge Gross Amount", value: "$15.00" },
    { label: "Payment Gross Amount", value: "$505.00" },
    { label: "Payment Tax Amount", value: "$50.00" },
    { label: "Payment Net Amount", value: "$455.00" },
  ];

  const administrativeData = [
    { label: "Created At", value: "2025-01-01" },
    { label: "Created By", value: "Admin" },
    { label: "Modified At", value: "2025-01-15" },
    { label: "Modified By", value: "Supervisor" },
    { label: "Origin Created At", value: "2025-01-01" },
    { label: "Origin Created By", value: "System" },
    { label: "Origin Modified At", value: "2025-01-10" },
    { label: "Origin Modified By", value: "System" },
  ];

  // Reusable function to render a list of data
  const renderDataList = (data) =>
    data.map((item, index) => (
      <li key={index}>
        {item.label}:{" "}
        {item.type === "checkbox" ? (
          <input type="checkbox" defaultChecked={false} />
        ) : (
          item.value
        )}
      </li>
    ));

  return (
    <div className="h-details-page">
      {/* Top Section */}
      <div className="h-top-section">
        {/* General Data Section */}
        <div className="h-general-data">
          <h2>General Data</h2>
          <ul>{renderDataList(generalData)}</ul>
        </div>

        {/* Table Service Section */}
        <div className="h-table-service">
          <h2>Table Service</h2>
          <ul>{renderDataList(tableService)}</ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="h-bottom-section">
        {/* Amount Section */}
        <div className="h-amount">
          <h2>Amount</h2>
          <ul>{renderDataList(amountData)}</ul>
        </div>

        {/* Administrative Data Section */}
        <div className="h-administrative-data">
          <h2>Administrative Data</h2>
          <ul>{renderDataList(administrativeData)}</ul>
        </div>
      </div>
    </div>
  );
};

export default Details;
