import React, { useEffect, useState } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../../Styles/Orders.css';
const firebaseConfig2 = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app2 = initializeApp(firebaseConfig2);
const firestore2 = getFirestore(app2);
// Firebase configuration
// Initialize Firebase for the main project (for both Firestore and Storage)
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);  // Firestore for the main project
const storage = getStorage(app);  // Storage for the main project

// Initialize the checkout app (if necessary)
const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

// If you are using two separate apps, initialize it explicitly
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);  // Firestore for the checkout project (optional, only needed if using Firestore3)




const UpdateItems = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [editItem, setEditItem] = useState(null);
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [SequenceNumber, setSequenceNumber] = useState(null);

    const [updatedImage, setUpdatedImage] = useState(null);
    const [updatedPrice, setUpdatedPrice] = useState('');
    const [updatedCategory, setUpdatedCategory] = useState('');
    const [updatedShortcut, setUpdatedShortcut] = useState('');
    const [updateMenu, setupdateMenu] = useState('');
    const [changeText, setchangeText] = useState(true);
    const [updatedMiniData, setUpdatedMiniData] = useState('');
    const [updatedMiniData1, setUpdatedMiniData1] = useState('');
    const [updatedMiniData2, setUpdatedMiniData2] = useState('');
    const [updatedMiniData3, setUpdatedMiniData3] = useState('');
    const [updatedMiniData4, setUpdatedMiniData4] = useState('');
    const [updatedMiniData5, setUpdatedMiniData5] = useState('');
    const [updatedMiniData6, setUpdatedMiniData6] = useState('');
    const [updatedCrustOptions, setUpdatedCrustOptions] = useState([]);
    const [categories, setCategories] = useState([]); // State for storing categories
    const [isPopular, setIsPopular] = useState(editItem?.isPopular || false);

const [selectedCategory, setSelectedCategory] = useState(''); // State for category selection
useEffect(() => { 
    const fetchFirestoreDetails = async () => {
        try {
            // Fetch data from 'MenuItems' collection
            const menuItemsSnapshot = await getDocs(collection(firestore3, 'Items'));
            const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Fetch data from 'Deals' collection
            const dealsSnapshot = await getDocs(collection(firestore2, 'Deals'));
            const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Combine the results of both collections
            const details = [...menuItems, ...deals];

            // Get unique categories (filtering duplicates and empty values)
            const uniqueCategories = Array.from(new Set(
                details
                    .map(item => {
                        // Check if category exists and is a string before trimming
                        if (typeof item.category === 'string') {
                            return item.category.trim();  // Trim whitespace
                        }
                        return ''; // If category is not a string, return an empty string
                    })
                    .filter(Boolean) // Remove falsy values (null, undefined, empty strings, etc.)
            ));

            // Define the order for categories
            const categoryOrder = [
                'Peri Peri',
                'Shake',
                'Pizza',
                'Sandwiches',
                'Fried',
                'Sides',
                'Hot Coffee',
                'Pasta',
                'Cold Drinks',
                'Desserts'
            ];

            // Sort the unique categories based on the predefined order
            const sortedCategories = uniqueCategories.sort((a, b) => {
                return categoryOrder.indexOf(a) - categoryOrder.indexOf(b);
            });

            // Add 'All Categories' to the list only if it's not already present
            const finalCategories = ['All Categories', ...sortedCategories.filter(cat => cat !== 'All Categories')];

            // Set the final sorted categories with "All Categories" at the top
            setCategories(finalCategories);

            // console.log('Categories:', finalCategories);
        } catch (error) {
            console.error('Error fetching Firestore details:', error);
        } finally {
            setLoading(false);
        }
    };

    fetchFirestoreDetails();
}, []);




    const [updatedSizes, setUpdatedSizes] = useState({
        small: '',
        medium: '',
        large: '',
        xlarge: ''
    });

    useEffect(() => {
        fetchFirestoreDetails();
    }, []);

    const fetchFirestoreDetails = async () => {
        try {
            const menuItemsSnapshot = await getDocs(collection(firestore3, 'Items'));
            const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'Items' }));
            
            const dealsSnapshot = await getDocs(collection(firestore3, 'Deals'));
            const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'Deals' }));
            
            const popularSnapshot = await getDocs(collection(firestore3, 'PopularItems'));
            const Popular = popularSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'PopularItems' }));
    console.log('popular items' , Popular)
            // Combine all the collections
            const details = [...menuItems, ...deals, ...Popular];
            setFirestoreDetails(details);
            console.log(details);
        } catch (error) {
            console.error('Error fetching Firestore details:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleEditClick = (item) => {
        setEditItem(item);
        setUpdatedTitle(item.title);
        setSequenceNumber(item.SequenceNumber);
        setUpdatedImage(null);
        setUpdatedPrice(item.price);
        setUpdatedCategory(item.category);
        setupdateMenu(item.MenuFrom);
        setUpdatedShortcut(item.shortcut || '');
        setUpdatedMiniData(item.miniData || '');
        setUpdatedMiniData1(item.miniData1 || '');
        setUpdatedMiniData2(item.miniData2 || '');
        setUpdatedMiniData3(item.miniData3 || '');
        setUpdatedMiniData4(item.miniData4 || '');
        setUpdatedMiniData5(item.miniData5 || '');
        setUpdatedMiniData6(item.miniData6 || '');
        console.log('category' , item.category)
        if (item.category === 'Pizza') {
            const sizePrices = item.sizes.reduce((acc, size) => {
                // Extract the size name before the parentheses (e.g., "small", "medium", etc.)
                const sizeKey = size.size.split('(')[0].trim().toLowerCase().replace('-', ''); // Remove hyphen
            acc[sizeKey] = size.price;
            return acc;
        }, {});
    
            console.log('item prizes' ,sizePrices )
            setUpdatedSizes(sizePrices);
    
            // Handle Crust as an object (key-value pairs)
            if (item.Crust) {
                setUpdatedCrustOptions(Object.entries(item.Crust).map(([name, price]) => ({
                    name,
                    price: price.toString(),  // Ensure price is a string for form inputs
                })));
            } else {
                setUpdatedCrustOptions([]);  // Default empty array if no crust options exist
            }
        }
    };
    
   
    
    
    
    useEffect(() => {
        // Check if the image URL is updated
        if (updatedImage) {
            console.log("Image URL updated:", updatedImage);
        }
    }, [updatedImage]);  // This will trigger when updatedImage changes
    
    
    const handleImageUpload = async (file) => {
        // Ensure you're uploading to the correct folder in Firebase Storage
        const imageRef = ref(storage, `menu-images/${file.name}`);  // Use `menu-images` folder instead of `images`
    
        try {
            const snapshot = await uploadBytes(imageRef, file);  // Upload image to Firebase Storage
            const downloadURL = await getDownloadURL(snapshot.ref);  // Get the download URL for the image
            setUpdatedImage(downloadURL);  // Store the URL in the state
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
    
    
    

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        setchangeText(false);
        
        const updatedData = {};
        if (SequenceNumber !== editItem.SequenceNumber) {
            updatedData.SequenceNumber = SequenceNumber;  // Only update if title is changed
        }
        if (isPopular !== editItem.isPopular) {
            updatedData.isPopular = isPopular;
        }
        // 1. Handle Image Update
        if (updatedImage && updatedImage !== editItem.image) {
            updatedData.image = updatedImage;  // Update the image URL if it's changed
        }
    
        // 2. Handle Title Update
        if (updatedTitle !== editItem.title) {
            updatedData.title = updatedTitle;  // Only update if title is changed
        }
    
        // 3. Handle Category Update
        if (updatedCategory !== editItem.category) {
            updatedData.category = updatedCategory;
        }
    
        // 4. Handle Price Update
        if (updatedPrice !== editItem.price) {
            updatedData.price = parseFloat(updatedPrice);
        }
    
        // 5. Handle Shortcut Update (parseInt to ensure it's a number)
        if (updatedShortcut !== editItem.Shortcut) {
            const shortcutNumber = parseInt(updatedShortcut, 10);
            if (!isNaN(shortcutNumber)) {
                updatedData.Shortcut = shortcutNumber;  // Only update if it's a valid number
            }
        }
    
        // 6. Handle MenuFrom Update
        if (updateMenu && updateMenu !== editItem.MenuFrom) {
            updatedData.MenuFrom = updateMenu;  // Only update if MenuFrom is different
        }
    
        // 7. Handle Crust Options Update (for Pizza category)
        if (updatedCategory === 'Pizza' && updatedCrustOptions.length > 0) {
            const crustObject = updatedCrustOptions.reduce((acc, crust) => {
                if (crust.name && crust.price) {
                    acc[crust.name] = parseFloat(crust.price);
                }
                return acc;
            }, {});
            if (Object.keys(crustObject).length > 0) {
                updatedData.Crust = crustObject;  // Only update if Crust options are valid
            }
        }
    
        // 8. Handle Sizes Update (for Pizza category)
        if (updatedCategory === 'Pizza') {
            const updatedSizesData = {
                small: parseFloat(updatedSizes.small),
                medium: parseFloat(updatedSizes.medium),
                large: parseFloat(updatedSizes.large),
                xlarge: parseFloat(updatedSizes.xlarge)
            };
    
            const sizesToUpdate = [];
            if (!isNaN(updatedSizesData.small)) sizesToUpdate.push({ size: 'small(7)', price: updatedSizesData.small });
            if (!isNaN(updatedSizesData.medium)) sizesToUpdate.push({ size: 'medium(10)', price: updatedSizesData.medium });
            if (!isNaN(updatedSizesData.large)) sizesToUpdate.push({ size: 'large(13)', price: updatedSizesData.large });
            if (!isNaN(updatedSizesData.xlarge)) sizesToUpdate.push({ size: 'x-large(16)', price: updatedSizesData.xlarge });
    
            if (sizesToUpdate.length > 0) {
                updatedData.sizes = sizesToUpdate;  // Only update if sizes are valid
            }
        }
    
        // 9. If no changes were made, alert the user and exit
        if (Object.keys(updatedData).length === 0) {
            alert('No changes were made.');
            setchangeText(true);
            return;
        }
    
        // Prepare Firestore update reference
        try {
            let itemRef;
            if (editItem.source === 'Deals') {
                itemRef = doc(firestore3, 'Deals', editItem.id);
            } else if (editItem.source === 'PopularItems') {
                itemRef = doc(firestore3, 'PopularItems', editItem.id);
            } else {
                itemRef = doc(firestore3, 'Items', editItem.id);
            }
    
            // Perform the update
            await updateDoc(itemRef, updatedData);
    
            alert('Item updated successfully!');
            setchangeText(true);
    
            // Reset states after successful update
            setEditItem(null);
            setUpdatedTitle('');
            setUpdatedImage(null);
            setUpdatedPrice('');
            setUpdatedCategory('');
            setUpdatedShortcut('');
            setUpdatedSizes({ small: '', medium: '', large: '', xlarge: '' });
            setUpdatedCrustOptions([]);
            setLoading(true);
    
            // Fetch updated data
            fetchFirestoreDetails();
        } catch (error) {
            setchangeText(true);
            console.error('Error updating item:', error);
        }
    };
    
    
    
    
    
    const filteredItems = firestoreDetails.filter(item => {
        // If the search term is a shortcut number (e.g., :123), match by Shortcut (id)
        // if (isShortcutSearch(searchTerm)) {
        //     const shortcutNumber = searchTerm.substring(1); // Get the number after ":"
        //     console.log('Searching for Shortcut:', shortcutNumber);
    
        //     // Ensure the Shortcut field exists and matches the search term
        //     return item.Shortcut && item.Shortcut.toString() === shortcutNumber;
        // }
    
        // Regular search by title or category (case-insensitive)
        const titleMatches = item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase());
        const categoryMatches = item.category && item.category.toLowerCase().includes(searchTerm.toLowerCase());
        // console.log('category matches' , categoryMatches)
    
        // Apply category filtering if a category is selected
        const categoryFilterMatches = selectedCategory ? item.category === selectedCategory : true;
    
        return (titleMatches || categoryMatches) && categoryFilterMatches;
    });
    
    
    
    

    const handleRemoveClick = async (itemId, category) => {
        const confirmation = window.confirm("Are you sure you want to remove this item?");
        
        if (!confirmation) {
            return;  // Exit if the user cancels
        }
    
        // Ensure the category and itemId are not undefined or empty
        if (!itemId || !category) {
            alert('Invalid item or category.');
            return;
        }
    
        // Construct references to all relevant collections
        const menuItemRef = doc(firestore3, 'MenuItems', itemId);
        const dealsRef = collection(firestore3, 'Deals');
        const popularRef = collection(firestore3, 'Popular');
    
        try {
            // Check if the item exists in the Deals collection
            const dealsSnapshot = await getDocs(dealsRef);
            const itemInDeals = dealsSnapshot.docs.some(doc => doc.id === itemId);
            
            // Check if the item exists in the Popular collection
            const popularSnapshot = await getDocs(popularRef);
            const itemInPopular = popularSnapshot.docs.some(doc => doc.id === itemId);
    
            // If item exists in either Deals or Popular, do not remove from MenuItems
            if (itemInDeals || itemInPopular) {
                alert("Item cannot be removed because it exists in another collection (Deals or Popular).");
                return;
            }
    
            // If item is not found in Deals or Popular, remove it from MenuItems
            await deleteDoc(menuItemRef);
            alert("Item removed successfully from MenuItems!");
    
            // Reload the data after deletion
            setLoading(true);
            fetchFirestoreDetails();  // Refresh the data in the UI
    
        } catch (error) {
            console.error("Error removing item:", error);
            alert("Failed to remove item. Please try again.");
        }
    };
    
    
    

    return (
        <div className="orders-container">
            <h1 className="orders-title">Update Item</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search items with title or special number (eg: :123)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="category-boxes">
  {/* <button
    className={`category-box ${selectedCategory === '' ? 'active' : ''}`}
    onClick={() => setSelectedCategory('')}
  >
    {/* <span>All Categories</span> */}
  {/* </button> */} 
  {categories.map((category) => (
    <button
      key={category}
      className={`category-box ${selectedCategory === category ? 'active' : ''}`}
      onClick={() => setSelectedCategory(category)}
    >
      {/* <i className={`icon ${getCategoryIcon(category)}`}></i> */}
      <span>{category}</span>
    </button>
  ))}
</div>
            {loading ? (
                <p className="loading-text">Loading items...</p>
            ) : (
                <>
                    <ul className="item-list">
                    <ul className="item-list">
    {filteredItems.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase())).map(item => (
        <li key={item.id} className="item-card">
            <img src={item.image} alt={item.title} className="item-image" />
            <h2 className="item-title">{item.title}</h2>
            {item.source === 'Deals' ? (
                    <>
                    <div>
                    <ul>
    {/* Render miniData if it exists */}
    {item.miniData && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData}
        </li>
    )}

    {/* Render miniData1 if it exists */}
    {item.miniData1 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData1}
        </li>
    )}

    {/* Render miniData2 if it exists */}
    {item.miniData2 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData2}
        </li>
    )}

    {/* Render miniData3 if it exists */}
    {item.miniData3 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData3}
        </li>
    )}

    {/* Render miniData4 if it exists */}
    {item.miniData4 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData4}
        </li>
    )}

    {/* Render miniData5 if it exists */}
    {item.miniData5 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData5}
        </li>
    )}

    {/* Render miniData6 if it exists */}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData6}
        </li>
    )}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData7}
        </li>
    )}
</ul>

                    
                    </div>
                       
                        <p className="item-price">Price: Rs. {item.price}</p>
                    </>
                ) : (
                    <p className="item-price">
                        Price: {item.category === 'Pizza' ? 'Varies by size' : `Rs. ${item.price}`}
                    </p>
                )}
            {/* Display source of the item */}

            <div className="item-source"><strong>Source:</strong>  {item.source}</div>
            <div className="item-source"><strong>Menu:</strong>  {item.MenuFrom} </div>
            <div className="item-source"><strong>Sequence Number:</strong>  {item.SequenceNumber}</div>

            <div style={{display:'flex',border:'1px solid #c42027',marginTop:'15px'}}>
            <button className="add-to-cart-button" style={{color:'black'}}  onClick={() => handleEditClick(item)}>Update</button>
            <button 
                className="add-to-cart-button"
                style={{borderLeft:'1px solid #c42027'}}
                onClick={() => handleRemoveClick(item.id, item.category)}
            >
                Remove
            </button>

            </div>
           
        </li>
    ))}
</ul>

                    </ul>

                    {editItem && (
                        <div className="update-form" >
                            <div className="form-container">
                                <button className="close-btn" onClick={() => setEditItem(null)}>&times;</button>
                                <h2>Edit Item</h2>
                                <form className='main-form-sty' onSubmit={handleUpdateSubmit} style={{height:'50vh',overflowY:'scroll',overflowX:'hidden'}}>
                                    <div>
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={updatedTitle}
                                            onChange={(e) => setUpdatedTitle(e.target.value)}
                                        />
                                    </div>
                                    <div>
    <label>Category:</label>
    <select
        value={updatedCategory}
        onChange={(e) => setUpdatedCategory(e.target.value)}
    style={{width:'100%',height:'35px',border:'1px solid silver',marginTop:'5px',marginBottom:'10px'}}
    >
        {categories.map((category, index) => (
            <option key={index} value={category}>
                {category}
            </option>
        ))}
    </select>
</div>

<div>
        <label>Menu:</label>
        <select
            value={updateMenu}
            onChange={(e) => setupdateMenu(e.target.value)}
            style={{width:'100%',height:'40px',border:'1px solid silver',marginTop:'5px',marginBottom:'10px'}}
        >
            <option value="Hungrie">Hungrie</option>
            <option value="Hungrie-Exp">Hungrie-Exp</option>
        </select>
    </div>
<div>
    <label>Shortcut Key:</label>
    <input
        type="number"
        value={updatedShortcut}
        onChange={(e) => setUpdatedShortcut(e.target.value)}
    />
</div>

                                    <div>
                                        <label>Image:</label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleImageUpload(e.target.files[0])}
                                        />
                                    </div>
                                    {editItem.source === 'Deals' ? (
                                <>
                                 {updatedMiniData && (
                                    <div>
                                        <label>Mini Data:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData}
                                            onChange={(e) => setUpdatedMiniData(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {updatedMiniData1 && (
                                    <div>
                                        <label>Mini Data 1:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData1}
                                            onChange={(e) => setUpdatedMiniData1(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {updatedMiniData2 && (
                                    <div>
                                        <label>Mini Data 2:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData2}
                                            onChange={(e) => setUpdatedMiniData2(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {updatedMiniData3 && (
                                    <div>
                                        <label>Mini Data 3:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData3}
                                            onChange={(e) => setUpdatedMiniData3(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {updatedMiniData4 && (
                                    <div>
                                        <label>Mini Data 4:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData4}
                                            onChange={(e) => setUpdatedMiniData4(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {updatedMiniData5 && (
                                    <div>
                                        <label>Mini Data 5:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData5}
                                            onChange={(e) => setUpdatedMiniData5(e.target.value)}
                                        />
                                    </div>
                                    )}
                                      {updatedMiniData6 && (
                                    <div>
                                        <label>Mini Data 6:</label>
                                        <input
                                            type="text"
                                            value={updatedMiniData6}
                                            onChange={(e) => setUpdatedMiniData6(e.target.value)}
                                        />
                                    </div>
                                    )}
                                    {/* <div>
                                        <label>Price:</label>
                                        <input
                                            type="number"
                                            value={updatedPrice}
                                            onChange={(e) => setUpdatedPrice(e.target.value)}
                                        />
                                    </div> */}
                                </>
                            ) : (
                                // Fields for other item types
                                <></>
                            )}
                                    {editItem && editItem.category === 'Pizza' ? (
    <div>
        <h3>Pizza Sizes</h3>
        <div>
            <label>Small Price (7 inches):</label>
            <input
                type="number"
                value={updatedSizes.small || ''}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, small: e.target.value })}
            />
        </div>
        <div>
            <label>Medium Price (10 inches):</label>
            <input
                type="number"
                value={updatedSizes.medium || ''}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, medium: e.target.value })}
            />
        </div>
        <div>
            <label>Large Price (13 inches):</label>
            <input
                type="number"
                value={updatedSizes.large || ''}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, large: e.target.value })}
            />
        </div>
        <div>
            <label>X-Large Price (16 inches):</label>
            <input
                type="number"
                value={updatedSizes.xlarge || ''}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, xlarge: e.target.value })}            />
        </div>

        <h3>Crust Options</h3>
        {Array.isArray(updatedCrustOptions) && updatedCrustOptions.map((crust, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
                <label>Crust Name:</label>
                <input
                    type="text"
                    value={crust.name}
                    onChange={(e) => {
                        const newCrustOptions = [...updatedCrustOptions];
                        newCrustOptions[index].name = e.target.value;
                        setUpdatedCrustOptions(newCrustOptions);
                    }}
                    placeholder="Crust name"
                />
                <label>Price:</label>
                <input
                    type="number"
                    value={crust.price}
                    onChange={(e) => {
                        const newCrustOptions = [...updatedCrustOptions];
                        newCrustOptions[index].price = e.target.value;
                        setUpdatedCrustOptions(newCrustOptions);
                    }}
                    placeholder="Crust price"
                />
                {updatedCrustOptions.length > 1 && (
                    <button
                        type="button"
                        onClick={() => {
                            const newCrustOptions = updatedCrustOptions.filter((_, i) => i !== index);
                            setUpdatedCrustOptions(newCrustOptions);
                        }}
                        style={{color:'black',backgroundColor:'silver',border:'1px solid silver',height:'30px',padding:'0px',width:'30%'}}
                    >
                        Remove Crust
                    </button>
                )}
            </div>
        ))}

        {/* <button
            type="button"
            style={{color:'black',backgroundColor:'silver',border:'1px solid silver',height:'30px',padding:'0px',width:'100%',marginBottom:'10px'}}

            onClick={() => setUpdatedCrustOptions([...updatedCrustOptions, { name: '', price: '' }])}
        >
            Add New Crust Option
        </button> */}

    </div>
) : (
    <div>
        <label>Price:</label>
        <input
            type="number"
            value={updatedPrice || ''}
            onChange={(e) => setUpdatedPrice(e.target.value)}
        />
    </div>
)}
                                    <div>
                                        <label>Sequence Number:</label>
                                        <input
                                            type="text"
                                            value={SequenceNumber}
                                            onChange={(e) => setSequenceNumber(e.target.value)}
                                        />
                                    </div>
<div style={{display:'flex',alignItems:'center'}}>
    <label>Is Popular:</label>
    <input
        type="checkbox"
        checked={isPopular}  // Add state to track checkbox status
        onChange={(e) => setIsPopular(e.target.checked)}  // Update state when checkbox is toggled
    />
</div>


                                    {/* {editItem.category === 'Pizza' ? (
                                        <>
                                            <div>
                                                <label>Small Price (7 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.small}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, small: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Medium Price (10 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.medium}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, medium: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Large Price (13 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.large}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, large: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>X-Large Price (16 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.xlarge}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, xlarge: e.target.value })}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <label>Price:</label>
                                            <input
                                                type="number"
                                                value={updatedPrice}
                                                onChange={(e) => setUpdatedPrice(e.target.value)}
                                            />
                                        </div>
                                    )} */}
                                    <button type="submit">
                                        
                                        {
                                            changeText?
                                            "Update Item"
                                            :
                                            'Updating...'
                                        }
                                        </button>
                                </form>
                                <button className="cancel-btn" style={{marginTop:'3px',width:'100%',height:'30px',border:'1px solid silver'}} onClick={() => setEditItem(null)}>Cancel</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UpdateItems;
