import React, { useState } from "react";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApps, initializeApp } from "firebase/app";
import '../../Styles/Addtional.css'

// Firebase configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find((app) => app.name === "checkoutApp") || initializeApp(checkoutFirebaseConfig, "checkoutApp");
const firestore = getFirestore(checkoutApp);

const Payroll = () => {

  return (
    <div className="orders-container">
      <h1 className="title">coming soon</h1>
      
    </div>
  )
}

export default Payroll
