
import '../Pages-4/TaxItems.css';

const TaxItems = () => {
  const taxItems = [
    { type: 'VAT', percentage: 5, businessTransactionAmount: 1000, currency: 'USD' },
    { type: 'Sales Tax', percentage: 8, businessTransactionAmount: 1500, currency: 'USD' },
    { type: 'Service Tax', percentage: 10, businessTransactionAmount: 2000, currency: 'EUR' },
    { type: 'Excise Duty', percentage: 12, businessTransactionAmount: 2500, currency: 'USD' },
  ];

  return (
    <div className="h-tax-items-container">
      
      <table className="h-tax-items-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Percentage</th>
            <th>Business Transaction Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {taxItems.map((item, index) => (
            <tr key={index}>
              <td>{item.type}</td>
              <td>{item.percentage}%</td>
              <td>${item.businessTransactionAmount}</td>
              <td>{item.currency}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TaxItems;