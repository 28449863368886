import React, { useEffect, useState } from 'react'
import './Terminal.css'
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate ,useLocation } from 'react-router-dom'; 
import TimeRecording from './Functions/TimeRecording';
import Amount from '../Amount/Amount';
import DayEnd from './Functions/DayEnd';
import Receipt from './Features/Receipt';


const ToolbarTerminal = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const IsFeatures = location.pathname === '/Terminal'
    const IsUser = location.pathname === '/POSInterface'

    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [date, setDate] = useState(new Date().toLocaleDateString());
    const [Popup, setPopup] = useState(false);
    const [TimeRecordingPopup, setTimeRecordingPopup] = useState(false);
    const [CashInPopup, setCashInPopup] = useState(false);
    const [DayEndPopup, setDayEndPopup] = useState(false);
    const [FeaturesPopup, setFeaturesPopup] = useState(false);

    const email = localStorage.getItem('email') || "user@example.com";
  
    useEffect(() => {
      const timer = setInterval(() => {
        const time = new Date();
        setCurrentTime(time.toLocaleTimeString());
        setDate(time.toLocaleDateString());
      }, 1000);
      return () => clearInterval(timer);
    }, []); 


  return (
    <>
    <header className='toolbar-header'>



{/* --------------------------------section 1----------------------------------------------------------- */}




        <div className='img-section'>
            <img src='logo2.png' className='terminal-img'/>
            <div>
                <div className='text-section-1'>{currentTime}</div>
                <div className='text-section-1'>{date}</div>
                <div className='text-section-1' style={{marginTop:'5px',fontWeight:'bold'}}>{email}</div>
           </div>
        </div>




{/* --------------------------------section 2----------------------------------------------------------- */}



              {!IsUser && (
                  <div className='text-section'>
                      <>
                          <div className='text-section-2' onClick={() => navigate('/Terminal')}>Sales</div>
                          <div className='text-section-2' onClick={() => navigate('/Tables')}>Tables</div>
                          <div className='text-section-2' onClick={() => navigate('/Park-Receipt')}>Park Receipt</div>
                          <div className='text-section-2' onClick={() => setPopup(true)}>Functions</div>
                      </>

                      {IsFeatures && (
                          <>
                              <div className='text-section-2' onClick={() => navigate('/POSInterface')}>User Side</div>

                              <div className='text-section-2' onClick={() => setFeaturesPopup(true)}>Features</div>
</>

                      )}
                  </div>
              )}



        </header>

{/* --------------------------------Popup----------------------------------------------------------- */}


{
    Popup ? 

    <div className='funtion-popup'>
        <div className='funtion-popup-content'>
                          <button className='close-popup'><IoCloseCircleOutline style={{ cursor: 'pointer' }} onClick={() => setPopup(false)} /></button>

                          <div className='functions-main'>
                              <div className='funtions-option' onClick={() => setTimeRecordingPopup(true)}>Time Recording</div>
                              <div className='funtions-option' onClick={() => setCashInPopup(true)}>Cash-in / Cash-out</div>
                              <div className='funtions-option' onClick={() => setDayEndPopup(true)}>Day end closing</div>
                              <div className='funtions-option' onClick={() => navigate('/Configuration')}>Configuration</div>
                              {/*<div className='funtions-option' onClick={() => navigate('/Configuration')}>Hold Receipt</div>*/}

                          </div>


        </div>
    </div>

    : null
}
          {TimeRecordingPopup && (
              <div className="payment-popup-overlay">
                  <div className="payment-popup-content-items">

                      <TimeRecording />
                      <button className='sec-cancel-button' onClick={() => setTimeRecordingPopup(false)} >Cancel</button>

                  </div>
              </div>
          )}
          {CashInPopup && (
              <div className="payment-popup-overlay">
                  <div className="payment-popup-content-items-2">

                      <Amount setCashInPopup={setCashInPopup} />
                      <button className='sec-cancel-button' onClick={() => setCashInPopup(false)} >Cancel</button>

                  </div>
              </div>
          )}
          {DayEndPopup && (
              <div className="payment-popup-overlay">
                  <div className="payment-popup-content-items-3">

                      <DayEnd setDayEndPopup={setDayEndPopup} />

                  </div>
              </div>
          )}
          {FeaturesPopup && (
              <div className="payment-popup-overlay">
                  <div className="payment-popup-content-items-3">

                      <Receipt setFeaturesPopup={setFeaturesPopup} />

                  </div>
              </div>
          )}
</>

  )
}

export default ToolbarTerminal
