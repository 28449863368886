
import "../Pages-4/SalesItems.css";

const SalesItems = () => {
  const dummyData = [
    {
      position: 1,
      articleId: "A123",
      typeCode: "TC001",
      description: "Sample Article 1",
      quantity: 5,
      uom: "pcs",
      taxCode: "TX001",
      unitGrossAmount: "$10.00",
      currency: "USD",
      discountGrossAmount: "$2.00",
      discountPercentage: "20%",
      paymentNetAmount: "$8.00",
      paymentTaxAmount: "$1.00",
      paymentGrossAmount: "$9.00",
    },
    {
      position: 2,
      articleId: "A124",
      typeCode: "TC002",
      description: "Sample Article 2",
      quantity: 3,
      uom: "pcs",
      taxCode: "TX002",
      unitGrossAmount: "$15.00",
      currency: "USD",
      discountGrossAmount: "$3.00",
      discountPercentage: "20%",
      paymentNetAmount: "$12.00",
      paymentTaxAmount: "$1.50",
      paymentGrossAmount: "$13.50",
    },
  ];

  return (
    <div className="h-sales-items-page">
      
      <table className="h-sales-items-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Article ID</th>
            <th>Type Code</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th>Tax Code</th>
            <th>Unit Gross Amount</th>
            <th>Currency</th>
            <th>Discount Gross Amount</th>
            <th>Discount %</th>
            <th>Payment Net Amount</th>
            <th>Payment Tax Amount</th>
            <th>Payment Gross Amount</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((item, index) => (
            <tr key={index}>
              <td>{item.position}</td>
              <td>{item.articleId}</td>
              <td>{item.typeCode}</td>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>{item.uom}</td>
              <td>{item.taxCode}</td>
              <td>{item.unitGrossAmount}</td>
              <td>{item.currency}</td>
              <td>{item.discountGrossAmount}</td>
              <td>{item.discountPercentage}</td>
              <td>{item.paymentNetAmount}</td>
              <td>{item.paymentTaxAmount}</td>
              <td>{item.paymentGrossAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesItems;
