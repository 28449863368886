import React, { useState } from "react";
import { TaxRates, DiscountRecipit, TaxRatesDefault, ReducedTax } from './ParentComp'
import "./Features.css";
import { useCheckoutContext } from '../../../Context/Data';



const tabs = [
    "RECEIPT", "SALES ITEM", "PAYMENTS", "RETAIL", "TABLE SERVICE",
    "QUICK SERVICE", "CUSTOMER AND LOYALTY", "OTHERS",
];

const receiptOptions = {
    "RECEIPT": [
        "Cancel receipt", "Receipt discount", "Default tax", "Reduced tax",
        "Tax rate", "Warehouse", "Sales Person", "Text note", "Display receipt details",
        "Receipt type", "Coupon", "Print receipt", "Split/merge", "Split items",
        "One-time customer", "Return receipt", "Reverse receipt by ID (deprecated)",
        "Park receipt", "Resume receipt", "Number of guests", "Change pricelist",
        "Sales item delivery", "Fetch sales order", "Fetch sales quotation",
        "Fetch invoice", "Fetch down payment", "Fetch paid down payment", "Fetch credit memo",
        "Fetch payment on account", "Shipment information", "Tracking number",
        "Reverse receipt", "Reopen receipt"
    ],
    "SALESITEM": [
        'Cancel item', 'Item discount', 'Item course', 'Item Defaul-tax', 'Item reduce-tax', 'Item sales-person',
        'Item warehouse', 'Item tax-rate', 'Item note', 'Item Details', 'Item description', 'Bactch/serial number', 'Stock overview',
        'Change item price', 'Change item quantity'
    ],
    "PAYMENTS": [
        'Exact amount', 'Cash payment', 'Card payment', 'Gift card payment', 'Pay-on credit',
    ],
    "RETAIL": [
        'Cancel receipt', 'Receipt discount', 'Sales person', 'Warehouse', 'Tax rate', 'Text note',
        'Display receipt details', 'Coupon', 'Print receipt', 'Split items', 'Reverse receipt by ID (deprecated)', 'Change pricelist',
        'Cancel item', 'Item discount', 'Item sales-person', 'Item warehouse', 'Item tax-rate', 'Item note', 'Item details',
        'Item description', 'Batch/serial number', 'Stock overview', 'Pay exact amount', 'Cash payment', 'Card payment', 'Gift card payment',
        'Pay-on credit', 'Reverse receipt', 'Reopen receipt',
    ],
    "TABLESERVICE": [
        'Cancel receipt', 'Receipt discount', 'Default tax', 'Reduced tax', 'Coupon', 'Print receipt',
        'Split/merge', 'Split items', 'Reverse receipt by ID (deprecated)', 'Number of guests', 'Change pricelist', 'Cancel item',
        'Item course', 'Item default-tax', 'Item reduced-tax', 'Pay exact amount', 'Cash payment', 'Card payment', 'Gift card payment', 'Serve course',
        'Reopen receipt', 'Add Pager',
    ],
    "QUICKSERVICE": [
        "Cancel receipt", "Receipt discount", "Default tax", "Reduced tax %", "Coupon", "Print receipt", "Split items", "One-time customer", "Return receipt",
        "Reverse receipt by ID (deprecated)", "Number of guests", "Change pricelist", "Sales item delivery", "Cancel item", "Item discount",
        "Item default-tax", "Item reduced-tax", "Exact amount", "Cash payment", "Card payment", "Gift card payment", "Loyalty payment",
        "Fetch external-object", "Add Pager", "Reverse receipt", "Reopen receipt"
    ],
    "CUSTOMERANDLOYALTY": [
        "Loyalty coupon", "Assign customer user", "Loyalty payment", "Create customer user", "Coupon", "Create customer",
        "Edit customer", "Purchased items", "Last purchases 8", "One-time customer", "Number of guests",
        "Display loyalty accounts", "Use promotion code", "Display loyalty information"
    ],
    "OTHERS": [
        "Cancel receipt", "Receipt discount", "Default tax", "Reduced tax 8", "Sales person", "Warehouse", "Tax rate", "Text note",
        "Display receipt details", "Receipt type", "Coupon", "Print receipt 00", "Split/merge", "Split items 8", "One-time customer",
        "Return receipt", "Reverse receipt by ID (deprecated)", "Number of guests", "Sales item delivery", "Change pricelist", "Park receipt",
        "Resume receipt", "Fetch sales order", "Fetch sales quotation", "Fetch invoice", "Fetch down payment", "Fetch paid down payment",
        "Fetch credit memo", "Fetch payment on account", "Shipment information", "Tracking number", "Tax exemption", "Reverse receipt", "Reopen receipt"
    ],
};


const ReceiptApp = ({ setFeaturesPopup }) => {
    const [activeTab, setActiveTab] = useState("RECEIPT");
    const [activePopup, setActivePopup] = useState(null);
    const {CancelItemAllP } = useCheckoutContext();

    const getReceiptOptions = () => {
        switch (activeTab) {
            case "RECEIPT":
                return receiptOptions.RECEIPT;
            case "SALES ITEM":
                return receiptOptions.SALESITEM;
            case "PAYMENTS":
                return receiptOptions.PAYMENTS;
            case "RETAIL":
                return receiptOptions.RETAIL;
            case "TABLE SERVICE":
                return receiptOptions.TABLESERVICE;
            case "QUICK SERVICE":
                return receiptOptions.QUICKSERVICE;
            case "CUSTOMER AND LOYALTY":
                return receiptOptions.CUSTOMERANDLOYALTY;
            case "OTHERS":
                return receiptOptions.OTHERS;
            default:
                return [];
        }
    };

    const getPopupComponent = () => {
        switch (activePopup) {

      //----------------------------------------- Recipts -------------------------------------------

            case "Tax rate":
                return <TaxRates setActivePopup={setActivePopup} />;


            case "Cancel receipt":
                return <div  className='cancel-recipt'>
                    <div className='cancel-recipt-text'>
                        Are you sure to cancel receipt
                    </div>
                    <button onClick={() => { CancelItemAllP(); setActivePopup(null); }}  className='cancel-recipt-button'>Cancel Receipt</button>
                </div>;

            case "Receipt discount":
                return <div>
                    <DiscountRecipit setActivePopup={setActivePopup } />
                </div>;

            case "Default tax":
                return <TaxRatesDefault setActivePopup={setActivePopup} />
               
            case "Reduced tax":
                return <ReducedTax setActivePopup={setActivePopup} />
            default:
                return <div>Option not found</div>;
        }
    };

    const handleOptionClick = (option) => {
        setActivePopup(option);
    };

    const closePopup = () => {
        setActivePopup(null); 
    };

    return (
        <div className="receipt-container">
            <button
                onClick={() => setFeaturesPopup(false)}
                style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', fontSize: '20px', letterSpacing: '10px' }}
            >
                cancel
            </button>

            <div className="receipt-sidebar">
                {tabs.map((tab) => (
                    <button key={tab} onClick={() => setActiveTab(tab)} className={activeTab === tab ? "receipt-active-tab" : "receipt-tab"}>
                        {tab}
                    </button>
                ))}
            </div>

            <div className="receipt-content">
                <div className="receipt-grid">
                    {getReceiptOptions().map((option) => (
                        <button key={option} className="receipt-grid-item" onClick={() => handleOptionClick(option)}>
                            {option}
                        </button>
                    ))}
                </div>
            </div>

            {activePopup && (
                <div className="uniques-popup-overlay" onClick={closePopup}>
                    <div className="uniques-popup-content" onClick={(e) => e.stopPropagation()}>
                        {getPopupComponent()}
                        <button className='sec-cancel-button' onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReceiptApp;
