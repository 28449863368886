import React, { useState, useEffect } from 'react';

const SAPSettingsForm = () => {
    const [settings, setSettings] = useState({
        guestsCheck: false,
        cancelEmptyReceipts: false,
        showCanceledItems: false,
        showCourseColumn: false,
        replaceChangeButton: false
    });

    const [isEditing, setIsEditing] = useState(false);

    const [fastTableName, setFastTableName] = useState('');

    useEffect(() => {
        const storedFastTableName = localStorage.getItem('fastTableName');
        if (storedFastTableName) {
            setFastTableName(storedFastTableName);
        } else {
            setFastTableName('99'); 
        }
    }, []);

    const handleFastTableNameChange = (e) => {
        setFastTableName(e.target.value);
    };

    const handleCheckboxChange = (key) => {
        setSettings((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleEditClick = () => {
        setIsEditing(true); 
    };

    const handleSaveClick = () => {
        localStorage.setItem('fastTableName', fastTableName);

        setIsEditing(false); 
    };

    const handleCancelClick = () => {
        setIsEditing(false); 
        setFastTableName(localStorage.getItem('fastTableName') || '99'); 
    };

    return (
        <div className="sap-settings-form-container">
            <div className="sap-settings-form-section">
                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Fast table name:
                        <span className="sap-settings-form-required">*</span>
                    </label>
                    {isEditing ? (
                        <input
                            type="text"
                            value={fastTableName}
                            onChange={handleFastTableNameChange}
                            className="sap-settings-form-input"
                        />
                    ) : (
                        <span>{fastTableName}</span>
                    )}
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Table owner display mode:
                    </label>
                    <div className="sap-settings-form-select-wrapper">
                        {isEditing ? (
                            <select className="sap-settings-form-select">
                                <option>Name (User name)</option>
                            </select>
                        ) : (
                            <span>Name (User name)</span>
                        )}
                    </div>
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Enable missing number of guests check for receipt sales:
                    </label>
                    <input
                        type="checkbox"
                        checked={settings.guestsCheck}
                        onChange={() => handleCheckboxChange('guestsCheck')}
                        className="sap-settings-form-checkbox"
                        disabled={!isEditing}
                    />
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Cancel empty receipts on canceling order:
                    </label>
                    <input
                        type="checkbox"
                        checked={settings.cancelEmptyReceipts}
                        onChange={() => handleCheckboxChange('cancelEmptyReceipts')}
                        className="sap-settings-form-checkbox"
                        disabled={!isEditing}
                    />
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Show canceled sales items in the receipt:
                    </label>
                    <input
                        type="checkbox"
                        checked={settings.showCanceledItems}
                        onChange={() => handleCheckboxChange('showCanceledItems')}
                        className="sap-settings-form-checkbox"
                        disabled={!isEditing}
                    />
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Always show course column:
                    </label>
                    <input
                        type="checkbox"
                        checked={settings.showCourseColumn}
                        onChange={() => handleCheckboxChange('showCourseColumn')}
                        className="sap-settings-form-checkbox"
                        disabled={!isEditing}
                    />
                </div>

                <div className="sap-settings-form-item">
                    <label className="sap-settings-form-label">
                        Replace 'Change payment' button with 'Reopen receipt' button:
                    </label>
                    <input
                        type="checkbox"
                        checked={settings.replaceChangeButton}
                        onChange={() => handleCheckboxChange('replaceChangeButton')}
                        className="sap-settings-form-checkbox"
                        disabled={!isEditing}
                    />
                </div>
            </div>

            <div className="checkout-bottom-bar">
                <button className="bottom-bar-action-btn">
                    <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                </button>
                {isEditing ? (
                    <>
                        <button className="bottom-bar-action-btn" onClick={handleSaveClick}>
                            Save
                        </button>
                        <button className="bottom-bar-action-btn" onClick={handleCancelClick}>
                            Cancel
                        </button>
                    </>
                ) : (
                    <button className="bottom-bar-action-btn edit-btn" onClick={handleEditClick}>
                        Edit
                    </button>
                )}
            </div>
        </div>
    );
};

export default SAPSettingsForm;
