import "../Pages-4/Orders.css";

const Orders = () => {
  const orders = [
    {
      orderId: "ORD123",
      timestamp: "2025-01-15 14:30:00",
      orderStatus: "Completed",
      salesItemPosition: 1,
      articleId: "A001",
      description: "Wireless Mouse",
      quantity: 2,
    },
    {
      orderId: "ORD124",
      timestamp: "2025-01-16 09:15:00",
      orderStatus: "Pending",
      salesItemPosition: 2,
      articleId: "A002",
      description: "Bluetooth Keyboard",
      quantity: 1,
    },
    {
      orderId: "ORD125",
      timestamp: "2025-01-17 16:45:00",
      orderStatus: "Shipped",
      salesItemPosition: 3,
      articleId: "A003",
      description: "Laptop Stand",
      quantity: 3,
    },
    {
      orderId: "ORD126",
      timestamp: "2025-01-18 11:00:00",
      orderStatus: "Completed",
      salesItemPosition: 4,
      articleId: "A004",
      description: "USB-C Charger",
      quantity: 5,
    },
  ];

  return (
    <div className="h-orders-container">
      <table className="h-orders-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Timestamp</th>
            <th>Order Status</th>
            <th>Sales Item Position</th>
            <th>Article ID</th>
            <th>Description</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item, index) => (
            <tr key={index}>
              <td>{item.orderId}</td>
              <td>{item.timestamp}</td>
              <td>{item.orderStatus}</td>
              <td>{item.salesItemPosition}</td>
              <td>{item.articleId}</td>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
