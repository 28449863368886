import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import ToolbarTerminal from './ToolbarTerminal';
import TerminalItems from './TerminalItems';
import Actions from './Actions';
import ItemsTable from './ItemsTable';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query, where ,doc} from 'firebase/firestore';
import { getDoc } from 'firebase/firestore';
import { useCheckoutContext } from '../../Context/Data';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const MainTerminal = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const { Items, setItems, Coupons, setCoupons } = useCheckoutContext();
    const location = useLocation(); 

    useEffect(() => {

        const routeParts = location.pathname.split('/');
        const terminalId = routeParts[routeParts.length - 1];
        console.log(routeParts)
        console.log(terminalId)

        if (terminalId && terminalId !== 'Terminal') {
 
            const fetchItems = async () => {
                const tableDocRef = doc(firestore3, 'Tables', terminalId);  
                const docSnapshot = await getDoc(tableDocRef); 
                console.log('docSnapshot', docSnapshot);

                if (docSnapshot.exists()) {
                    const fetchedItems = docSnapshot.data().Items || []; 
                    console.log(fetchedItems);  
                    setItems(fetchedItems);
                } else {
                    console.log("No such document!");
                }
            };


            fetchItems();
        } else {
            setItems([]);
        }
    }, [location.pathname]); 

    useEffect(() => {

        const routeParts = location.pathname.split('/');
        const terminalId = routeParts[routeParts.length - 1];
        console.log(routeParts)
        console.log(terminalId)

        if (terminalId && terminalId !== 'Terminal') {

            const fetchItems = async () => {
                const tableDocRef = doc(firestore3, 'HoldReceipt', terminalId);
                const docSnapshot = await getDoc(tableDocRef);
                console.log('docSnapshot', docSnapshot);

                if (docSnapshot.exists()) {
                    const fetchedItems = docSnapshot.data().Items || [];
                    console.log(fetchedItems);
                    setItems(fetchedItems);
                } else {
                    console.log("No such document!");
                }
            };


            fetchItems();
        } else {
            setItems([]);
        }
    }, [location.pathname]); 


    const updateItemQuantity = (uniqueId, newQuantity, selectedToppings, toppingPrice) => {
        setItems((prevItems) => {
            return prevItems.map((item) =>
                item.uniqueId === uniqueId
                    ? {
                        ...item,
                        quantity: newQuantity,
                        toppings: selectedToppings,
                        toppingPrice: toppingPrice,
                    }
                    : item
            );
        });
    };

    const handleItemSelection = (item) => {
        const uniqueId = `${item.id}-${item.size || ''}-${item.crust || ''}`;

        const uniqueItem = `${item.id}-${item.size || ''}-${item.crust || ''}`;

        setItems((prevItems) => {
            const existingItem = prevItems.find((i) => i.uniqueId === uniqueId);

            if (existingItem) {
                return prevItems;
            }

            const selectedSize = item.size;
            const sizePrice = item.sizes.find((s) => s.size === selectedSize)?.price || 0;
            const crustPrice = item.crust && item.Crust[item.crust] ? parseFloat(item.Crust[item.crust]) : 0;

            const basePrice = item.category === 'Pizza'
                ? (parseFloat(sizePrice) + crustPrice)
                : parseFloat(item.price) || 0;

            return [
                ...prevItems,
                {
                    ...item,
                    uniqueId,
                    uniqueItem,
                    price: basePrice,
                    quantity: 1,
                },
            ];
        });
        //console.log('Item selected:', item)
        setSelectedItem(item);
    };

    const CancelItem = (item) => {
        setItems((prevItems) => {
            const updatedItems = prevItems.filter((i) => i.uniqueId !== item.uniqueId);
            return updatedItems;
        });
        setSelectedItem(null);
    };

    const CancelItemAll = () => {
        setItems([]);
        setSelectedItem(null);
    };

    const handleCouponUpdate = (discountPercent) => {
        setCoupons(discountPercent)
        console.log(`Discount applied: ${discountPercent}%`);
    };
 
    return (
        <>
            <ToolbarTerminal />
            <div className='main-terminal'>
                <TerminalItems setSelectedItem={handleItemSelection} />
                <Actions selectedItem={selectedItem} updateItemQuantity={updateItemQuantity} CancelItem={CancelItem} CancelItemAll={CancelItemAll} Coupons={Coupons} setCoupons={handleCouponUpdate} />
                <ItemsTable Items={Items} onSelectItem={handleItemSelection} selectedItem={selectedItem} updateItems={setItems} Coupons={Coupons} />
            </div>
        </>
    );
};

export default MainTerminal;
