import { useState } from "react";
import "../Pages-5/Details.css";

const Details = () => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    providedBySap: false,
    createdAt: "2025-01-21 10:00 AM",
    createdBy: "Admin",
    modifiedAt: "2025-01-25 02:00 PM",
    modifiedBy: "Editor",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="details-page">
      {/* General Data Section */}
      <div className="section">
        <h2>General Data</h2>

        <label htmlFor="id">ID:</label>
        <input
          type="text"
          id="id"
          name="id"
          value={formData.id}
          onChange={handleChange}
          placeholder="Enter ID"
          required
        />

        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter Name"
          required
        />

        <label htmlFor="sap-checkbox">
          <input
            type="checkbox"
            id="sap-checkbox"
            name="providedBySap"
            checked={formData.providedBySap}
            onChange={handleChange}
          />
          Provided by SAP
        </label>
      </div>

      {/* Administrative Data Section */}
      <div className="section">
        <h2>Administrative Data</h2>

        <label htmlFor="createdAt">Created At:</label>
        <input
          type="text"
          id="createdAt"
          name="createdAt"
          value={formData.createdAt}
          readOnly
        />

        <label htmlFor="createdBy">Created By:</label>
        <input
          type="text"
          id="createdBy"
          name="createdBy"
          value={formData.createdBy}
          readOnly
        />

        <label htmlFor="modifiedAt">Modified At:</label>
        <input
          type="text"
          id="modifiedAt"
          name="modifiedAt"
          value={formData.modifiedAt}
          readOnly
        />

        <label htmlFor="modifiedBy">Modified By:</label>
        <input
          type="text"
          id="modifiedBy"
          name="modifiedBy"
          value={formData.modifiedBy}
          readOnly
        />
      </div>
    </div>
  );
};

export default Details;
