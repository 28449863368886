// import React, { useEffect, useState } from 'react'; 
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles
// import { doc, updateDoc,getDoc,setDoc,deleteDoc  } from 'firebase/firestore';
// import axios from 'axios';
// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const KitchenOrdersList = () => {
//     const [kitchenOrders, setKitchenOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [selectedBilling, setSelectedBilling] = useState(null);
//     const [selectedBilling2, setSelectedBilling2] = useState(null);
//     const [showModal, setShowModal] = useState(false);

//     useEffect(() => {
//         const fetchKitchenOrders = async () => {
//             try {
//                 const querySnapshot = await getDocs(collection(firestore, 'ToDeliver'));
//                 const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                
//                 // Sort orders by sequence number
//                 orders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);
                
//                 console.log('Fetched ToDeliever Orders:', orders);
//                 setKitchenOrders(orders);
//             } catch (err) {
//                 console.error('Error fetching ToDeliever:', err);
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchKitchenOrders();
//     }, []);

//     const handleShowModal = (billing , order) => {
//         if (billing) {
//             setSelectedBilling(billing);
//         } else {
//             setSelectedBilling2(order); // Assuming 'order' is accessible here
//         }
//         setShowModal(true);
//     };
//     const handleShowModal2 = (order) => {
//         setSelectedBilling2(order);
       
//         setShowModal(true);
//     };
//     const handleCloseModal = () => {
//         setShowModal(false);
//         setSelectedBilling(null);
//     };

//     if (loading) {
//         return <p>Loading kitchen orders...</p>;
//     }

//     if (error) {
//         return <p>Error loading kitchen orders: {error}</p>;
//     }
//     const updateOrderStatus2 = async (sequenceNumber, status) => {
//         try {
//             console.log('Attempting to update order with Sequence Number:', sequenceNumber);
    
//             // Fetch all orders to find the document with the specific sequenceNumber
//             const querySnapshot = await getDocs(collection(firestore, 'ToDeliver'));
//             const orderToUpdate = querySnapshot.docs.find(doc => {
//                 const orderData = doc.data();
//                 return orderData.sequenceNumber === sequenceNumber; // Compare sequence numbers
//             });
    
//             if (!orderToUpdate) {
//                 console.error('No such document with Sequence Number:', sequenceNumber);
//                 return; // Exit if no matching document is found
//             }
    
//             const orderRef = doc(firestore, 'ToDeliver', orderToUpdate.id); // Get the reference using the found document's ID
    
//             // Update the order status in "ToDeliver"
//             await updateDoc(orderRef, { status });
    
//             // Log the entire orderData for debugging
//             const orderData = orderToUpdate.data();
//             console.log('Full Order Data:', orderData);
    
//             // Check if userId exists at the root level (not inside cartItems)
//             let userId = orderData.userId;
    
//             // Log userId for debugging
//             console.log('userId:', userId);
    
//             if (userId) {
//                 // Fetch OrderDetails for the specific userId to update their status
//                 const orderDetailsSnapshot = await getDocs(collection(firestore, 'OrderDetails'));
//                 const userOrderToUpdate = orderDetailsSnapshot.docs.find(doc => {
//                     const orderData = doc.data();
//                     return orderData.userId === userId; // Match the userId
//                 });
    
//                 if (userOrderToUpdate) {
//                     // If found, update the order status in 'OrderDetails'
//                     const orderDetailsRef = doc(firestore, 'OrderDetails', userOrderToUpdate.id);
//                     await updateDoc(orderDetailsRef, { status: 'completed' });
//                     console.log(`Order for userId ${userId} updated to 'completed' in OrderDetails.`);
//                 } else {
//                     console.log('No matching user found in OrderDetails.');
//                 }
//             } else {
//                 console.log('No userId found in the root of the order data.');
//             }
    
//             // After updating the status, move the order to the "Completed" collection
//             const completedOrdersRef = collection(firestore, 'CompletedOrders');
//             const newCompletedOrderRef = doc(completedOrdersRef); // Create a new document reference in "Completed"
            
//             // Add the updated order to "Completed"
//             await setDoc(newCompletedOrderRef, {
//                 ...orderToUpdate.data(), // Copy all the fields from the existing order
//                 status, // Make sure the status is updated
//                 sequenceNumber, // Retain the sequence number
//                 completedAt: new Date(), // Add the completed timestamp
//             });
    
//             // Now, delete the order from the "ToDeliver" collection
//             await deleteDoc(orderRef);
    
//             // Update the local state to reflect the change
//             setKitchenOrders(prevOrders =>
//                 prevOrders.filter(order => order.sequenceNumber !== sequenceNumber) // Remove the order from state
//             );
    
//             console.log('Order moved to Completed and deleted from ToDeliver.');
    
//         } catch (error) {
//             console.error('Error updating order status:', error);
//         }
//     };
    
    
    
    
    


    
    
    
    
    
    
//     return (
//         <div className="orders-container">
  
//             <h1 className="title">Orders to Deliver</h1>
//             <p style={{textAlign:"center"}}>Please check orders in order to refresh the orders in kitchen </p>
//             <ul className="orders-list">
                
//                 {kitchenOrders.map(order => (
//                       <>
//                     <li key={order.id} className="order-item">
//                         <p style={{ textDecoration: 'underline' }}>
//                             Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
//                         </p>
//                         <p className="order-details">
//                             Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total || order.totalPrice} {order.currency}</span> - Status: <span className="status" style={{fontWeight:'bold',fontSize:'20px'}}>{order.status}</span>
//                         </p>
//                         <ul>
                        
//                         {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
//      (order.cartItems || order.items).map((item) => (
//             <li key={item.id} className="line-item">
//                 {/* <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span> */}
//                 <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
//                                         <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
//                                         <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
//                 {/* Check for toppings and render them if available */}
//                 {Array.isArray(item.toppings) && item.toppings.length > 0 ? (
//                     <div className="item-toppings">
//                         <h4>Toppings:</h4>
//                         <ul>
//                             {item.toppings.map((topping, index) => (
//                                 <li key={index}>{topping}</li>
//                             ))}
//                         </ul>
//                     </div>
//                 ) : (
//                     <p>No toppings available.</p>
//                 )}

//                 {/* Check for options and render them if available */}
//                 {item.options && Object.keys(item.options).length > 0 && (
//                     <div className="item-options">
//                         <h4>Options:</h4>
//                         <ul>
//                             {Object.entries(item.options).map(([option, value]) => (
//                                 <li key={option}>
//                                     <strong>{option}:</strong> {value ? 'Yes' : 'No'}
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 )}
//             </li>
//         ))
//     ) : (
//         <p>No items in this order.</p>
//     )}




//                             {order.line_items && order.line_items.length > 0 ? (
//                                 order.line_items.map(item => (
//                                     <li key={item.id} className="line-item" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        
//                                         {/* <img src={item.image.src} alt={item.name} style={{ maxWidth: '100px', marginBottom: '10px' }} /> */}
//                                         <li key={item.id} className="line-item">
//                                         <p>Title: <span style={{ fontWeight: 'bold' }}>{item.name}</span></p>
//                                         <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
//                                         <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
// </li>
//                                         {/* Displaying Topping Options, excluding _ywapo_meta_data */}
//                                         {item.meta_data && item.meta_data.length > 0 ? (
//                                             <div className="topping-options">
//                                                 <h4>Topping Options:</h4>
//                                                 <ul>
//                                                     {item.meta_data
//                                                         .filter(meta => meta.key !== '_ywapo_meta_data') // Exclude unwanted key
//                                                         .map((meta, index) => (
//                                                             <li key={index}>
//                                                                 <strong>{meta.display_key}:</strong> {String(meta.display_value)}
//                                                             </li>
//                                                         ))}
//                                                 </ul>
//                                             </div>
//                                         ) : (
//                                             <p>No topping options available.</p>
//                                         )}

//                                         {/* Button to show billing information */}
                                        
//                                     </li>
//                                 ))
//                             ) : (
//                                 <p></p>
//                             )}
//                         </ul>
//                         <div className="buttons">
                   
                    
//                      {/* <button className="status-button on-hold" onClick={() => {updateOrderStatus2(order.sequenceNumber, 'on-hold') ; updateOrderStatus(order.id, 'on-hold') }}>On Hold</button> */}
                    
//                     <button className="status-button completed" onClick={() =>  updateOrderStatus2(order.sequenceNumber, 'completed')}> 
//     Delivered
// </button>
// <button className="status-button completed" onClick={() => {handleShowModal(order.billing || order.billingDetails , order) ; handleShowModal2(order) }}>Show Billing Info</button>
                    
//                   </div>
//                     </li>
                    
                
                  
//                   </>
//                 ))}
//             </ul>
//             {showModal && (
//                 <div className="modal-overlay">
//                     <div className="modal">
//                         <h2>Billing Information</h2>
//                         {selectedBilling ? (
//                             <div>
//                                 <p><strong>First Name:</strong> {selectedBilling.first_name || selectedBilling.firstName}</p>
//                                 <p><strong>Last Name:</strong> {selectedBilling.last_name || selectedBilling.lastName}</p>
//                                 <p><strong>Address 1:</strong> {selectedBilling.address_1 || selectedBilling.streetAddress}</p>
//                                 <p><strong>Address 2:</strong> {selectedBilling.address_2 || selectedBilling.streetAddress}</p>
//                                 <p><strong>City:</strong> {selectedBilling.city}</p>
//                                 <p><strong>State:</strong> {selectedBilling.state}</p>
//                                 <p><strong>Postcode:</strong> {selectedBilling.postcode}</p>
//                                 <p><strong>Country:</strong> {selectedBilling.country || selectedBilling.state}</p>
//                                 <p><strong>Phone:</strong> {selectedBilling.phone}</p>
//                                 <p><strong>Email:</strong> {selectedBilling.email}</p>
//                             </div>
//                         ) : (
//                             <p>No billing information available.</p>
//                         )}
//                         <button onClick={handleCloseModal}>Close</button>
//                     </div>
//                 </div>
//             )}

//         </div>
//     );
// };

// export default KitchenOrdersList;



import React, { useEffect, useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore';
import '../../Styles/Orders.css';

// Firebase Configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [loading, setLoading] = useState(true); // Loading state
  const [changeText, setchangeText] = useState(true); // Loading state
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [Dname, setDname] = useState('');
  const [Pname, setPname] = useState('');
  const [Dmethod, setDmethod] = useState('');
  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 }; // Default value if `createdAt` is not available
          return order;
        }
      });

      // Filter orders where status is 'processing' and sort by SequenceNumber (smallest to biggest)
      const processingOrders = ordersData.filter(order => order.status === 'for-delivery' || order.status === 'going-for-delivery');
      processingOrders.sort((a, b) => a.SequenceNumber - b.SequenceNumber); // Sorting by SequenceNumber
      setOrders(processingOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  // Fetch orders when the component mounts and set an interval for periodic fetching
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); // Refresh orders every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to update the status of an order to 'out-for-delivery'
  const addItemToConfirmedOrder = async (order) => {
    setIsPopupOpen(false)
    try {
      const AddData = {
        DeliveryBoy: Dname,
        ReciverName: Pname,
        PaymentMethodbyClient: Dmethod,
      };
  
      // Update the order status to 'completed' and add additional data in one go
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, {
        status: 'completed',
        ...AddData,  // Merge AddData fields into the update
      });
  
      // Update the state with the new status and additional data for the order
      const updatedOrders = orders.map(o =>
        o.id === order.id ? { ...o, status: 'completed', ...AddData } : o
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  
  
  const addItemToStartOrder = async (order) => {
    setchangeText(false)
    try {
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, { status: 'going-for-delivery' });
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status: 'completed' } : o
      );
      setOrders(updatedOrders);
      setchangeText(true)
    } catch (error) {
      setchangeText(true)
      console.error('Error updating order status:', error);
    }
  };
  return (
    <div className="orders-container">
      <h1 className="title">For Delivery Orders</h1>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <li key={order.id} className="order-item">
                 <strong>Sequence Number: </strong> {order.SequenceNumber} <br />
                <p className="order-details" style={{marginTop:'20px',marginBottom:'20px'}}>
                Order <span className="order-number">#{order.orderNumber}</span> - 
                {/* {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )} */}
               
              </p>
                <p className="order-details">
                  {/* Displaying all data for each order */}
                  <strong>From: </strong> {order.From} <br />
                
                  <strong>Billing Details:</strong> <br />
                  <ul>
                    <li><strong>First Name:</strong> {order.billingDetails?.firstName}</li>
                    <li><strong>Last Name:</strong> {order.billingDetails?.lastName}</li>
                    <li><strong>Email:</strong> {order.billingDetails?.email}</li>
                    <li><strong>Phone:</strong> {order.billingDetails?.phone}</li>
                    <li><strong>Payment Method:</strong> {order.billingDetails?.paymentMethod}</li>
                    <li><strong>Address:</strong> {order.billingDetails?.streetAddress}, {order.billingDetails?.city}, {order.billingDetails?.state} {order.billingDetails?.postcode}</li>
                  </ul>

                  <strong>Additional Note: </strong> {order.billingDetails?.additionalNote || 'No notes'} <br />
                  {/* <strong>Status: </strong> {order.status} <br /> */}
                  <strong>Created At: </strong> {new Date(order.createdAt.seconds * 1000).toLocaleString()} <br />
                  <strong>Total Amount: </strong> {order.totalAmount} {order.currency} <br />

                  <ul className="order-items" style={{marginTop:'20px'}}>
                    <strong>Items:</strong>
                    {order.items && order.items.length > 0 ? (
                      order.items.map((item, index) => (
                        <li key={index} className="order-item-detail" >
                          <img src={item.image} alt={item.title} className="item-image" style={{ maxWidth: '100px' }} />
                          <div style={{marginTop:'20px'}}>
                            <span className="item-name" style={{marginTop:'20px'}}>{item.title}</span> 
                            <ul>
    {/* Render miniData if it exists */}
    {item.miniData && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData}
        </li>
    )}

    {/* Render miniData1 if it exists */}
    {item.miniData1 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData1}
        </li>
    )}

    {/* Render miniData2 if it exists */}
    {item.miniData2 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData2}
        </li>
    )}

    {/* Render miniData3 if it exists */}
    {item.miniData3 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData3}
        </li>
    )}

    {/* Render miniData4 if it exists */}
    {item.miniData4 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData4}
        </li>
    )}

    {/* Render miniData5 if it exists */}
    {item.miniData5 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData5}
        </li>
    )}

    {/* Render miniData6 if it exists */}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData6}
        </li>
    )}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData7}
        </li>
    )}
</ul> - <br/>
                            <span className="item-quantity">Qty: {item.quantity}</span><br />
                            <strong>Price:</strong> {item.price} <br />
                            {item.crust && <><strong>Crust:</strong> {item.crust} <br /></>}
                            {item.size && <><strong>Size:</strong> {item.size} <br /></>}
                            {item.toppings?.length > 0 && <><strong>Toppings:</strong> {item.toppings.join(', ')} <br /></>}
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No items</p>
                    )}
                  </ul>

                  {/* Display a button to mark the order as ready */}
                  {order.status === 'going-for-delivery' && (
                    <button
                      // onClick={() => addItemToConfirmedOrder(order)}
                      onClick={()=>setIsPopupOpen(true)}
                      className="status-button completed"
                      style={{ marginTop: '30px',backgroundColor:'#c84347' }}
                    >
                      Delivered
                    </button>
                  )}
                  {order.status === 'for-delivery' && (
                    <button
                      onClick={() => addItemToStartOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px' }}
                    >
                     
                      {
                        changeText?
                        'To Deliver' : 'wait...'
                      }
                    </button>
                  )}
 {isPopupOpen && (
     <div className="popup-overlay">
         <div className="popup-content2" style={{backgroundColor:'#af3127'}}>
         <div>
          <div  style={{marginTop:'10px'}}>
          <label>Delivery boy name:</label>
          <input type='text' placeholder='your name' value={Dname} onChange={(e)=>setDname(e.target.value)} style={{height:'30px',width:'100%'}}/>
          </div>
          <div  style={{marginTop:'10px'}}>
          <label>Order Reciver name:</label>
          <input type='text' placeholder='Reciver name' value={Pname} onChange={(e)=>setPname(e.target.value)} style={{height:'30px',width:'100%'}}/>
            </div>
            <div  style={{marginTop:'10px'}}>
            <label>Payment method:</label>
            <input type='text' placeholder='Payment method' value={Dmethod} onChange={(e)=>setDmethod(e.target.value)} style={{height:'30px',width:'100%'}}/>
            </div>
          </div>
         <div style={{ display: 'flex', gap: '10px',marginTop:'20px' }}>
                 <button style={{width:'50%',height:'30px',backgroundColor:'green',color:'white',}}   onClick={() => addItemToConfirmedOrder(order)}>Submit</button>
                 <button onClick={() => setIsPopupOpen(false)} style={{width:'50%',height:'30px',backgroundColor:'red',color:'white'}}>Cancel</button>
             </div>
         </div>
     </div>
 )}
                </p>
              </li>
            ))
          ) : (
            <p>No orders found</p>
          )}
        </ul>
      )}
     
    </div>
  );
};

export default Orders;
