import React, { useState, useEffect } from 'react';
import PaymentInterface from './PaymentInterface';
import PaymentSummary from './PaymentSummary';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const Counting = () => {
    return <PaymentInterface />;
};

const FinalStep = () => {
    return <PaymentSummary />;
};

const CashFlowInterface = ({ setDayEndPopup }) => {
    const [step, setStep] = useState(0);
    const [posData, setPosData] = useState([]); 

    useEffect(() => {
        const fetchPosData = async () => {
            const posCollectionRef = collection(firestore3, 'POS');
            const posSnapshot = await getDocs(posCollectionRef);
            const posList = posSnapshot.docs.map(doc => doc.data());
            console.log('Fetched POS Data:', posList); 
            setPosData(posList);
        };

        fetchPosData();
    }, []);

    const TableHeader = () => (
        <tr className="cashflow-table-header">
            <th className="cashflow-table-header-cell">Amount</th>
            <th className="cashflow-table-header-cell">Branch</th>
            <th className="cashflow-table-header-cell">Counter</th>
            <th className="cashflow-table-header-cell">Currency</th>
            <th className="cashflow-table-header-cell">Date</th>
            <th className="cashflow-table-header-cell">Email</th>
            <th className="cashflow-table-header-cell">Reason</th>
            <th className="cashflow-table-header-cell">Updated Date</th>
        </tr>
    );

    const handleNextStep = () => {
        setStep((prevStep) => {
            if (prevStep === 0) return 1;
            if (prevStep === 1) return 2;
            return prevStep;
        });
    };

    const handleOK = () => {
        console.log('Process complete!');
        setStep(0);
    };

    return (
        <div className="cashflow-container">
            {step === 0 && (
                <>
                    <div className="cashflow-section">
                        <h2 className="cashflow-section-title">Total Amount</h2>
                        <div className="cashflow-table-container">
                            <table className="cashflow-table">
                                <thead>
                                    <TableHeader />
                                </thead>
                                <tbody>
                                    <tr className="cashflow-table-row">
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.amount, 0)}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.branch, 0)}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.counter, 0)}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.currency, 0)}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.date, 0)}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.email, '')}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.reason, '')}</td>
                                        <td className="cashflow-table-cell">{posData.reduce((acc, row) => acc + row.updatedDate, 0)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="cashflow-section">
                        <h2 className="cashflow-section-title">Cash-In Amount</h2>
                        <div className="cashflow-table-container">
                            <table className="cashflow-table">
                                <thead>
                                    <TableHeader />
                                </thead>
                                <tbody>
                                    {posData.filter((row) => row.type === 'cash-in').map((row, index) => (
                                        <tr key={index} className="cashflow-table-row">
                                            <td className="cashflow-table-cell">{row.cashInAmount}</td>
                                            <td className="cashflow-table-cell">{row.branch}</td>
                                            <td className="cashflow-table-cell">{row.counter}</td>
                                            <td className="cashflow-table-cell">{row.currency}</td>
                                            <td className="cashflow-table-cell">{row.date}</td>
                                            <td className="cashflow-table-cell">{row.email}</td>
                                            <td className="cashflow-table-cell">{row.reason}</td>
                                            <td className="cashflow-table-cell">{row.updatedDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="cashflow-section">
                        <h2 className="cashflow-section-title">Cash-Out Amount</h2>
                        <div className="cashflow-table-container">
                            <table className="cashflow-table">
                                <thead>
                                    <TableHeader />
                                </thead>
                                <tbody>
                                    {posData.filter((row) => row.type === 'cash-in').map((row, index) => (
                                        <tr key={index} className="cashflow-table-row">
                                            <td className="cashflow-table-cell">{row.cashOutAmount}</td>
                                            <td className="cashflow-table-cell">{row.branch}</td>
                                            <td className="cashflow-table-cell">{row.counter}</td>
                                            <td className="cashflow-table-cell">{row.currency}</td>
                                            <td className="cashflow-table-cell">{row.date}</td>
                                            <td className="cashflow-table-cell">{row.email}</td>
                                            <td className="cashflow-table-cell">{row.reason}</td>
                                            <td className="cashflow-table-cell">{row.updatedDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}

            {step === 1 && <Counting />}

            {step === 2 && <FinalStep />}

            <div className="cashflow-button-container">
                {step !== 2 ? (
                    <button className="cashflow-next-button" onClick={handleNextStep}>
                        Next Step
                    </button>
                ) : (
                    <button className="cashflow-next-button" onClick={handleOK}>
                        OK
                    </button>
                )}
                <button className="cashflow-cancel-button" onClick={() => setDayEndPopup(false)}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default CashFlowInterface;
