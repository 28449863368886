import "../Pages-5/OrgUnits.css";

const OrgUnits = () => {
  const dummyData = [
    { orgUnit: "Marketing", isDefault: true },
    { orgUnit: "Sales", isDefault: false },
    { orgUnit: "HR", isDefault: false },
    { orgUnit: "IT", isDefault: true },
    { orgUnit: "Finance", isDefault: false },
  ];

  return (
    <div className="table-container">
      <table className="org-unit-table">
        <thead>
          <tr>
            <th>Org Unit</th>
            <th>Default</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((row, index) => (
            <tr key={index}>
              <td>{row.orgUnit}</td>
              <td>{row.isDefault ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrgUnits;
