import React, { useState, useEffect } from 'react';
import ToolbarTerminal from '../Terminal/ToolbarTerminal';
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, deleteDoc  , doc} from 'firebase/firestore';
import { PiPicnicTable } from "react-icons/pi";
import './Tables.css';
import toast, { Toaster } from 'react-hot-toast';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const Tables = () => {
    const navigate = useNavigate();
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [activeTab, setActiveTab] = useState('ownedTables');
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddTableModal, setShowAddTableModal] = useState(false);
    const [newTableId, setNewTableId] = useState('');
    const [isLoading, setIsLoading] = useState(false); 

    const email = localStorage.getItem('email') || "user@example.com";

    useEffect(() => {
        const fetchTables = async () => {
            try {
                const tablesCollection = collection(firestore3, 'Tables');
                const querySnapshot = await getDocs(tablesCollection);
                const fetchedTables = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    category: doc.data().category || 'owned',
                }));
                setTables(fetchedTables);
            } catch (error) {
                console.error("Error fetching tables: ", error);
            }
        };

        fetchTables();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setSearchQuery('');
    };

    const filteredTables = tables.filter((table) => {
        const matchesSearch = table.TableNo.toString().includes(searchQuery);

        if (activeTab === 'ownedTables') {
            return table.AddedBy === email && matchesSearch;
        }

        if (activeTab === 'availableTables') {
            return matchesSearch;
        }

        return false;
    });

    const handleTableClick = (table) => {
        setSelectedTable(table);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedTable(null);
    };

    const handleAddItem = (selectedTable) => {
        console.log(selectedTable.id);
        navigate(`/Terminal/${selectedTable.id}`); 
    };


    const handleAddNewTable = async () => {
        if (newTableId) {
            setIsLoading(true);  
            try {
                const currentTime = new Date().toISOString();

                const newTable = {
                    AddedBy: email,
                    AddedTime: currentTime,
                    Items: [],
                    TableNo: parseInt(newTableId),
                    UpdatedTime: "",
                };

                const docRef = await addDoc(collection(firestore3, 'Tables'), newTable);

                setTables((prevTables) => [
                    ...prevTables,
                    {
                        id: docRef.id,
                        TableNo: parseInt(newTableId),
                        AddedBy: email,
                        AddedTime: currentTime,
                        Items: [],
                        UpdatedTime: "",
                    }
                ]);

                setNewTableId('');
                setShowAddTableModal(false);
                toast.success('Table Added successfully!');

            } catch (error) {
                console.error("Error adding new table: ", error);
            } finally {
                setIsLoading(false);  
            }
        }
    };
    const handleAddFastTable = async () => {
            setIsLoading(true);
            try {
                const currentTime = new Date().toISOString();
                const TableNumber = localStorage.getItem('fastTableName') || '99'

                const newTable = {
                    AddedBy: email,
                    AddedTime: currentTime,
                    Items: [],
                    TableNo: parseInt(TableNumber),
                    UpdatedTime: "",
                };

                const docRef = await addDoc(collection(firestore3, 'Tables'), newTable);

                setTables((prevTables) => [
                    ...prevTables,
                    {
                        id: docRef.id,
                        TableNo: parseInt(TableNumber),
                        AddedBy: email,
                        AddedTime: currentTime,
                        Items: [],
                        UpdatedTime: "",
                    }
                ]);

                setNewTableId('');
                setShowAddTableModal(false);

            } catch (error) {
                console.error("Error adding new table: ", error);
            } finally {
                setIsLoading(false);
            
        }
    };
    const handleCloseTable = async (selectedTable) => {
        if (!selectedTable.id) return;

        try {
            await deleteDoc(doc(firestore3, 'Tables', selectedTable.id));

            setTables((prevTables) => prevTables.filter((t) => t.id !== selectedTable.id));
            setShowPopup(false);
            setSelectedTable(null);
            toast.success('Table closed successfully!');
        } catch (error) {
            console.error("Error closing table: ", error);
        }
    };

    return (
        <>
            <Toaster /> 

            <ToolbarTerminal />
            <div className="tabs-container">
                <button
                    className={`tab ${activeTab === 'ownedTables' ? 'active' : ''}`}
                    onClick={() => handleTabClick('ownedTables')}
                >
                    Owned Tables
                </button>
                <button
                    className={`tab ${activeTab === 'availableTables' ? 'active' : ''}`}
                    onClick={() => handleTabClick('availableTables')}
                >
                    Available Tables
                </button>
            </div>
            <div className="search-container-tables">
                <input
                    type="text"
                    placeholder="Search by Table ID..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="tables-container">
                {filteredTables.map((table) => (
                    <div
                        key={table.id}
                        className={`table-box table-${table.TableNo}`}
                        onClick={() => handleTableClick(table)}
                    >
                        {table.TableNo}
                    </div>
                ))}
                <button
                    className="table-box add-new"
                    onClick={() => setShowAddTableModal(true)}
                >
                    <IoMdAdd size={40} />
                </button>
                <button
                    className="table-box add-new"
                    onClick={handleAddFastTable}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <PiPicnicTable size={40} />
                    <span>Fast Table</span>
                </button>
            </div>

            {showAddTableModal && (
                <div className="popup-tables">
                    <div className="popup-content-tables">
                        <h3>Add New Table</h3>
                        <div className='addnew-table' style={{ display: 'flex', flexDirection: 'column' }}>
                            <input
                                type="text"
                                placeholder="Enter Table Number"
                                value={newTableId}
                                onChange={(e) => setNewTableId(e.target.value)}
                            />
                        </div>
                        <div>
                            <button onClick={handleAddNewTable}>
                                {isLoading ? 'Adding...' : 'Add Table'} {/* Show loader or button text */}
                            </button>
                            <button onClick={() => setShowAddTableModal(false)}>Cancel</button>
                        </div>

                    </div>
                </div>
            )}

            {showPopup && selectedTable && (
                <div className="popup-tables">
                    <div className="popup-content-tables">
                        <h3>{`Table ${selectedTable.TableNo}`}</h3>
                        <ul>
                            {selectedTable.Items.length === 0 ? (
                                <li>No items added yet.</li>
                            ) : (
                                selectedTable.Items.map((item, index) => (
                                    <li key={index}>{item.title}</li>
                                ))
                            )}
                        </ul>
                        <button onClick={() => handleAddItem(selectedTable)}>Add More Items</button>
                        <button onClick={handleClosePopup}>Close</button>
                        <button onClick={() => handleCloseTable(selectedTable)}>Close Table</button>

                    </div>
                </div>
            )}

        </>
    );
};

export default Tables;
