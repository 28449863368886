
import React, { useEffect, useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import '../../Styles/Orders.css';

// Firebase Configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [loading, setLoading] = useState(true); // Loading state
  const [changeText, setchangeText] = useState(true); // Loading state
  // Function to fetch orders from Firestore
  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 }; // Default value if `createdAt` is not available
          return order;
        }
      });

      // Filter orders where status is 'processing' and sort by SequenceNumber (smallest to biggest)
      const processingOrders = ordersData.filter(order => order.From === 'Dinning');
      processingOrders.sort((a, b) => a.SequenceNumber - b.SequenceNumber); // Sorting by SequenceNumber
      setOrders(processingOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  // Fetch orders when the component mounts and set an interval for periodic fetching
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); // Refresh orders every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to update the status of an order to 'out-for-delivery'
  const addItemToConfirmedOrder = async (order) => {
    try {
      // Determine the status based on the order.From value
      let status;
      if (order.From === 'Dinning') {
        status = 'completed-dinning';
      } else {
        status = 'for-delivery';
      }
  
      // Reference to the order document in Firestore
      const orderRef = doc(firestore, 'Orders', order.id);
  
      // Update the status in Firestore
      await updateDoc(orderRef, { status });
  
      // Update the local state with the new status
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status } : o
      );
      setOrders(updatedOrders);
  
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  const addItemToStartOrder = async (order) => {
    setchangeText(false)
    try {
      // Determine the status based on the order.From value
      let status;
      if (order.From === 'Dinning') {
        status = 'completed-dinning';
      } else {
        status = 'started';
      }
  
      // Reference to the order document in Firestore
      const orderRef = doc(firestore, 'Orders', order.id);
  
      // Update the status in Firestore
      await updateDoc(orderRef, { status });
  
      // Update the local state with the new status
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status } : o
      );
      setOrders(updatedOrders);
      setchangeText(true)
    } catch (error) {
      setchangeText(true)

      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className="orders-container">
      <h1 className="title">Table Management</h1>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <li key={order.id} className="order-item">
                 <strong>Sequence Number: </strong> {order.SequenceNumber} <br />
                <p className="order-details" style={{marginTop:'20px',marginBottom:'20px'}}>
                Order  <span className="order-number">#{order.orderNumber}</span> - 
                {/* {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )} */}
               
              </p>
                <p className="order-details">
                  {/* Displaying all data for each order */}
                  {/* <strong>From: </strong> {order.From} <br />
                
                  <strong>Billing Details:</strong> <br />
                  <ul>
                    <li><strong>First Name:</strong> {order.billingDetails?.firstName}</li>
                    <li><strong>Last Name:</strong> {order.billingDetails?.lastName}</li>
                    <li><strong>Email:</strong> {order.billingDetails?.email}</li>
                    <li><strong>Phone:</strong> {order.billingDetails?.phone}</li>
                    <li><strong>Address:</strong> {order.billingDetails?.streetAddress}, {order.billingDetails?.city}, {order.billingDetails?.state} {order.billingDetails?.postcode}</li>
                  </ul> */}

                  <strong>Additional Note: </strong> {order.billingDetails?.additionalNote || 'No notes'} <br />
                  {/* <strong>Status: </strong> {order.status} <br /> */}
                  {/* <strong>Created At: </strong> {new Date(order.createdAt.seconds * 1000).toLocaleString()} <br /> */}
                  {/* <strong>Total Amount: </strong> {order.totalAmount} {order.currency} <br /> */}

                  <ul className="order-items" style={{marginTop:'20px'}}>
                    <strong>Items:</strong>
                    {order.items && order.items.length > 0 ? (
                      order.items.map((item, index) => (
                        <li key={index} className="order-item-detail" >
                          <img src={item.image} alt={item.title} className="item-image" style={{ maxWidth: '100px' }} />
                          <div style={{marginTop:'10px'}}>
                            <span className="item-name" style={{fontWeight:'bold',fontSize:'18px',color:'#c84347'}}>{item.title}</span>
                            
                            <ul>
    {/* Render miniData if it exists */}
    {item.miniData && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData}
        </li>
    )}

    {/* Render miniData1 if it exists */}
    {item.miniData1 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData1}
        </li>
    )}

    {/* Render miniData2 if it exists */}
    {item.miniData2 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData2}
        </li>
    )}

    {/* Render miniData3 if it exists */}
    {item.miniData3 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData3}
        </li>
    )}

    {/* Render miniData4 if it exists */}
    {item.miniData4 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData4}
        </li>
    )}

    {/* Render miniData5 if it exists */}
    {item.miniData5 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData5}
        </li>
    )}

    {/* Render miniData6 if it exists */}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData6}
        </li>
    )}
    {item.miniData6 && (
        <li className="d" style={{ textAlign: 'start' }}>
            {item.miniData7}
        </li>
    )}
</ul> - <br/>
                            <span className="item-quantity">Qty: {item.quantity}</span><br />
                            {/* <strong style={{marginTop:'20px',}}>Price:</strong> {item.price} <br /> */}
                            {item.crust && <><strong>Crust:</strong> {item.crust} <br /></>}
                            {item.crust && <><strong>Sauce:</strong> {item.sauce} <br /></>}

                            {item.size && <><strong>Size:</strong> {item.size} <br /></>}
                            {item.toppings?.length > 0 && <><strong>Toppings:</strong> {item.toppings.join(', ')} <br /></>}
                            {item.sauces?.length > 0 && <><strong>Sauces:</strong> {item.sauces.join(', ')} <br /></>}

                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No items</p>
                    )}
                  </ul>

                  {/* Display a button to mark the order as ready */}
                  {order.status === 'started' && (
                    <button
                      onClick={() => addItemToConfirmedOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px',backgroundColor:'#c84347' }}
                    >
                      Order is ready
                    </button>
                  )}
                  {order.status === 'processing' && (
                    <button
                      onClick={() => addItemToStartOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px' }}
                    >
                     
                      {
                        changeText?
                        'Start Making' : 'Starting'
                      }
                    </button>
                  )}
                </p>
              </li>
            ))
          ) : (
            <p>No orders in processing status</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default Orders;
