import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import NotAllowed from './Components/Options/NotAllowed';
import Amount from './Components/POS/Amount/Amount';
import MainTerminal from './Components/POS/Terminal/MainTerminal';
import Tables from './Components/POS/Tables/Tables';
import POSInterface from './Components/POS/Terminal/UserSide';
import Confriguration from './Components/POS/Terminal/Configuration/Configuration';
import { CheckoutProvider } from './Components/Context/Data';
import { Toaster } from 'react-hot-toast';
import Receipts from './Components/POS/ParkReceipt/ParkReceipt';
function App() {


    return (
        <CheckoutProvider>
            <Toaster />

    <Router>
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path="/not-allowed" Component={NotAllowed} />
        <Route path="/Cashing" Component={Amount} />
        <Route path="/Terminal" Component={MainTerminal} />
        <Route path="/Terminal/:tableIdl" Component={MainTerminal} />
        <Route path="/Tables" Component={Tables} />
        <Route path="/POSInterface" Component={POSInterface} />
                    <Route path="/Configuration" Component={Confriguration} />
                    <Route path="/Park-Receipt" Component={Receipts} />

      </Routes>
            </Router>
        </CheckoutProvider>
  );
}

export default App;
