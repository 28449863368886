import { useEffect, useState } from "react";
import "../Pages-6/DrawersDetails.css";

const DrawersDetails = ({ tableId,tableStatus,tableDes }) => {
  const [data, setData] = useState(null);
  const [administrativeData, setAdministrativeData] = useState(null);

  useEffect(() => {
    // Simulate fetching data for General Data
    const fetchData = () => {
      setData({
        id: tableId,
        description: tableDes, 
        defaultUser: " ", 
        status: tableStatus, 
      });
    };

    // Simulate fetching data for Administrative Data
    const fetchAdministrativeData = () => {
      setAdministrativeData({
        createdAt: "2025-01-20 10:00 AM",
        createdBy: "System Admin",
        modifiedAt: "2025-01-25 02:30 PM",
        modifiedBy: "Editor User",
      });
    };

    fetchData();
    fetchAdministrativeData();
  }, [tableId]);

  if (!data || !administrativeData) return <p>Loading...</p>;

  return (
    <div className="drawers-details-page">
      {/* General Data Section */}
      <section className="general-data-section">
        <h2>General Data</h2>
        <div className="general-data-fields">
          <p>
            <strong>ID:</strong> {data.id}
          </p>
          <p>
            <strong>Description:</strong> {data.description}
          </p>
          <p>
            <strong>Default User:</strong> {data.defaultUser}
          </p>
          <p>
            <strong>Status:</strong>{" "}
            {data.status === "active" ? "20-Available" : "10-Draft"}
          </p>
        </div>
      </section>

      {/* Administrative Data Section */}
      <section className="administrative-data-section">
        <h2>Administrative Data</h2>
        <div className="administrative-data-fields">
          <p>
            <strong>Created At:</strong> {administrativeData.createdAt}
          </p>
          <p>
            <strong>Created By:</strong> {administrativeData.createdBy}
          </p>
          <p>
            <strong>Modified At:</strong> {administrativeData.modifiedAt}
          </p>
          <p>
            <strong>Modified By:</strong> {administrativeData.modifiedBy}
          </p>
        </div>
      </section>
    </div>
  );
};

export default DrawersDetails;
