import React, { useEffect, useState } from 'react';
import './POS.css';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp, getApps } from "firebase/app";

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9xv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX",
};

const checkoutApp =
    getApps().find((app) => app.name === "checkoutApp") ||
    initializeApp(checkoutFirebaseConfig, "checkoutApp");
const firestore = getFirestore(checkoutApp);

const TimeRecordingTable = () => {
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchRecords = async () => {
            const querySnapshot = await getDocs(collection(firestore, "TimeRecording"));
            const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRecords(data);
        };
        fetchRecords();
    }, []);

    const filteredRecords = records.filter(record =>
        record.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="bestEverContainer">
            <div className="time-recording-container">
                <h2 className="salesReportTitle">Time Recording</h2>
                <input
                    type="text"
                    placeholder="Search by email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar-timerecord"
                />
                <table className="time-recording-table">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Punch In</th>
                            <th>Punch Out</th>
                            <th>Hours</th>
                            <th>Punch In POS</th>
                            <th>Punch Out POS</th>
                            <th>User Name</th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredRecords.map((record, index) => (
                            <tr key={record.id}>
                                <td>{index + 1}</td>
                                <td>{new Date(record.startTime?.seconds * 1000).toLocaleString()}</td>
                                <td>{new Date(record.endTime?.seconds * 1000).toLocaleString()}</td>
                                <td>{((record.endTime?.seconds - record.startTime?.seconds) / 3600).toFixed(2)}</td>
                                <td>{record.counter}</td>
                                <td>{record.orgUnit}</td>
                                <td>{record.email}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TimeRecordingTable;
