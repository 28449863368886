import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotAllowed = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Access Denied</h2>
      <p>You are not authorized to view this page.</p>
      <button onClick={() => navigate('/')}>Go to Login</button>
    </div>
  );
};

export default NotAllowed;
