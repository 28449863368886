import React from "react";
import '../Pages-7/CurrenciesRoundings.css';
const CurrenciesRoundings = () => {
  // Sample data for the table
  const roundingsData = [
    {
      country: "USA",
      description: "US Dollar Rounding",
      decimalPlaces: 2,
      roundingTo: "0.01",
      roundingLevel: "Standard",
    },
    {
      country: "Germany",
      description: "Euro Rounding",
      decimalPlaces: 2,
      roundingTo: "0.05",
      roundingLevel: "Banking",
    },
  ];

  return (
    <div className="currencies-roundings-container">
      <h2>Roundings</h2>
      <table className="currencies-roundings-table">
        <thead>
          <tr>
            <th>Country/Region</th>
            <th>Description</th>
            <th>Decimal Places</th>
            <th>Rounding To</th>
            <th>Rounding Level</th>
          </tr>
        </thead>
        <tbody>
          {roundingsData.map((row, index) => (
            <tr key={index}>
              <td>{row.country}</td>
              <td>{row.description}</td>
              <td>{row.decimalPlaces}</td>
              <td>{row.roundingTo}</td>
              <td>{row.roundingLevel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CurrenciesRoundings;
