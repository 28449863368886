import React, { useEffect, useState } from 'react';
import ToolbarTerminal from './ToolbarTerminal';

const POSInterface = () => {
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        const updateProducts = () => {
            const storedItems = JSON.parse(localStorage.getItem('cartItems'));
            if (storedItems) {
                const formattedItems = storedItems.map(item => ({
                    name: item.id,
                    price: item.price,
                    qty: item.quantity,
                    discount: item.discount || 0,
                    subtotal: item.price * item.quantity,
                    stock: item.stock || 'N/A'
                }));

                setProducts(formattedItems);
            }

            // Fetch Total, GST, and Discount from localStorage
            const totalAmount = parseFloat(localStorage.getItem('Total')) || 0;
            const gstAmount = parseFloat(localStorage.getItem('GST')) || 0;
            const totalDiscount = parseFloat(localStorage.getItem('Discount')) || 0;

            setTotal(totalAmount);
            setGst(gstAmount);
            setDiscount(totalDiscount);
        };

        updateProducts();

        const interval = setInterval(updateProducts, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <ToolbarTerminal />
            <div className="pos-container" style={{ flexDirection: 'column' }}>
                <div >
                    <div className="pos-card">
                        <div className="pos-table-container">
                            <table className="pos-table" >
                                <thead>
                                    <tr className="pos-table-header" style={{ height: '50px', border: '1px solid red' }}>
                                        <th style={{ height: '50px', border: '1px solid red' }}>Qty.</th>

                                        <th style={{ height: '50px', border: '1px solid red' }}>Item</th>
                                        <th style={{ height: '50px', border: '1px solid red' }}>Toppings.</th>

                                        <th style={{ height: '50px', border: '1px solid red' }}>Sauce.</th>
                                        <th style={{ height: '50px', border: '1px solid red' }}>Flavor.</th>
                                        <th style={{ height: '50px', border: '1px solid red' }}>Price</th>

                                        <th style={{ height: '50px', border: '1px solid red' }}>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product, index) => (
                                        <tr key={index} className="pos-table-row">
                                            <td>{product.qty}</td>
                                            <td>{product.name}</td>
                                            <td>{product.toppings?.join(', ') || 'none'}</td>
                                            <td>{product.sauce || 'none'}</td>
                                            <td>{product.flavor || 'none'}</td>
                                            <td>{product.price.toFixed(2)}</td>

                                            <td>{product.subtotal.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column',marginTop:'30px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <span style={{ fontWeight: 'bold' }}>GST:</span>
                                <span>{gst.toFixed(2)}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <span style={{ fontWeight: 'bold' }}>Discount:</span>
                                <span>{discount.toFixed(2)}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <span style={{ fontWeight: 'bold' }}>Total:</span>
                                <span>{total.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </>
    );
};

export default POSInterface;