import React, { useState, useEffect } from 'react';
import './Configuration.css';
import ToolbarTerminal from '../ToolbarTerminal';
import SAPSettingsForm from './TableService';
import Card from './Card';
import UniqueTaxTable from './Tax';
import UniqueCurrencyTable from './Currencey';

const SAPCustomerCheckout = () => {
    const [activeTab, setActiveTab] = useState('POS system');
    const [activeSubTab, setActiveSubTab] = useState('Table Service');

    const tabs = [
        'POS system', 'Accounting', 'Currency',
    ];

    const POSSystemSubNav = [
        'Table Service',
    ];
    const AccountingSubNav = [
        'Credit card type', 'Tax rates',
    ];
    const CurrencySubNav = [
        'Currency',
    ];

    const getSubNav = () => {
        switch (activeTab) {
            case 'POS system':
                return POSSystemSubNav;
            case 'Accounting':
                return AccountingSubNav;
            case 'Currency':
                return CurrencySubNav;
            default:
                return [];
        }
    };

    useEffect(() => {
        const subTabs = getSubNav();
        if (subTabs.length > 0) {
            setActiveSubTab(subTabs[0]);
        }
    }, [activeTab]);

    const getContent = () => {
        switch (activeTab) {
            case 'POS system':
                switch (activeSubTab) {
                    case 'Table Service':
                        return <div className="pos-system-content"><SAPSettingsForm/></div>;
                    default:
                        return <div className="pos-system-content">Content for POS system</div>;
                }
            case 'Accounting':
                switch (activeSubTab) {
                    case 'Credit card type':
                        return <div className="accounting-content"><Card/></div>;
                    case 'Tax rates':
                        return <div className="accounting-content"><UniqueTaxTable/></div>;
                    default:
                        return <div className="accounting-content">Content for Accounting</div>;
                }
            case 'Currency':
                switch (activeSubTab) {
                    case 'Currency':
                        return <div className="currency-content"><UniqueCurrencyTable/></div>;
                    default:
                        return <div className="currency-content">Content for Currency</div>;
                }
            default:
                return <div className="default-content">Select a tab to view content</div>;
        }
    };

    return (
        <>
            <ToolbarTerminal/>
        <div className="checkout-container">
            <div className="checkout-top-nav">
                {tabs.map((item) => (
                    <button
                        key={item}
                        className={`top-nav-item ${activeTab === item ? 'active-main-tab' : ''}`}
                        onClick={() => {
                            setActiveTab(item);
                            setActiveSubTab('');
                        }}
                    >
                        {item}
                    </button>
                ))}
            </div>

            {activeTab && (
                <div className="sub-navigation">
                    {getSubNav().map((tab) => (
                        <button
                            key={tab}
                            onClick={() => setActiveSubTab(tab)}
                            className={`sub-nav-item ${activeSubTab === tab ? 'active-sub-tab' : ''}`}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            )}

            <div className="content-area">

                <div className="form-content-wrapper">
                    <div className="tab-content">
                        {getContent()}
                    </div>
                </div>
            </div>

            {/*<div className="checkout-bottom-bar">*/}
            {/*    <button className="bottom-bar-action-btn">*/}
            {/*        <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">*/}
            {/*            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />*/}
            {/*        </svg>*/}
            {/*    </button>*/}
            {/*    <button className="bottom-bar-action-btn edit-btn">*/}
            {/*        Edit*/}
            {/*    </button>*/}
            {/*</div>*/}
            </div>
        </>

    );
};

export default SAPCustomerCheckout;
