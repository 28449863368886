import React, { useState, useEffect } from 'react';
import { TbReceiptOff } from "react-icons/tb";
import { RiCoupon3Line } from "react-icons/ri";
import { BiDish } from "react-icons/bi";
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './Terminal.css';
import { useCheckoutContext } from '../../Context/Data';


const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const Actions = ({ selectedItem, updateItemQuantity, CancelItem, CancelItemAll, Coupons, setCoupons }) => {
    const [quantity, setQuantity] = useState(selectedItem?.quantity || 1);
    const [selectedToppings, setSelectedToppings] = useState([]);
    const [toppingPrice, setToppingPrice] = useState(0);
    const [showCouponPopup, setShowCouponPopup] = useState(false); 
    const [couponCode, setCouponCode] = useState(""); 
    const { Items, setItems } = useCheckoutContext();












    useEffect(() => {
        if (selectedItem) {
            setQuantity(selectedItem.quantity || 1);
            setSelectedToppings([]);
        }
    }, [selectedItem]);

 
    const handleToppingSelect = (topping) => {
        if (topping && !selectedToppings.includes(topping)) {
            setSelectedToppings((prev) => [...prev, topping]);
        }
    };


    const handleRemoveTopping = (topping) => {
        setSelectedToppings((prev) => prev.filter((t) => t !== topping));
    };
;



    const calculateToppingPrice = () => {
        let pricePerTopping;
        switch (selectedItem?.size) {
            case 'small(7)':
                pricePerTopping = 100;
                break;
            case 'medium(10)':
                pricePerTopping = 125;
                break;
            case 'large(13)':
                pricePerTopping = 150;
                break;
            case 'x-large(16)':
                pricePerTopping = 200;
                break;
            default:
                pricePerTopping = 0;
        }
        setToppingPrice(pricePerTopping * selectedToppings.length);
    };


    const handleQuantityChange = (e) => {
        const value = e.target.value;
        setQuantity(parseInt(value, 10) || 1);
    };


    const handleSubmit = () => {
        if (selectedItem && quantity > 0) {
            updateItemQuantity(selectedItem.uniqueId, quantity, selectedToppings, toppingPrice);
        }
    };


    const handleNumberClick = (key) => {
        setQuantity((prev) => {
            const newQuantity = prev.toString() + key;
            return parseInt(newQuantity, 10) || 1;
        });
    };


    const handleBackspace = () => {
        setQuantity((prev) => {
            const newQuantity = prev.toString().slice(0, -1);
            return parseInt(newQuantity, 10) || 1;
        });
    };


 
    const handleCancelAll = () => {
        CancelItemAll(selectedItem);
        setQuantity(1);
        setSelectedToppings([]);
    };

    useEffect(() => {
        calculateToppingPrice();
    }, [selectedToppings, selectedItem?.size]);

    const toggleCouponPopup = () => {
        setShowCouponPopup(!showCouponPopup);
    };

    const handleCouponCodeChange = (e) => {
        setCouponCode(e.target.value);
    };

    const handleApplyCoupon = async () => {
        if (!couponCode) {
            alert("Please enter a coupon code.");
            return;
        }

        try {
            const promosCollection = collection(firestore3, 'Promos'); 
            const promoQuerySnapshot = await getDocs(promosCollection);
            let couponFound = false;
            let discountPercent = 0;

            promoQuerySnapshot.forEach((doc) => {
                const promo = doc.data();
                if (promo.code === couponCode) {
                    const currentDate = new Date();
                    const startDate = new Date(promo.startDateTime);
                    const endDate = new Date(promo.endDateTime);

                    if (currentDate >= startDate && currentDate <= endDate) {
                        couponFound = true;
                        discountPercent = promo.percent; 
                    }
                }
            });

            if (couponFound) {
                alert(`Coupon applied successfully! You get a ${discountPercent}% discount.`);
                setShowCouponPopup(false);
                setCoupons(discountPercent); 

            } else {
                alert("Invalid or expired coupon code.");
            }
        } catch (error) {
            console.error("Error applying coupon:", error);
            alert("An error occurred while applying the coupon.");
        }
    };
    const CancelItemInContext = (selectedItem) => {
        if (!selectedItem) return; 

        const uniqueItem = `${selectedItem.id}-${selectedItem.size}-${selectedItem.crust || "default"}`;

        setItems((prevItems) => prevItems.filter((i) => i.uniqueItem !== uniqueItem));
    };




    return (
        <>
            <div className="main-actions-box">
                <div className="main-Actions">
                    {
                        Items.length > 0 && selectedItem && (
                           
                                <>

                                    <div className="item-details">
                                        <div>{selectedItem.title}</div>
                                        <input

                                            type="number"
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                            min="1"
                                            className="quantity-input"

                                        />
                                    </div>
                                {selectedItem.category === 'Pizza' && (
                                    <div className="toppings-section">
                                        <h4>Select Toppings:</h4>

                                        {/* Dropdown to select new toppings */}
                                        <select
                                            onChange={(e) => handleToppingSelect(e.target.value)}
                                            className="topping-select"
                                        >
                                            <option value="" disabled>Select a topping</option>
                                            {['Cheese', 'Olives', 'Pepperoni', 'Mushrooms'].map((topping) => (
                                                <option
                                                    key={topping}
                                                    value={topping}
                                                    disabled={selectedToppings.includes(topping)} // Disable already selected toppings
                                                >
                                                    {topping}
                                                </option>
                                            ))}
                                        </select>

                                        {/* Display already selected toppings */}
                                        <div className="selected-toppings">
                                            <h5>Selected Toppings:</h5>
                                            <ul>
                                                {selectedToppings.length > 0 ? (
                                                    selectedToppings.map((topping, index) => (
                                                        <li key={index}>
                                                            {topping}
                                                            <button onClick={() => handleRemoveTopping(topping)} >❌</button>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No toppings selected</li>
                                                )}
                                            </ul>

                                        </div>

                                        {/* Display total price of toppings */}
                                        <div className="topping-price">
                                            <p>Total Toppings Price: ${toppingPrice}</p>
                                        </div>
                                    </div>
                                )}




                                    


                                    <button
                                        className="actions-btns"
                                        onClick={() => {
                                            //handleCancel();
                                            setTimeout(() => CancelItemInContext(selectedItem), 0);
                                        }}
                                    >
                                        <div className='actions-btn-icon'><BiDish size={20} /></div>
                                        <div className='actions-btn-text'>Cancel Item</div>
                                    </button>


                                </>
                            
                        )
                    }
                   
                    <button className="actions-btns" style={{ marginTop:'20px' }} onClick={handleCancelAll}>
                        <div className='actions-btn-icon'><TbReceiptOff size={20} /></div><div className='actions-btn-text'>Cancel receipt</div>  
                    </button>
                    <button className="actions-btns" onClick={toggleCouponPopup}>
                        <div className='actions-btn-icon'><RiCoupon3Line size={20} /></div><div className='actions-btn-text'>Coupons</div>
                    </button>
                </div>

                <div className="keypad-container-actions">
                    <div className="keypad-actions">
                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0'].map((key) => (
                            <button
                                key={key}
                                onClick={() => handleNumberClick(key)}
                                className="keypad-button-actions"
                            >
                                {key}
                            </button>
                        ))}
                        <button onClick={handleBackspace} className="keypad-button backspace">
                            ←
                        </button>
                        <button onClick={handleSubmit} className="keypad-button confirm">
                            Submit
                        </button>
                    </div>
                </div>
                {showCouponPopup && (
                    <div className="coupon-popup">
                        <div className="coupon-popup-content">
                            <h2 style={{color:'black'} }>Open Coupons</h2>
                            <input
                                type="text"
                                value={couponCode}
                                onChange={handleCouponCodeChange}
                                placeholder="Enter your coupon code"
                                className="coupon-input"
                            />
                            <button onClick={handleApplyCoupon} className="apply-coupon-btn">
                                Apply Coupon
                            </button>
                            <button onClick={toggleCouponPopup} className="close-popup-btn">
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Actions;
