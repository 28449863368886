import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';  // Import Line and Bar from react-chartjs-2
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../../Styles/Welcome.css';
import { FaBorderStyle } from "react-icons/fa";
import { MdOutlinePending } from "react-icons/md";
import { MdIncompleteCircle } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { CiMobile1 } from "react-icons/ci";
import { PiUsersThree } from "react-icons/pi";
import { LiaUsersCogSolid } from "react-icons/lia";
import { initializeApp } from 'firebase/app';
import {  getApps } from 'firebase/app';
import { getFirestore, collection, getDocs,addDoc } from 'firebase/firestore';
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);
const firebaseConfig2 = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig2);
const firestore2 = getFirestore(app);
// Registering Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const App = () => {

  const [TotalOrders, setTotalOrders] = useState(0)
  const [PendingOrders, setPendingOrders] = useState(0)
  const [CompletedOrders, setCompletedOrders] = useState(0)
  const [FromWebsiteOrders, setFromWebsiteOrders] = useState(0)
  const [FromAppOrders, setFromAppOrders] = useState(0)
  const [outfordelivery, setoutfordelivery] = useState(0)
  const [Customers, setCustomers] = useState(0)
  const [User , setUser ] = useState(0)
  const [TotalSales, setTotalSales] = useState(0); // Initialize with 0
  const [totalSalesPerMonth, setTotalSalesPerMonth] = useState(Array(12).fill(0)); // Initialize with 12 zeros for each month


  
  const [OrdersPerMonth , setOrdersPerMonth ] = useState()
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'Orders'));
        const ordersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(ordersData);
        setTotalOrders(ordersData.length);
  
        const processingOrders = ordersData.filter(order => order.status === 'processing');
        const completed = ordersData.filter(order => order.status === 'completed');
        const outfordeliverys = ordersData.filter(order => order.status === 'out-for-delivery');
        const website = ordersData.filter(order => order.From === 'website');
        const app = ordersData.filter(order => order.From === 'app');
        setPendingOrders(processingOrders.length);
        setCompletedOrders(completed.length);
        setFromWebsiteOrders(website.length);
        setFromAppOrders(app.length);
        setoutfordelivery(outfordeliverys.length);
  
        // Prepare data for charting
        const totalSalesPerMonth = ordersData.reduce((acc, order) => {
          const month = new Date(order.createdAt.seconds * 1000).getMonth(); // Extract month
          acc[month] = (acc[month] || 0) + order.totalAmount; // Sum sales for each month
          return acc;
        }, Array(12).fill(0)); // Initialize with 0 for all 12 months
        
        setOrdersPerMonth(totalSalesPerMonth); // Set the monthly sales data
        console.log('totalSalesPerMonth',totalSalesPerMonth)
        const salesPerMonth = ordersData.reduce((acc, order) => {
          const month = new Date(order.createdAt.seconds * 1000).getMonth(); // Extract month
          acc[month] = (acc[month] || 0) + order.totalAmount; // Sum sales for each month
          return acc;
        }, Array(12).fill(0)); // Initialize with 0 for all 12 months
  
        setTotalSalesPerMonth(salesPerMonth); // Update the state
  
        const ordersPerMonth = ordersData.reduce((acc, order) => {
          const month = new Date(order.createdAt.seconds * 1000).getMonth(); // Extract month from timestamp
          acc[month] = (acc[month] || 0) + 1;
          return acc;
        }, Array(12).fill(0));  // Ensure there are 12 months even if no data exists for a particular month
  
        const totalSalesAmount = ordersData.reduce((total, order) => total + order.totalAmount, 0);
        setTotalSales(totalSalesAmount); // Total sales in PKR
        console.log(totalSalesAmount)
        setOrdersPerMonth(ordersPerMonth); // Set monthly order data
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
  
    const fetchOrders2 = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore2, 'AccessUsers'));
        const usersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUser(usersData.length);
        // Update customers data if needed
        setCustomers(usersData.length);  // Assuming it represents customer count
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchOrders();
    fetchOrders2();
    const interval = setInterval(() => {
      fetchOrders();
      fetchOrders2();
    }, 3000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  
  const orderStats = {
    totalOrders: TotalOrders,
    pendingOrders: PendingOrders,
    completedOrders: CompletedOrders,
    websiteOrders: FromWebsiteOrders,
    appOrders: FromAppOrders,
    outfordelivery: outfordelivery,
    totalCustomers: Customers,
    totalSales: TotalSales, // Updated
    totalSalesPerMonth: totalSalesPerMonth, // Use totalSalesPerMonth here
    totalUsers: User,
    OrdersPerMonths: OrdersPerMonth, // This still counts the number of orders
    totalOrdersPerMonth: OrdersPerMonth,
  };
  

// Sales data for the line chart (showing amount in dollars)
// Sales data for the line chart (showing amount in Pkr)
const salesData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Total Sales in Pkr ',
      data: orderStats.totalSalesPerMonth,  // Corrected here to show total sales
      borderColor: '#c84347',
      backgroundColor: 'rgba(200, 67, 71, 0.2)',
      borderWidth: 2,
      tension: 0.4,
    },
  ],
};






// Order data for the bar chart (showing number of orders per month)
const orderData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Number of Orders',
      data: orderStats.totalOrdersPerMonth,
      backgroundColor: ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#FFD700', '#8A2BE2'], // Custom colors for each bar
      borderColor: '#fff', // Optional: Set the border color for each bar
      borderWidth: 2,
    },
  ],
};

// Order breakdown data (Pending, Completed, Website Orders, App Orders)
const orderBreakdownData = {
  labels: ['Pending', 'Completed', 'Website Orders', 'App Orders'],
  datasets: [
    {
      label: 'Order Status Distribution',
      data: [orderStats.pendingOrders, orderStats.completedOrders, orderStats.websiteOrders, orderStats.appOrders],
      backgroundColor: ['#FF6F61', '#4CAF50', '#2196F3', '#F44336'],
      borderColor: '#fff',
      borderWidth: 2,
    },
  ],
};

  return (
    <div className="dashboard-container">

      <div className='dash-text-main'>My Dashboard</div>
      <div className='dash-text'>You can see some information on here.</div>

      <div className='img-cont'>
      <div className='img-cont-overlay'>
      <div className='dash-text-main2'>Order and Customer Analytics Overview</div>
      <div className='dash-text2'>This report provides a comprehensive summary of recent order and customer activity across various platforms.</div>
  
      
      <div className="stats-container">
      
        <div className="stat-card">
          <FaBorderStyle color='#c84347' size={25} />
          <h2>Total Orders</h2>
          <div>{orderStats.totalOrders}</div>
        </div>
        <div className="stat-card">
          <MdOutlinePending color='#c84347' size={25} />
          <h2>Pending Orders</h2>
          <div>{orderStats.pendingOrders}</div>
        </div>
        <div className="stat-card">
          <MdOutlinePending color='#c84347' size={25} />
          <h2> Out for delivery</h2>
          <div>{orderStats.outfordelivery}</div>
        </div>
        <div className="stat-card">
          <MdIncompleteCircle color='#c84347' size={25} />
          <h2>Completed Orders</h2>
          <div>{orderStats.completedOrders}</div>
        </div>
        <div className="stat-card">
          <CgWebsite color='#c84347' size={25} />
          <h2>Orders from Website</h2>
          <div>{orderStats.websiteOrders}</div>
        </div>
        <div className="stat-card">
          <CiMobile1 color='#c84347' size={25} />
          <h2>Orders from App</h2>
          <div>{orderStats.appOrders}</div>
        </div>
        {/* <div className="stat-card">
          <PiUsersThree color='#c84347' size={25} />
          <h2>Total Customers</h2>
          <div>{orderStats.totalCustomers}</div>
        </div> */}
        <div className="stat-card">
          <LiaUsersCogSolid color='#c84347' size={25} />
          <h2>Total Users</h2>
          <div>{orderStats.totalUsers}</div>
        </div>
      </div>
      </div>
      </div>
      {/* Charts Section */}
      <div className="charts-container">
        {/* Sales Chart (Line) */}
        <div className="chart-box">
          <div>Sales Overview</div>
          <Line 
            data={salesData} 
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Total Sales Over Time',
                },
                legend: {
                  position: 'top',
                },
              },
              scales: {
                y: {
                  beginAtZero: true, // Ensures that the Y-axis starts from 0
                },
              },
            }}
            
          />
        </div>

        {/* Orders Chart (Bar) */}
        <div className="chart-box">
          <div>Order Count Over Time</div>
          <Bar 
            data={orderData} 
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Number of Orders Per Month',
                },
                legend: {
                  position: 'top',
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }} 
          />
        </div>

        {/* Order Breakdown Chart (Bar) */}
        <div className="chart-box">
          <div>Order Breakdown</div>
          <Bar 
            data={orderBreakdownData} 
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Order Status Distribution',
                },
                legend: {
                  position: 'top',
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }} 
          />
        </div>
      </div>
    </div>
  );
}

export default App;
