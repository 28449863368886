import React, { useState } from "react";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApps, initializeApp } from "firebase/app";
import '../../Styles/Addtional.css'

// Firebase configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find((app) => app.name === "checkoutApp") || initializeApp(checkoutFirebaseConfig, "checkoutApp");
const firestore = getFirestore(checkoutApp);

const AddEmployees = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    dateOfBirth: "",
    position: "",
    department: "",
    salary: "",
    joinDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const employeeRef = doc(firestore, "Employees", formData.email);
      await setDoc(employeeRef, formData);
      alert("Employee added successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        dateOfBirth: "",
        position: "",
        department: "",
        salary: "",
        joinDate: "",
      });
    } catch (error) {
      console.error("Error adding employee: ", error);
      alert("Failed to add employee.");
    }
  };

  return (
    <div className="form-container">
    <h1 className="form-title">Add New Employee</h1>
    <form className="employee-form" onSubmit={handleSubmit}>
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>First Name:</label>
      <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Last Name:</label>
      <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Email:</label>
      <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Phone Number:</label>
      <input type="text" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Address:</label>
      <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Date of Birth:</label>
      <input type="date" name="dateOfBirth" placeholder="Date of Birth" value={formData.dateOfBirth} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Position:</label>
      <input type="text" name="position" placeholder="Position" value={formData.position} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Department:</label>
      <input type="text" name="department" placeholder="Department" value={formData.department} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Salary:</label>
      <input type="number" name="salary" placeholder="Salary" value={formData.salary} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Join Date:</label>
      <input type="date" name="joinDate" placeholder="Join Date" value={formData.joinDate} onChange={handleChange} required />
      
      {/* Additional Payroll Fields */}
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Tax Identification Number (TIN):</label>
      <input type="text" name="taxId" placeholder="Tax Identification Number" value={formData.taxId} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Bank Account Number:</label>
      <input type="text" name="bankAccount" placeholder="Bank Account Number" value={formData.bankAccount} onChange={handleChange} required />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Payment Method:</label>
      <select name="paymentMethod" value={formData.paymentMethod} onChange={handleChange} required className="select-data">
        <option value="">Select Payment Method</option>
        <option value="bankTransfer">Bank Transfer</option>
        <option value="cheque">Cheque</option>
        <option value="cash">Cash</option>
      </select>
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Overtime Rate:</label>
      <input type="number" name="overtimeRate" placeholder="Overtime Rate (per hour)" value={formData.overtimeRate} onChange={handleChange} />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Deductions (e.g., Loans, Penalties):</label>
      <input type="number" name="deductions" placeholder="Deductions" value={formData.deductions} onChange={handleChange} />
      
      <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Benefits (e.g., Health, Insurance):</label>
      <input type="text" name="benefits" placeholder="Benefits" value={formData.benefits} onChange={handleChange} />
      
      <button type="submit" className="submit-button">Add Employee</button>
    </form>
  </div>
  
  );
};

export default AddEmployees;
