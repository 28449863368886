import { useState } from "react";
import "../Component-7/Currencies.css";
import CurrenciesDetails from "./Pages-7/CurrenciesDetails";
import CurrenciesRoundings from "./Pages-7/CurrenciesRoundings";

const Currencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [newCurrency, setNewCurrency] = useState({
    code: "",
    decimalPlaces: ""
  });
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [activePage, setActivePage] = useState("Details");
  const [errorMessage, setErrorMessage] = useState("");

  const subNavTabs = ["Details", "Roundings"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCurrency((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (!newCurrency.code || !newCurrency.decimalPlaces) {
      setErrorMessage("All fields are required.");
      return;
    }

    // Check if the currency code already exists
    if (currencies.some(currency => currency.code === newCurrency.code)) {
      setErrorMessage(`Currency code ${newCurrency.code} already exists.`);
      return;
    }

    setCurrencies((prevCurrencies) => [...prevCurrencies, newCurrency]);
    setNewCurrency({ code: "", decimalPlaces: "" });
    setFormVisible(false);
    setErrorMessage("");
  };

  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency);
  };

  return (
    <div className="currencies-container">
      <aside className="currencies-sidebar">
        <div className="currencies-list-container">
          {currencies.map((currency, index) => (
            <div
              key={index}
              className={`currencies-item-container ${
                selectedCurrency?.code === currency.code ? "active" : ""
              }`}
              onClick={() => handleCurrencyClick(currency)}
            >
              {currency.code}
            </div>
          ))}
        </div>
        <button
          className="add-currencies-btn"
          onClick={() => setFormVisible(true)}
        >
          Add Currency
        </button>
      </aside>

      <main className="currencies-main-content-container">
        {formVisible && (
          <div className="currencies-form-overlay-container">
            <div className="currencies-form-container">
              <h3>Create Currency</h3>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <input
                type="text"
                name="code"
                value={newCurrency.code}
                placeholder="Currency Code (e.g., USD, EUR)"
                onChange={handleInputChange}
                required
              />
              <input
                type="number"
                name="decimalPlaces"
                value={newCurrency.decimalPlaces}
                placeholder="Decimal Places"
                onChange={handleInputChange}
                required
              />
              <div className="currencies-form-actions-container">
                <button
                  className="currencies-cancel-btn"
                  onClick={() => {
                    setFormVisible(false);
                    setErrorMessage("");
                  }}
                >
                  Cancel
                </button>
                <button className="currencies-save-btn" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedCurrency && (
          <>
            <nav className="currencies-navbar-container">
              <div className="currencies-navbar-content">
                <h2>{selectedCurrency.code}</h2>
                <div className="currencies-info-container">
                  {/* <p>Decimal Places: {selectedCurrency.decimalPlaces}</p> */}
                </div>
              </div>
            </nav>

            <div className="currencies-sub-navbar-container">
              {subNavTabs.map((tab) => (
                <div
                  key={tab}
                  className={`currencies-sub-nav-tab ${
                    activePage === tab ? "active" : ""
                  }`}
                  onClick={() => setActivePage(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>

            <div className="currencies-page-content-container">
              {activePage === "Details" && (
                <CurrenciesDetails currencyCode={selectedCurrency.code} decimalPlace={selectedCurrency.decimalPlaces} />
              )}
              {activePage === "Roundings" && (
                <CurrenciesRoundings currencyCode={selectedCurrency.code} />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Currencies;
