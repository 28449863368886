// import React, { useEffect, useState } from 'react';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs, doc, deleteDoc, setDoc } from 'firebase/firestore';
// import '../../Styles/Orders.css'; // Import the CSS file

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const AppOrders = () => {
//     const [firestoreDetails, setFirestoreDetails] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [changeText, setchangeText] = useState(true);
    
//     useEffect(() => {


//         fetchFirestoreDetails();
//     }, []);
//     const fetchFirestoreDetails = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(firestore, 'Dinning'));
//             const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             // Sort orders by date in descending order
//             details.sort((a, b) => new Date(b.date) - new Date(a.date));
//             console.log('firestore data', details);
//             setFirestoreDetails(details);
//         } catch (error) {
//             console.error('Error fetching Firestore details:', error);
//         } finally {
//             setLoading(false);
//         }
//     };
//     const completeOrder = async (order) => {
//         setchangeText(false)
//         const orderRef = doc(firestore, 'Dinning', order.id);
//         const completedOrderRef = doc(collection(firestore, 'CompleteDinning'));

//         try {
//             // Add the order to CompleteDinning
//             await setDoc(completedOrderRef, { ...order, completedAt: new Date().toISOString() });
//             // Remove the order from Dinning
//             await deleteDoc(orderRef);
//             // Update local state
//             setFirestoreDetails(prevOrders => prevOrders.filter(o => o.id !== order.id));
//             fetchFirestoreDetails()
//         } catch (error) {
//             console.error('Error completing order:', error);
//         }
//     };

//     return (
//         <div className="orders-container">
//             <h1 className="title">Dinning</h1>
//             {loading ? (
//                 <p className="loading-text">Loading orders...</p>
//             ) : (
//                 <>
//                     <ul className="orders-list">
//                         {firestoreDetails.map((order) => (
//                             <li key={order.id} className="order-item">
//                                 <p className="order-details">
//                                     Order <span className="order-number">#{order.id}</span> - Total: <span className="total">Rs. {order.totalPrice}</span> - Table: <span className="table-number">{order.tableNumber}</span> - Date: <span className="date">{new Date(order.date).toLocaleString()}</span>
//                                 </p>
//                                 <ul className="line-items">
//                                     {order.items && order.items.length > 0 ? (
//                                         order.items.map((item) => (
//                                             <li key={item.id} className="line-item">
//                                                 {/* <img src={item.image} alt={item.title} className="item-image" /> */}
//                                                 <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">Rs. {item.price}</span>
//                                             </li>
//                                         ))
//                                     ) : (
//                                         <li>No items in this order.</li>
//                                     )}
//                                 </ul>
//                                 <button onClick={() => completeOrder(order)}  style={{border:'1px solid silver' ,marginTop:'10px',textAlign:'center',backgroundColor:'#c84347',color:'white'} }>
//                                     {
//                                         changeText?
//                                         'Complete Order' :
//                                         'Completing...'
//                                     }
//                                 </button>
//                             </li>
//                         ))}
//                     </ul>
//                 </>
//             )}
//         </div>
//     );
// };

// export default AppOrders;



import React, { useEffect, useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import '../../Styles/Orders.css';

// Firebase Configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [loading, setLoading] = useState(true); // Loading state

  // Function to fetch orders from Firestore
  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 }; // Default value if `createdAt` is not available
          return order;
        }
      });

      // Filter orders where status is 'processing' and sort by SequenceNumber (smallest to biggest)
      const processingOrders = ordersData.filter(order => order.status === 'completed-dinning');
      processingOrders.sort((a, b) => a.SequenceNumber - b.SequenceNumber); // Sorting by SequenceNumber
      setOrders(processingOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  // Fetch orders when the component mounts and set an interval for periodic fetching
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); // Refresh orders every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to update the status of an order to 'out-for-delivery'
  const addItemToConfirmedOrder = async (order) => {
    try {
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, { status: 'out-for-delivery' });
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status: 'out-for-delivery' } : o
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className="orders-container">
      <h1 className="title">Orders from POS</h1>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <li key={order.id} className="order-item">
                 <strong>Sequence Number: </strong><span className="order-number">{order.SequenceNumbers}</span>  <br />
                 <strong>Table Number: </strong><span className="order-number">{order.tableNumber}</span>  <br />
                 <strong>Total Price: </strong>  <span className="order-number">{order.totalPrice}</span><br></br>
                
                 <strong>  Order </strong> 
                {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )}
               
              
              
                <p className="order-details">
                  {/* Displaying all data for each order */}
                  {/* <strong>From: </strong> {order.From} <br />
                
                  <strong>Billing Details:</strong> <br />
                  <ul>
                    <li><strong>First Name:</strong> {order.billingDetails?.firstName}</li>
                    <li><strong>Last Name:</strong> {order.billingDetails?.lastName}</li>
                    <li><strong>Email:</strong> {order.billingDetails?.email}</li>
                    <li><strong>Phone:</strong> {order.billingDetails?.phone}</li>
                    <li><strong>Address:</strong> {order.billingDetails?.streetAddress}, {order.billingDetails?.city}, {order.billingDetails?.state} {order.billingDetails?.postcode}</li>
                  </ul> */}

                  <strong>Additional Note: </strong> {order.billingDetails?.additionalNote || 'No notes'} <br />
                  {/* <strong>Status: </strong> {order.status} <br /> */}
                  {/* <strong>Created At: </strong> {new Date(order.createdAt.seconds * 1000).toLocaleString()} <br /> */}
                  {/* <strong>Total Amount: </strong> {order.totalAmount} {order.currency} <br /> */}

                  <ul className="order-items" style={{ marginTop: '20px' }}>
  <strong>Items:</strong>
  {order.items && order.items.length > 0 ? (
    order.items.map((item, index) => (
      <li key={index} className="order-item-detail">
        <img
          src={item.image}
          alt={item.title}
          className="item-image"
          style={{ maxWidth: '100px' }}
        />
        <div style={{ marginTop: '20px' }}>
          <span className="item-name" style={{ marginTop: '20px' }}>
            {item.title}
          </span>
          - <span className="item-quantity">Qty: {item.quantity}</span>
          <br />
          <strong>Price:</strong> {item.price} <br />
          {item.crust && (
            <>
              <strong>Crust:</strong> {item.crust} <br />
            </>
          )}
          {item.size && (
            <>
              <strong>Size:</strong> {item.size} <br />
            </>
          )}
       
            <>
            {item.toppings?.length > 0 && <><strong>Toppings:</strong> {item.toppings.join(', ')} <br /></>}
            </>
         
     
            <>
            {item.sauces?.length > 0 && <><strong>Sauces:</strong> {item.sauces.join(', ')} <br /></>}
            </>
       
        </div>
      </li>
    ))
  ) : (
    <p>No items</p>
  )}
</ul>




                  {/* Display a button to mark the order as ready */}
                  {/* {order.status === 'processing' && (
                    <button
                      onClick={() => addItemToConfirmedOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px' }}
                    >
                      Order is ready
                    </button>
                  )} */}
                </p>
              </li>
            ))
          ) : (
            <p>No orders found</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default Orders;
