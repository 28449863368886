import React, { useState, useEffect } from "react";
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const UniqueCurrencyTable = () => {
    const [currencies, setCurrencies] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

 
    useEffect(() => {
        const fetchCurrencies = async () => {
            const currenciesDocRef = doc(firestore3, "Currency", "Currency");
            try {
                const docSnapshot = await getDoc(currenciesDocRef);
                if (docSnapshot.exists()) {
                    setCurrencies(docSnapshot.data().currencies || []); 
                } else {
                    console.log("No currency data found");
                }
            } catch (error) {
                console.error("Error fetching currencies:", error);
            }
        };

        fetchCurrencies(); 
    }, []); 

    const handleCheckboxChange = (id) => {
        setCurrencies(prev =>
            prev.map(curr => curr.id === id ? { ...curr, supported: !curr.supported } : curr)
        );
    };

    const handleDecimalChange = (id, value) => {
        setCurrencies(prev =>
            prev.map(curr => curr.id === id ? { ...curr, decimalPlaces: value } : curr)
        );
    };

   
    const handleCurrencyChange = (id, value) => {
        setCurrencies(prev =>
            prev.map(curr => curr.id === id ? { ...curr, currency: value } : curr)
        );
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        const currenciesDocRef = doc(firestore3, "Currency", "Currency"); 

        try {
            await updateDoc(currenciesDocRef, {
                currencies: currencies,
            });
            setIsEditing(false); 
        } catch (error) {
            console.error("Error saving currencies:", error);
        }
    };

    const handleAddNewRow = () => {
        setCurrencies(prev => [
            ...prev,
            { id: prev.length + 1, currency: "", supported: false, decimalPlaces: 2 }
        ]);
    };

    const handleDeleteRow = (id) => {
        setCurrencies(prev => prev.filter(curr => curr.id !== id));
    };

    return (
        <div className="unique-currency-table-container">
            <table className="unique-currency-table">
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Payment supported</th>
                        <th>Decimal places</th>
                        {isEditing && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {currencies.map((row) => (
                        <tr key={row.id}>
                            <td>
                                <input
                                    type="text"
                                    className="unique-input"
                                    value={row.currency}
                                    onChange={(e) => handleCurrencyChange(row.id, e.target.value)}
                                    disabled={!isEditing}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    className="unique-checkbox"
                                    checked={row.supported}
                                    onChange={() => handleCheckboxChange(row.id)}
                                    disabled={!isEditing}
                                />
                            </td>
                            <td>
                                <select
                                    className="unique-select"
                                    value={row.decimalPlaces}
                                    onChange={(e) => handleDecimalChange(row.id, parseInt(e.target.value))}
                                    disabled={!isEditing}
                                >
                                    <option value={0}>0</option>
                                    <option value={2}>2</option>
                                </select>
                            </td>
                            {isEditing && (
                                <td>
                                    <button onClick={() => handleDeleteRow(row.id)} className="delete-btn">
                                        Delete
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="checkout-bottom-bar">
                {isEditing && (
                    <button onClick={handleAddNewRow} className="bottom-bar-action-btn">
                        Add New Row
                    </button>
                )}
                <button onClick={toggleEdit} className="bottom-bar-action-btn">
                    {isEditing ? "Cancel" : "Edit"}
                </button>
                {isEditing && (
                    <button onClick={handleSave} className="bottom-bar-action-btn save-btn">
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default UniqueCurrencyTable;
