import "../Pages-4/AdditionalFields.css";

const AdditionalFields = () => {
  const additionalFields = [
    {
      name: "Field 1",
      value: "Value 1",
    },
    {
      name: "Field 2",
      value: "Value 2",
    },
    {
      name: "Field 3",
      value: "Value 3",
    },
    {
      name: "Field 4",
      value: "Value 4",
    },
  ];

  return (
    <div className="h-additional-fields-container">
      <table className="h-additional-fields-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {additionalFields.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdditionalFields;
