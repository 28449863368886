import  { useState } from 'react';
import '../Component-8/DeliveryModes.css';
const DeliveryModes = () => {
  const [deliveryModes, setDeliveryModes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingMode, setEditingMode] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    quantityMode: '',
    addressRequired: false
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingMode !== null) {
      // Update existing entry
      const updatedModes = [...deliveryModes];
      updatedModes[editingMode] = formData;
      setDeliveryModes(updatedModes);
    } else {
      // Add new entry
      setDeliveryModes([...deliveryModes, { ...formData, id: Date.now() }]);
    }
    setShowForm(false);
    setEditingMode(null);
    setFormData({
      code: '',
      name: '',
      quantityMode: '',
      addressRequired: false
    });
  };

  const handleDelete = (index) => {
    const newModes = deliveryModes.filter((_, i) => i !== index);
    setDeliveryModes(newModes);
  };

  const handleEdit = (index) => {
    setFormData(deliveryModes[index]);
    setEditingMode(index);
    setShowForm(true);
  };

  const filteredModes = deliveryModes.filter(mode =>
    mode.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
    mode.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
      <div className="navbar">
        <h2>Delivery Modes</h2>
        <div className="controls">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={() => setShowForm(true)}>Add +</button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Delivered Quantity Mode</th>
            <th>Shipping Address Required</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredModes.map((mode, index) => (
            <tr key={index}>
              <td>{mode.code}</td>
              <td>{mode.name}</td>
              <td>{mode.quantityMode}</td>
              <td>
                <input
                  type="checkbox"
                  checked={mode.addressRequired}
                  readOnly
                />
              </td>
              <td>
                <button onClick={() => handleEdit(index)}>Edit</button>
                <button onClick={() => handleDelete(index)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showForm && (
        <div className="modal">
          <div className="modal-content">
            <h3>{editingMode !== null ? 'Edit' : 'Add'} Delivery Mode</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Code:</label>
                <input
                  type="text"
                  name="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Delivered Quantity Mode:</label>
                <input
                  type="text"
                  name="quantityMode"
                  value={formData.quantityMode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    name="addressRequired"
                    checked={formData.addressRequired}
                    onChange={handleInputChange}
                  />
                  Shipping Address Required
                </label>
              </div>
              <div className="form-actions">
                <button type="submit">Save</button>
                <button type="button" onClick={() => {
                  setShowForm(false);
                  setEditingMode(null);
                }}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeliveryModes;