import React from 'react'

const LoyalCustomers = () => {
  return (
    <div className="orders-container">
      <h1 className="title">coming soon</h1>
      
    </div>
  )
}

export default LoyalCustomers
