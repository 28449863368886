import React, { useEffect, useState } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './Terminal.css';


const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);


const TerminalItems = ({ setSelectedItem, selectedItem }) => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All Categories'); 
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPizza, setSelectedPizza] = useState(null);
    const [crust, setCrust] = useState('');
    const [size, setSize] = useState('');
    const [showSaucePopup, setShowSaucePopup] = useState(false); 
    const [selectedBurger, setSelectedBurger] = useState(null); 
    const [selectedSauce, setSelectedSauce] = useState(''); 
    const [showPeriPeriPopup, setShowPeriPeriPopup] = useState(false); 
    const [selectedPeri, setSelectedPeri] = useState(null); 
    const [selectedPeriPeriFlavor, setSelectedPeriPeriFlavor] = useState('');

    const saucesList = ['Perinaise', 'Mayonnaise', 'Ketchup', 'BBQ'];
    const periPeriFlavors = ['Hot', 'Mild', 'Extra Hot'];

    useEffect(() => {
        const fetchFirestoreDetails = async () => {
            try {
                const menuItemsSnapshot = await getDocs(collection(firestore3, 'Items'));
                const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const dealsSnapshot = await getDocs(collection(firestore3, 'Deals'));
                const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const details = [...menuItems, ...deals];

                const uniqueCategories = Array.from(new Set(details.map(item => item.category?.trim()).filter(Boolean)));
                const categoryOrder = [
                    'All Categories','Peri Peri', 'Shake', 'Pizza', 'Sandwiches', 'Fried',
                    'Sides', 'Hot Coffee', 'Pasta', 'Cold Drinks', 'Desserts'
                ];
                const sortedCategories = uniqueCategories.sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b));
                const finalCategories = ['All Categories', ...sortedCategories.filter(cat => cat !== 'All Categories')];

                setCategories(finalCategories);
                setFirestoreDetails(details);
            } catch (error) {
                console.error('Error fetching Firestore details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFirestoreDetails();
    }, []);
    const handleItemClick = (item) => {
        if (item.category === 'Pizza') {
            setSelectedPizza(item);
            setShowPopup(true);
        } else if (item.category === 'Burgers/Wrpas') {
            setSelectedBurger(item);
            setShowSaucePopup(true);
        } else if (item.category === 'Peri Peri') {
            setSelectedPeri(item); 
            setShowPeriPeriPopup(true); 
        } else {
            setSelectedItem(item);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
        setCrust('');
        setSize('');
    };

    const handleConfirm = () => {
        setSelectedItem({ ...selectedPizza, crust, size });
        handlePopupClose();
    };
    const handleSaucePopupClose = () => {
        setShowSaucePopup(false);
        setSelectedSauce('');
    };

    const handleSauceConfirm = () => {
        setSelectedItem({ ...selectedBurger, sauce: selectedSauce }); 
        handleSaucePopupClose();
    };

    const handlePeriPeriPopupClose = () => {
        setShowPeriPeriPopup(false);
        setSelectedPeriPeriFlavor(''); 
    };

    const handlePeriPeriConfirm = () => {
        setSelectedItem({ ...selectedPeri, flavor: selectedPeriPeriFlavor }); 
        handlePeriPeriPopupClose();
    };

    const filteredItems = firestoreDetails.filter(item => {
        const titleMatches = item.title?.toLowerCase().includes(searchTerm.toLowerCase());
        const categoryFilterMatches = selectedCategory === 'All Categories' || item.category === selectedCategory;
        return titleMatches && categoryFilterMatches;
    });



    return (
        <div className="Toolbar-Items">
            {loading && <div></div>}
            <div className="search-container-items">
                <input
                    type="text"
                    placeholder="Search items with title or special number (eg: :123)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <ul className="item-list-terminal">
                {filteredItems.map(item => (
                    <li key={item.id} className="item-card-terminal" onClick={() => handleItemClick(item)}>
                        <h4 className="item-title-terminal">{item.title}</h4>
                        <p className="item-price-terminal">Rs. <strong>{item.price || 'Varies by size'}</strong></p>
                    </li>
                ))}
            </ul>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content-items">
                        <div className='change-color-main'>Select Crust and Size for {selectedPizza.title}</div>
                        <div>
                            <select value={crust} onChange={(e) => setCrust(e.target.value)}>
                                <option value="">Select Crust</option>
                                {Object.entries(selectedPizza.Crust || {}).map(([crustType, price]) => (
                                    <option key={crustType} value={crustType}>
                                        {crustType} (+Rs. {price})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <select value={size} onChange={(e) => setSize(e.target.value)}>
                                <option value="">Select Size</option>
                                {selectedPizza.sizes?.map(({ size, price }, index) => (
                                    <option key={index} value={size}>
                                        {size} - Rs. {price}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="popup-actions-items">
                            <button className='popup-btn-confirm' onClick={handleConfirm} disabled={!crust || !size}>
                                Confirm
                            </button>
                            <button className='popup-btn-cancel' onClick={handlePopupClose}>Cancel</button>

                        </div>
                    </div>
                </div>
            )}
            {showSaucePopup && (
                <div className="popup-overlay">
                    <div className="popup-content-items">
                        <div className='change-color-main'>Select Sauce for {selectedBurger.title}</div>
                        <div>
                            <select value={selectedSauce} onChange={(e) => setSelectedSauce(e.target.value)}>
                                <option value="">Select Sauce</option>
                                {saucesList.map((sauce, index) => (
                                    <option key={index} value={sauce}>{sauce}</option>
                                ))}
                            </select>
                        </div>
                        <div className="popup-actions-items">
                            <button className='popup-btn-confirm' onClick={handleSauceConfirm} disabled={!selectedSauce}>
                                Confirm
                            </button>
                            <button className='popup-btn-cancel' onClick={handleSaucePopupClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
            {showPeriPeriPopup && (
                <div className="popup-overlay">
                    <div className="popup-content-items">
                        <div className='change-color-main'>Select Flavor for {selectedPeri.title}</div>
                        <div>
                            <select value={selectedPeriPeriFlavor} onChange={(e) => setSelectedPeriPeriFlavor(e.target.value)}>
                                <option value="">Select Flavor</option>
                                {periPeriFlavors.map((flavor, index) => (
                                    <option key={index} value={flavor}>{flavor}</option>
                                ))}
                            </select>
                        </div>
                        <div className="popup-actions-items">
                            <button className='popup-btn-confirm' onClick={handlePeriPeriConfirm} disabled={!selectedPeriPeriFlavor}>
                                Confirm
                            </button>
                            <button className='popup-btn-cancel' onClick={handlePeriPeriPopupClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
            <div className="category-boxes-terminal">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`category-box-item ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => {
                            setSelectedCategory(category);
                            console.log(category);
                        }}
                    >
                        <span>{category}</span>
                    </button>

                ))}
            </div>
        </div>
    );
};

export default TerminalItems;
