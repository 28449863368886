import "../Pages-4/DispatchStates.css";

const DispatchStates = () => {
  const dispatchStates = [
    {
      status: "Shipped",
      name: "Order #123",
      modifiedAt: "2025-01-15 14:30:00",
    },
    {
      status: "Pending",
      name: "Order #124",
      modifiedAt: "2025-01-16 09:15:00",
    },
    {
      status: "In Transit",
      name: "Order #125",
      modifiedAt: "2025-01-17 16:45:00",
    },
    {
      status: "Delivered",
      name: "Order #126",
      modifiedAt: "2025-01-18 11:00:00",
    },
  ];

  return (
    <div className="h-dispatch-states-container">
      <table className="h-dispatch-states-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Name</th>
            <th>Modified At</th>
          </tr>
        </thead>
        <tbody>
          {dispatchStates.map((item, index) => (
            <tr key={index}>
              <td>{item.status}</td>
              <td>{item.name}</td>
              <td>{item.modifiedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DispatchStates;
