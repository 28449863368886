

import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs,addDoc} from 'firebase/firestore';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import '../../Styles/Orders.css';
// import { getFirestore, collection } from 'firebase/firestore';
import {  getApps } from 'firebase/app';
import { FaPizzaSlice, FaCocktail, FaHamburger, FaThLarge } from 'react-icons/fa'; 
import { getFirestore, collection, getDocs,addDoc } from 'firebase/firestore';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
  };
  
  // Initialize Firebase app for checkout with a unique name
  const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
  const firestore = getFirestore(checkoutApp);
  const firebaseConfig2 = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig2);
const firestore2 = getFirestore(app);
const getCategoryIcon = (category) => {
    switch (category.toLowerCase()) {
        case 'pizza':
            return <FaPizzaSlice />; // Pizza icon
        case 'drinks':
            return <FaCocktail />; // Drink icon
        case 'sides':
            return <FaHamburger />; // Side dish icon
        default:
            return <FaThLarge />; // Default icon (box-like)
    }
};
const Orders = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [searchTerm, setSearchTerm] = useState('');
    const [cart, setCart] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [pdfGenerated, setPdfGenerated] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [sizePrice, setSizePrice] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentPizza, setCurrentPizza] = useState(null);
    const [amountPaidss, setAmountPaidss] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [balance, setBalance] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [changePdf, setchangePdf] = useState(true);
    const [changeAdd, setchangeAdd] = useState(true);
    const [tableNumber, setTableNumber] = useState('');
    const [password, setPassword] = useState('');
const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
const [itemToRemove, setItemToRemove] = useState(null);
const [passwordError, setPasswordError] = useState('');
const [discountedTotalPrice, setDiscountedTotalPrice] = useState('');
const [isAddItemVisible, setIsAddItemVisible] = useState(false);
const [Scroll, setScroll] = useState(false);
const [discount, setDiscount] = useState(0); // New state for discount
const [promoCode, setPromoCode] = useState(''); // New state for promo code
const [promoCodes, setPromoCodes] = useState([]); // To store promo codes from Firestore
const [remainingBalance, setRemainingBalance] = useState(balance); // To track remaining balance after payment

const [selectedCrust, setSelectedCrust] = useState(null);
const [crustPrice, setCrustPrice] = useState(0);
const [selectedSauce, setSelectedSauce] = useState([]);
const [selectedToppings, setSelectedToppings] = useState([]);
const [categories, setCategories] = useState([]); // State for storing categories

const [selectedCategory, setSelectedCategory] = useState(''); // State for category selection
    const [searchTerm, setSearchTerm] = useState('');
    const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
    const [calcInput, setCalcInput] = useState('');
    const [promoCodeApplied, setPromoCodeApplied] = useState(false);

    const handleCalculatorToggle = () => {
      setIsCalculatorOpen(!isCalculatorOpen);
    };
  
    const handleCalcInput = (value) => {
      setCalcInput(calcInput + value);
    };
  
    const handleCalcResult = () => {
      try {
        setCalcInput(eval(calcInput).toString());
      } catch (error) {
        setCalcInput('Error');
      }
    };
  
    const handleCalcClear = () => {
      setCalcInput('');
    };

useEffect(() => {
    const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(total - discount); // Apply discount to total
}, [cart, discount]);
useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const promoCodesCollection = await getDocs(collection(firestore2, 'Promo'));
        console.log('Promo codes query snapshot:', promoCodesCollection); // Log the whole snapshot
        
        // Ensure the snapshot is not empty
        if (promoCodesCollection.empty) {
          console.log('No promo codes found.');
        } else {
          // Log each document
          promoCodesCollection.docs.forEach(doc => {
            console.log('Promo code document data:', doc.data());
          });
        }
  
        const promoCodesList = promoCodesCollection.docs.map(doc => doc.data());
        console.log('Mapped promo codes:', promoCodesList); // Log the mapped data
  
        setPromoCodes(promoCodesList); // Store the promo codes in state
      } catch (error) {
        console.error("Error fetching promo codes:", error);
      }
    };
  
    fetchPromoCodes(); // Fetch promo codes on component mount
  }, []);
  
  
  const applyPromoCode = () => {
    if (promoCodeApplied) {
      alert("Discount has already been applied.");
      return;
    }
  
    if (promoCodes.length === 0) {
      alert("No promo codes available.");
      return;
    }
  
    const promo = promoCodes.find((promo) => promo.code === promoCode.trim());
    if (!promo) {
      alert("Invalid promo code.");
      return;
    }
  
    const currentDateTime = new Date();
    const promoStartDateTime = new Date(promo.startDateTime);
    const promoEndDateTime = new Date(promo.endDateTime);
  
    if (currentDateTime >= promoStartDateTime && currentDateTime <= promoEndDateTime) {
      // Calculate the discount amount based on the promo percent
      const discountAmount = (promo.percent / 100) * totalPrice;
      const discountedTotalPrice = totalPrice - discountAmount;
  
      // Set discounted total price
      setDiscountedTotalPrice(discountedTotalPrice);  // Store discounted price separately
      setTotalPrice(discountedTotalPrice);
      setPromoCodeApplied(true);  // Mark that a promo code has been applied
  
      alert(`Promo code applied! You've saved ${promo.percent}%. New price: $${discountedTotalPrice.toFixed(2)} (was $${totalPrice.toFixed(2)})`);
  
      // Recalculate balance using the discounted price and amountPaid
      const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const saucesTotal = sauces.reduce((acc, sauce) => acc + sauce.price * sauce.quantity, 0);
      const toppingsTotal = toppings.reduce((acc, topping) => acc + topping.price * topping.quantity, 0);
  
      setBalance(discountedTotalPrice + saucesTotal + toppingsTotal - amountPaid);
    } else {
      alert("Promo code is expired or not yet valid.");
    }
  };
  
  
  
  const handlePaymentChange = (event) => {
    const paidAmount = parseFloat(event.target.value) || 0;
    setAmountPaid(paidAmount);
  
    // Calculate balance using the discounted total price (not the original total price)
    const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const saucesTotal = sauces.reduce((acc, sauce) => acc + sauce.price * sauce.quantity, 0);
    const toppingsTotal = toppings.reduce((acc, topping) => acc + topping.price * topping.quantity, 0);
  
    // Use the discounted total price to calculate the remaining balance
    setBalance(discountedTotalPrice + saucesTotal + toppingsTotal - paidAmount);
  };
  
  const removeDiscount = () => {
    const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const saucesTotal = sauces.reduce((acc, sauce) => acc + sauce.price * sauce.quantity, 0);
    const toppingsTotal = toppings.reduce((acc, topping) => acc + topping.price * topping.quantity, 0);
    setPromoCodeApplied(false);  // Mark that no promo code is applied
    setTotalPrice(total + saucesTotal + toppingsTotal);  // Reset to the original price
    setDiscountedTotalPrice(total + saucesTotal + toppingsTotal);  // Reset discounted price to the original
    setBalance(balance + (totalPrice - (total + saucesTotal + toppingsTotal)));  // Recalculate balance if needed
  
    alert("Discount removed.");
  };
  




const crustOptions = [
    { name: 'Deep Pan', price: 0 },
    { name: 'Thin Crust', price: 0 },
    { name: 'Stuffed Crust - Cheesy', price: 140 },
    { name: 'Stuffed Crust - Special', price: 160 }
];


    const [sauces, setSauces] = useState([
        { name: 'BBQ Sauce', price: 20, quantity: 0 },
        { name: 'Hot Sauce', price: 15, quantity: 0 },
        { name: 'Garlic Sauce', price: 10, quantity: 0 },
    ]);
    const [toppings, setToppings] = useState([
        { name: 'Cheese', price: 30, quantity: 0 },
        { name: 'Mushrooms', price: 25, quantity: 0 },
        { name: 'Olives', price: 20, quantity: 0 },
    ]);

    useEffect(() => {
        const fetchFirestoreDetails = async () => {
            try {
                // Fetch data from 'MenuItems' collection
                const menuItemsSnapshot = await getDocs(collection(firestore2, 'MenuItems'));
                const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
                // Fetch data from 'Deals' collection
                const dealsSnapshot = await getDocs(collection(firestore2, 'Deals'));
                const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
                // Combine the results of both collections
                const details = [...menuItems, ...deals];
        
                setFirestoreDetails(details);
                console.log(details);
                const uniqueCategories = Array.from(new Set(details.map(item => item.category))).filter(Boolean);
                setCategories(uniqueCategories);

                console.log('Categories:', uniqueCategories);
            } catch (error) {
                console.error('Error fetching Firestore details:', error);
            } finally {
                setLoading(false);
            }
        };
        

        fetchFirestoreDetails();
    }, []);

    useEffect(() => {
        const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const saucesTotal = sauces.reduce((acc, sauce) => acc + sauce.price * sauce.quantity, 0);
        const toppingsTotal = toppings.reduce((acc, topping) => acc + topping.price * topping.quantity, 0);
        setTotalPrice(total + saucesTotal + toppingsTotal);
        setBalance(total + saucesTotal + toppingsTotal - amountPaid);
    }, [cart, amountPaid, sauces, toppings]);

    const addItemToCart = (item) => {
         console.log(item.category)
        if (item.category === "Pizza") {
            setCurrentPizza(item);
            setIsPopupOpen(true);
        } else {
            setCart(prevCart => {
                const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
                if (existingItem) {
                    return prevCart.map(cartItem =>
                        cartItem.id === item.id
                            ? { ...cartItem, quantity: cartItem.quantity + 1 }
                            : cartItem
                    );
                } else {
                    return [...prevCart, { ...item, quantity: 1 }];
                }
            });
        }
    };


      

    const confirmSizeSelection = () => {
        if (currentPizza && selectedSize && selectedCrust !== null) {
            // Log selected size and crust
            console.log('Selected Size:', selectedSize);
            console.log('Selected Crust:', selectedCrust);
    
            const itemToAdd = {
                ...currentPizza,
                price: sizePrice + crustPrice, // Add price of crust to the pizza size
                quantity: 1,
                selectedSize: selectedSize,
                selectedCrust: selectedCrust
            };
    
            setCart(prevCart => {
                const existingItem = prevCart.find(cartItem => 
                    cartItem.id === itemToAdd.id && cartItem.selectedSize === selectedSize && cartItem.selectedCrust === selectedCrust
                );
    
                if (existingItem) {
                    return prevCart.map(cartItem =>
                        cartItem.id === existingItem.id && cartItem.selectedSize === selectedSize && cartItem.selectedCrust === selectedCrust
                            ? { ...cartItem, quantity: cartItem.quantity + 1 }
                            : cartItem
                    );
                } else {
                    return [...prevCart, itemToAdd];
                }
            });
            setIsPopupOpen(false); // Close the popup after confirming
        }
    };
    

        
    

    const removeItemFromCart = (itemToRemove) => {
        setCart(cart.filter(item => item.id !== itemToRemove.id));
    };
    const handleRemoveItemClick = (item) => {
        setItemToRemove(item); // Store the item to be removed
        setIsPasswordPromptVisible(true); // Show the password prompt
    };
    
    const handlePasswordSubmit = () => {
        const correctPassword = "123456"; 
        if (password === correctPassword) {
            removeItemFromCart(itemToRemove);
            setIsPasswordPromptVisible(false);
            setPassword(''); 
            setPasswordError('');
        } else {
            setPasswordError('Incorrect password');
        }
    };
    
    const changeItemQuantity = (item, change) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
            if (existingItem) {
                const newQuantity = existingItem.quantity + change;
                if (newQuantity <= 0) {
                    return prevCart.filter(cartItem => cartItem.id !== item.id);
                }
                return prevCart.map(cartItem =>
                    cartItem.id === item.id ? { ...cartItem, quantity: newQuantity } : cartItem
                );
            }
            return prevCart;
        });
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Function to check if the search term is a shortcut (i.e., a number after ':')
    const isShortcutSearch = (term) => term.startsWith(':') && !isNaN(term.substring(1));


    // Filter the items based on the search term
 // Filter the items based on the search term
// Update the filter logic to include the selected category:
const filteredItems = firestoreDetails.filter(item => {
    // If the search term is a shortcut number (e.g., :123), match by Shortcut (id)
    if (isShortcutSearch(searchTerm)) {
        const shortcutNumber = searchTerm.substring(1); // Get the number after ":"
        console.log('Searching for Shortcut:', shortcutNumber);

        // Ensure the Shortcut field exists and matches the search term
        return item.Shortcut && item.Shortcut.toString() === shortcutNumber;
    }

    // Regular search by title or category (case-insensitive)
    const titleMatches = item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase());
    const categoryMatches = item.category && item.category.toLowerCase().includes(searchTerm.toLowerCase());

    // Apply category filtering if a category is selected
    const categoryFilterMatches = selectedCategory ? item.category === selectedCategory : true;

    return (titleMatches || categoryMatches) && categoryFilterMatches;
});

// Inside your JSX render, pass `filteredItems` to the list rendering:




const generatePDF = () => {
  setchangePdf(false)
  setInterval(() => { setchangePdf(true) }, 1500)
  const doc = new jsPDF();
  const customerId = `Invoice-${new Date().getTime()}`;
  const date = new Date().toLocaleDateString();
  const taxRate = 0; // Set tax rate to 0 to exclude tax
  const subtotal = totalPrice;
  const tax = 0; // Set tax to 0 since it's not applied
  const total = subtotal + tax; // Total is just the subtotal

  // Logo and Title
  doc.setFillColor(204, 0, 0);
  doc.rect(0, 0, 210, 30, 'F');
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(22);
  doc.text("INVOICE", 10, 20);

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(`Invoice To: ${customerName || "Customer"}`, 10, 40);
  doc.text("Main Road, Building Name,", 10, 45);
  doc.text("Country", 10, 50);
  doc.text(`Invoice: ${customerId}`, 150, 40);
  doc.text(`Date: ${date}`, 150, 45);

  // Table columns and rows based on cart items
  const tableColumns = ["SL.", "ITEM DESCRIPTION", "PIZZA TYPE", "PRICE", "QTY.", "TOTAL"];
  const tableRows = cart.map((item, index) => [
      index + 1,
      `${item.title} - ${item.selectedSize || ''}`,
      item.selectedPizzaType, // Add Pizza Type here
      `Rs. ${item.price.toFixed(2)}`,
      item.quantity,
      `Rs. ${(item.price * item.quantity).toFixed(2)}`
  ]);

  // Adding the table
  doc.autoTable({
      startY: 60,
      head: [tableColumns],
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: [204, 0, 0], textColor: 255 },
      styles: { halign: 'center' }
  });

  // Adding subtotal and total section (no tax)
  const finalY = doc.lastAutoTable.finalY + 10;
  doc.setFontSize(12);
  doc.text(`SUB-TOTAL: Rs. ${subtotal.toFixed(2)}`, 150, finalY);
  doc.setFontSize(14);
  doc.text(`TOTAL: Rs. ${total.toFixed(2)}`, 150, finalY + 15);

  // Footer
  doc.setFontSize(10);
  doc.text("Thank you for your business", 10, finalY + 30);
  doc.text("Terms & Conditions: Payment is due within 15 days.", 10, finalY + 35);
  doc.text("Authorized Sign", 150, finalY + 35);

  // Generate Blob and Update State
  const pdfBlob = doc.output("blob");
  handleAddToDining2()
  setPdfBlob(pdfBlob);
  setInterval(() => {});
  setPdfGenerated(true);
};

    const handleAddToDining2 = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      console.log("Query Snapshot:", querySnapshot);
      const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Order Details:", details);
      const SequenceNumber = details.length > 0 ? details.length + 1 : 1; 
      const IsFrom ='Take-Away';

      const sanitizedItems = cart.map(item => ({
          orderNumber:Math.floor(Math.random() * 100000),
          title: item.title || '',
          crust: selectedCrust || '',  // Ensure crust is never undefined
          size: selectedSize || '',
          price: item.price || 0,
          image: item.image || '',
          quantity: item.quantity || 1,
          toppings: selectedToppings || [],  // Pass array of toppings
          sauces: selectedSauce || [],  // Pass array of sauces
         
        }));
      try {
          const orderData = {
              status:'completed',
              SequenceNumber:SequenceNumber,
              tableNumber,
              items: sanitizedItems,
              totalPrice,
              From:IsFrom,
              date: new Date().toISOString(),
              createdAt: new Date(),
          };
          await addDoc(collection(firestore, 'Orders'), orderData);
          alert('Order added to completed!');
          setTableNumber('');
          setScroll(false)
          // setCart([]);
      } catch (error) {
          console.error('Error adding order to Firestore:', error);
      }
  };
    
    const viewPDF = () => {
        setPdfGenerated(false)
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            window.open(url, '_blank');
        }
    };
    


    const removeSauce = (sauce) => {
        setSauces(sauces.map(s =>
            s.name === sauce.name ? { ...s, quantity: s.quantity - 1 } : s
        ));
        // Remove the sauce from selectedSauce array
        setSelectedSauce(prevSelectedSauces => prevSelectedSauces.filter(s => s.name !== sauce.name));
    };
    
    const removeTopping = (topping) => {
        setToppings(toppings.map(t =>
            t.name === topping.name ? { ...t, quantity: t.quantity - 1 } : t
        ));
        // Remove the topping from selectedToppings array
        setSelectedToppings(prevSelectedToppings => prevSelectedToppings.filter(t => t.name !== topping.name));
    };
    
    const addSauce = (sauce) => {
        // Update the quantity of the sauce in the sauces array
        setSauces(sauces.map(s =>
            s.name === sauce.name ? { ...s, quantity: s.quantity + 1 } : s
        ));
    
        // Store only the sauce name in the selectedSauce array
        setSelectedSauce(prevSelectedSauces => {
            if (!Array.isArray(prevSelectedSauces)) {
                prevSelectedSauces = [];
            }
            // Ensure no duplicates (if the sauce is already selected, do not add it again)
            if (!prevSelectedSauces.includes(sauce.name)) {
                return [...prevSelectedSauces, sauce.name];
            }
            return prevSelectedSauces;
        });
    };
    
    const addTopping = (topping) => {
        // Update the quantity of the topping in the toppings array
        setToppings(toppings.map(t =>
            t.name === topping.name ? { ...t, quantity: t.quantity + 1 } : t
        ));
    
        // Store only the topping name in the selectedToppings array
        setSelectedToppings(prevSelectedToppings => {
            if (!Array.isArray(prevSelectedToppings)) {
                prevSelectedToppings = [];
            }
            // Ensure no duplicates (if the topping is already selected, do not add it again)
            if (!prevSelectedToppings.includes(topping.name)) {
                return [...prevSelectedToppings, topping.name];
            }
            return prevSelectedToppings;
        });
    };
    
    
    


    const getTotalItemsInCart = () => {
        return cart.reduce((acc, item) => acc + item.quantity, 0);
    };
    const handleAddToDining = async () => {
        const querySnapshot = await getDocs(collection(firestore, 'Orders'));
        console.log("Query Snapshot:", querySnapshot);
        const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Order Details:", details);
        const SequenceNumber = details.length > 0 ? details.length + 1 : 1; 
        const IsFrom ='Dinning';

        const sanitizedItems = cart.map(item => ({
            orderNumber:Math.floor(Math.random() * 100000),
            title: item.title || '',
            crust: selectedCrust || '',  // Ensure crust is never undefined
            size: selectedSize || '',
            price: item.price || 0,
            image: item.image || '',
            quantity: item.quantity || 1,
            toppings: selectedToppings || [],  // Pass array of toppings
            sauces: selectedSauce || [],  // Pass array of sauces
           
          }));
        try {
            const orderData = {
                status:'processing',
                SequenceNumber:SequenceNumber,
                tableNumber,
                items: sanitizedItems,
                totalPrice,
                From:IsFrom,
                date: new Date().toISOString(),
                createdAt: new Date(),
            };
            await addDoc(collection(firestore, 'Orders'), orderData);
            alert('Order added to Dinning collection!');
            setTableNumber('');
            setScroll(false)
            // setCart([]);
        } catch (error) {
            console.error('Error adding order to Firestore:', error);
        }
    };
    const highlightText = (text, search) => {
        if (!search) return text; // If no search term, return the original text
        const parts = text.split(new RegExp(`(${search})`, 'gi')); // Split text by search term, case insensitive
        return parts.map((part, index) => 
            part.toLowerCase() === search.toLowerCase() 
                ? <span key={index} style={{ color: '#c84347',fontWeight:'bold' }}>{part}</span> 
                : part
        );
    };
    
    const handlePizzaTypeChange = (item, selectedType) => {
        const updatedCart = cart.map(cartItem => {
          if (cartItem.id === item.id) {
            return {
              ...cartItem,
              selectedPizzaType: selectedType // Update pizza type
            };
          }
          return cartItem;
        });
        setCart(updatedCart);
      };
      
 
    const handleCrustChange = (crust) => {
        setSelectedCrust(crust.name);
        setCrustPrice(crust.price);
    };
      

    
    return (
        <>

                <div className="orders-container">
             <div className='orders-container2'> 
             <div className='sec-1'>
             <h1 className="orders-title" style={{color:'#c84347'}}>Point of Sale</h1>
            
           
             <div className="search-container">
                 <input
                     type="text"
                     placeholder="Search items with title or with special number with ( : ) eg- ( :123 )"
                     value={searchTerm}
                     onChange={(e) => setSearchTerm(e.target.value)}
                     className="search-input"
                     autoFocus
                 />

             </div>
                 {/* Category Boxes */}
                 <div className="category-boxes">
  <button
    className={`category-box ${selectedCategory === '' ? 'active' : ''}`}
    onClick={() => setSelectedCategory('')}
  >
    <span>All Categories</span>
  </button>
  {categories.map((category) => (
    <button
      key={category}
      className={`category-box ${selectedCategory === category ? 'active' : ''}`}
      onClick={() => setSelectedCategory(category)}
    >
      <i className={`icon ${getCategoryIcon(category)}`}></i>
      <span>{category}</span>
    </button>
  ))}
</div>



             {loading ? (
                 <p className="loading-text">Loading orders...</p>
             ) : (
                 <>
                     <ul className="item-list">
    {filteredItems.map(item => (
        <li key={item.id} className="item-card">
            <img src={item.image} alt={item.title} className="item-image" />
            <h2 className="item-title">
                {highlightText(item.title, searchTerm)}
            </h2>
            <p className="item-prices">
                {typeof item.price === 'number' ? item.price.toFixed(2) : 'click to select'}
            </p>
            <button onClick={() => addItemToCart(item)} style={{borderTop:'1px solid silver', backgroundColor:"#ffe5e5"}} className="add-to-cart-button">Add</button>
        </li>
    ))}
</ul>
 
                     {/* Popup for Pizza Size Selection */}
                     {isPopupOpen && (
     <div className="popup-overlay">
         <div className="popup-content" style={{backgroundColor:'#af3127'}}>
             <button className="close-button" onClick={() => setIsPopupOpen(false)}>✖</button>
             <h2>Select Size for {currentPizza.title}</h2>
             {currentPizza.sizes.map((size, index) => (
                 <div key={index} className="size-option">
                     <input
                         type="radio"
                         id={`size-${index}`}
                         name="size"
                         value={size.size}
                         onChange={() => {
                             setSelectedSize(size.size);
                             setSizePrice(size.price);
                         }}
                     />
                     <label htmlFor={`size-${index}`}>{size.size} - Rs. {size.price}</label>
                 </div>
             ))}
             <h3>Select Crust Type</h3>
             {crustOptions.map((crust, index) => (
                 <div key={index} className="crust-option">
                     <input
                         type="radio"
                         id={`crust-${index}`}
                         name="crust"
                         value={crust.name}
                         onChange={() => handleCrustChange(crust)}
                     />
                     <label htmlFor={`crust-${index}`}>{crust.name} {crust.price > 0 ? `- Extra Rs.${crust.price}` : ''}</label>
                 </div>
             ))}
             <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                 <button onClick={confirmSizeSelection} className="add-to-cart-button">Confirm</button>
                 <button onClick={() => setIsPopupOpen(false)} className="add-to-cart-button">Cancel</button>
             </div>
         </div>
     </div>
 )}
 
 
 
                     {/* Sidebar for cart */}
                     
 
 
  
                 </>
             )}
             {isPasswordPromptVisible && (
     <div className="password-prompt">
         <div className="password-prompt-content">
             <h3 style={{color:'black'}}>Enter Password to Remove Item</h3>
             <input
                 type="password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 placeholder="Enter password"
                 style={{width:'80%'}}
             />
             {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
             <div style={{ display: 'flex', gap: '10px' }}>
                 <button onClick={handlePasswordSubmit} style={{width:'50%'}}>Submit</button>
                 <button onClick={() => setIsPasswordPromptVisible(false)} style={{width:'50%'}}>Cancel</button>
             </div>
         </div>
     </div>
 )}
  </div>
  <div className='sec-2'>
  <div className={`open ${isSidebarOpen ? '' : ''}`} style={{ marginBottom: '100px' }}>
   <h2 className="cart-title">Cart</h2>
   {/* <button onClick={toggleSidebar} className="close-sidebar-button">✖</button> */}
 
   <ul className="cart-items-list">
   {cart.map((item, index) => (
     <div key={`${item.id}-${item.selectedSize}-${index}`}>
       <li className="cart-item" style={{backgroundColor:'#ffe5e5'}}>
         {item.title} - Size: {item.selectedSize} 
         - Price: Rs {typeof item.price === 'number' ? (item.price * item.quantity).toFixed(2) : 'N/A'} 
         - {item.selectedCrust ? `Crust/Type: ${item.selectedCrust}` : 'Crust/Type: Not selected'}
         
         <div style={{ display: 'flex', gap: '5px' }}>
           <button onClick={() => changeItemQuantity(item, -1)} className="quantity-button">-</button>
           {item.quantity}
           <button onClick={() => changeItemQuantity(item, 1)} className="quantity-button">+</button>
         </div>
         <button onClick={() => handleRemoveItemClick(item)} className="remove-item-button">✖</button>
       </li>
     </div>
   ))}
 </ul>
 
 
   {/* Extra Sauces Section */}
   <h3 style={{color:'#c84347'}}>Extra Sauces</h3>
   {sauces.map(sauce => (
     <div key={sauce.name} className="sauce-item">
       <span>{sauce.name} - Rs. {sauce.price}</span>
       <div style={{ display: 'flex', gap: '5px' }}>
         <button onClick={() => addSauce(sauce)} className="quantity-button">+</button>
         {sauce.quantity}
         <button onClick={() => removeSauce(sauce)} className="quantity-button">-</button>
       </div>
     </div>
   ))}
 
   {/* Extra Toppings Section */}
   <h3 style={{color:'#c84347'}}>Extra Toppings</h3>
   {toppings.map(topping => (
     <div key={topping.name} className="topping-item">
       <span>{topping.name} - Rs. {topping.price}</span>
       <div style={{ display: 'flex', gap: '5px' }}>
         <button onClick={() => addTopping(topping)} className="quantity-button">+</button>
         {topping.quantity}
         <button onClick={() => removeTopping(topping)} className="quantity-button">-</button>
       </div>
     </div>
   ))}
 
   {/* Total Price */}
   <p className="total-price" style={{color:'black'}}>Total Price: Rs {totalPrice.toFixed(2)}</p>
 
   {/* Payment Section */}
   <div className="payment-section">
     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
       <label style={{color:'black'}}>Customer Name:</label>
       <input 
         type="text" 
         value={customerName} 
         onChange={(e) => setCustomerName(e.target.value)} 
         className="customer-input"
         style={{ backgroundColor: 'white', width: '120px', border: '1px solid black', color: 'black' }}
       />
     </div>
     <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  <label style={{ color: 'black' }}>Any Voucher:</label>
  <input
    type="text"
    placeholder="Enter promo code"
    value={promoCode}
    onChange={(e) => setPromoCode(e.target.value)}
    onBlur={applyPromoCode}  // Call applyPromoCode when focus leaves the input
    className="payment-input"
    style={{ backgroundColor: 'white', width: '120px', border: '1px solid black', color: 'black' }}
    disabled={promoCodeApplied}  // Disable input if promo code is applied
  />
   {promoCodeApplied && (
  <button onClick={removeDiscount}>X</button>
)}

</div>

   

</div>


   </div>
 {/* Amount Paid and Remaining Calculation */}
 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <label style={{color: 'black'}}>Amount Paid:</label>
        <input 
          type="number" 
          value={amountPaidss} 
          onChange={(e) => setAmountPaidss(parseFloat(e.target.value) || 0)} 
          className="payment-input"
          style={{ backgroundColor: 'white', width: '120px', border: '1px solid black', color: 'black' }}
        />
      </div>

      {/* Display Remaining Amount */}
      <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
        <p style={{color:'black'}}>Remaining Balance: Rs {(totalPrice - amountPaidss).toFixed(2)}</p>
      </div>


    {/* Button for PDF generation */}

   <div style={{ display: 'flex' }}>
     <button onClick={generatePDF} className="add-to-cart-button" style={{ border:' 1px solid #C84347',marginTop: '20px',}}>
       {changePdf ? 'Generated Pdf' : 'Generate PDF'}
     </button>
     {pdfGenerated && (
       <button onClick={viewPDF} className="add-to-cart-button" style={{ borderLeft: '1px solid #C84347',marginTop: '20px', }}>View PDF</button>
     )}
   </div>
 
   {/* Add items to dining */}
   <button onClick={() => { setIsAddItemVisible(!isAddItemVisible); setScroll(true) }} className="add-to-cart-button" style={{ display: 'flex', marginTop: '5px',border:' 1px solid #C84347' }}>
     Also add Items to Dining?
   </button>
 
   {Scroll && <div style={{ fontSize: '12px', padding: '5px',color:'black' }}>Please scroll down and add table number</div>}
 
   <button onClick={() => setCart([])} className="add-to-cart-button" style={{ display: 'flex', marginTop: '5px' ,border:' 1px solid #C84347' }}>
     Clear Item
   </button>
   <button 
          onClick={handleCalculatorToggle} 
          className="add-to-cart-button"
          style={{  padding: '10px', border: '1px solid #C84347', color: '#c84347',marginTop:'5px' }}
        >
          Open Calculator
        </button>
        <button 
         
          className="add-to-cart-button"
          style={{  padding: '10px', border: '1px solid #C84347', color: '#c84347',marginBottom: '20px',marginTop:'5px' }}
        >
          Closing
        </button>
   {isAddItemVisible && (
     <div className="add-dining-form" style={{ display: 'flex', flexDirection: 'column', marginTrim: '5px', marginBottom: '50px' }}>
       <label style={{color:'black'}}>Table Number:</label>
       <input 
         type="text" 
         value={tableNumber} 
         onChange={(e) => setTableNumber(e.target.value)} 
       />
       <button onClick={handleAddToDining}>Submit</button>
     </div>
   )}
 </div>
  </div>
 
  </div>
         </div>
         {isCalculatorOpen && (
          <div className="calculator-popup unique-classname" style={styles.calculatorPopup}>
            <div className="calculator-content">
             
              <div className="calculator-display" style={styles.calcDisplay}>
                <input 
                  type="text" 
                  value={calcInput} 
                  readOnly 
                  className="calc-input" 
                  style={styles.calcInput}
                />
              </div>
              <div className="calculator-buttons" style={styles.calcButtons}>
                <button onClick={() => handleCalcInput('1')} style={styles.calcButton}>1</button>
                <button onClick={() => handleCalcInput('2')} style={styles.calcButton}>2</button>
                <button onClick={() => handleCalcInput('3')} style={styles.calcButton}>3</button>
                <button onClick={() => handleCalcInput('+')} style={styles.calcButton}>+</button>
                
                <button onClick={() => handleCalcInput('4')} style={styles.calcButton}>4</button>
                <button onClick={() => handleCalcInput('5')} style={styles.calcButton}>5</button>
                <button onClick={() => handleCalcInput('6')} style={styles.calcButton}>6</button>
                <button onClick={() => handleCalcInput('-')} style={styles.calcButton}>-</button>
                
                <button onClick={() => handleCalcInput('7')} style={styles.calcButton}>7</button>
                <button onClick={() => handleCalcInput('8')} style={styles.calcButton}>8</button>
                <button onClick={() => handleCalcInput('9')} style={styles.calcButton}>9</button>
                <button onClick={() => handleCalcInput('*')} style={styles.calcButton}>*</button>
                
                <button onClick={() => handleCalcInput('0')} style={styles.calcButton}>0</button>
                <button onClick={handleCalcClear} style={styles.calcButton}>C</button>
                <button onClick={handleCalcResult} style={styles.calcButton}>=</button>
                <button onClick={() => handleCalcInput('/')} style={styles.calcButton}>/</button>
              </div>
            </div>
            <button 
                onClick={handleCalculatorToggle} 
                className="close-calculator"
                style={{ border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer', color: '#c84347',width:'100%',paddingTop:'10px',marginTop:'10px',border:'1px solid silver' }}
              >
                Close
              </button>
          </div>
        )}
        </>

    );
};

export default Orders;




















const styles = {
    calculatorPopup: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      padding: '20px',
      zIndex: '1000',
      width: '250px',
    },
    calcDisplay: {
      textAlign: 'right',
      marginBottom: '10px',
    },
    calcInput: {
      width: '100%',
      padding: '10px',
      fontSize: '18px',
      textAlign: 'right',
      border: '1px solid #C84347',
      borderRadius: '5px',
      marginBottom: '10px',
    },
    calcButtons: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '10px',
    },
    calcButton: {
      padding: '15px',
      fontSize: '20px',
      backgroundColor: '#ffe5e5',
      border: '1px solid #c84347',
      color: '#c84347',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    closeCalculator: {
      position: 'absolute',
      top: '5px',
      right: '10px',
      fontSize: '20px',
      color: '#c84347',
    }
  };
  