import { useState } from "react";
import "../Component-6/Drawers.css";
import DrawersDetails from "./Pages-6/DrawersDetails";
import DrawersAccount from "./Pages-6/DrawersAccount";

const Drawers = () => {
  const [tables, setTables] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [newTable, setNewTable] = useState({
    id: "",
    description: "",
    defaultUser: "",
    status: "active",
  });
  const [selectedTable, setSelectedTable] = useState(null);
  const [activePage, setActivePage] = useState("Details");

  // Define available tabs
  const subNavTabs = ["Details", "Accounts"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (!newTable.id || !newTable.description || !newTable.defaultUser) return;
    setTables((prevTables) => [...prevTables, newTable]);
    setNewTable({ id: "", description: "", defaultUser: "", status: "active" });
    setFormVisible(false);
  };

  const handleTableClick = (table) => {
    setSelectedTable(table);
  };

  return (
    <div className="drawers-container">
      <aside className="drawers-sidebar">
        <div className="drawers-list-container">
          {tables.map((table, index) => (
            <div
              key={index}
              className={`drawers-item-container ${
                selectedTable?.id === table.id ? "active" : ""
              }`}
              onClick={() => handleTableClick(table)}
            >
              {table.description}
            </div>
          ))}
        </div>
        <button
          className="add-drawers-btn"
          onClick={() => setFormVisible(true)}
        >
          Add New Drawer
        </button>
      </aside>

      <main className="drawers-main-content-container">
        {formVisible && (
          <div className="drawers-form-overlay-container">
            <div className="drawers-form-container">
              <h3>New Drawer</h3>
              <input
                type="text"
                name="id"
                value={newTable.id}
                placeholder="ID"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="description"
                value={newTable.description}
                placeholder="Description"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="defaultUser"
                value={newTable.defaultUser}
                placeholder="Default User"
                onChange={handleInputChange}
                required
              />
              <select
                name="status"
                value={newTable.status}
                onChange={handleInputChange}
              >
                <option value="active">20-Available</option>
                <option value="inactive">10-Draft</option>
              </select>
              <div className="drawers-form-actions-container">
                <button
                  className="drawers-cancel-btn"
                  onClick={() => setFormVisible(false)}
                >
                  Cancel
                </button>
                <button className="drawers-save-btn" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedTable && (
          <>
            <nav className="drawers-navbar-container">
              <div className="drawers-navbar-content">
                <h2>{selectedTable.description}</h2>
                <div className="drawers-info-container">
                  <p>ID: {selectedTable.id}</p>

                  {/* <p>Default User: {selectedTable.defaultUser}</p> */}
                  <p>
                    Status:{" "}
                    <span
                      className={`drawers-status-indicator ${selectedTable.status}`}
                    >
                      {selectedTable.status === "active"
                        ? "20-Available"
                        : "10-Draft"}
                    </span>
                  </p>
                </div>
              </div>
            </nav>

            <div className="drawers-sub-navbar-container">
              {subNavTabs.map((tab) => (
                <div
                  key={tab}
                  className={`drawers-sub-nav-tab ${
                    activePage === tab ? "active" : ""
                  }`}
                  onClick={() => setActivePage(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>

            <div className="drawers-page-content-container">
              {activePage === "Details" && (
                <DrawersDetails tableId={selectedTable.id} tableStatus={selectedTable.status} tableDes={selectedTable.description} />
              )}
              {activePage === "Accounts" && (
                <DrawersAccount tableId={selectedTable.id} />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Drawers;
