import React, { useState, useEffect } from 'react';

const PaymentInterface = () => {
    const [selectedField, setSelectedField] = useState(null);
    const [values, setValues] = useState({
        notes: {
            '500.00': '0',
            '200.00': '0',
            '100.00': '0',
            '50.00': '0',
            '20.00': '0',
            '10.00': '0',
            '5.00': '0',
        },
        coins: {
            '2.00': '0',
            '1.00': '0',
            '0.50': '0',
            '0.20': '0',
            '0.10': '0',
            '0.05': '0',
            '0.02': '0',
            '0.01': '0',
        },
        payments: {
            'Master Card': '0',
            'VISA': '0',
            'American Express': '0',
            'EC': '0',
            'Voucher': '0',
            'Payment on credit': '0',
        }
    });

    useEffect(() => {
        const savedData = localStorage.getItem('paymentData');
        if (savedData) {
            setValues(JSON.parse(savedData));
        }
    }, []);

    const handleNumberClick = (value) => {
        if (!selectedField) return;

        const [section, key] = selectedField.split('|');
        setValues(prev => {
            const newValues = { ...prev };
            let currentValue = newValues[section][key];

            if (value === 'backspace') {
                currentValue = currentValue.slice(0, -1) || '0';
            } else if (value === 'clear') {
                currentValue = '0';
            } else if (value === '+/-') {
                currentValue = currentValue.startsWith('-') ?
                    currentValue.slice(1) :
                    '-' + currentValue;
            } else if (value === '.' && !currentValue.includes('.')) {
                currentValue += '.';
            } else if (value !== '.') {
                currentValue = currentValue === '0' ? value : currentValue + value;
            }

            newValues[section][key] = currentValue;
            return newValues;
        });
    };

    const handleSaveData = () => {
        localStorage.setItem('paymentData', JSON.stringify(values));
        alert('Data saved to localStorage');
    };

    const CurrencyRow = ({ section, label, value, denomination }) => (
        <div className="currency-row">
            <span className="currency-label">{label}</span>
            <input
                type="text"
                className={`currency-input ${selectedField === `${section}|${denomination}` ? 'currency-input-selected' : ''}`}
                value={value}
                readOnly
                onClick={() => setSelectedField(`${section}|${denomination}`)}
            />
            <span className="currency-equal">=</span>
            <input
                type="text"
                className="currency-result"
                value={(parseFloat(value || 0) * parseFloat(denomination)).toFixed(2)}
                readOnly
            />
        </div>
    );

    const NumberPad = () => (
        <div className="number-pad">
            {[
                '1', '2', '3', '+/-',
                '4', '5', '6', '',
                '7', '8', '9', '',
                '.', '0',
                'clear', 'confirm'
            ].map((num, index) => (
                <button
                    key={index}
                    className="number-pad-button"
                    onClick={() => {
                        if (num === 'clear') {
                            handleNumberClick('clear');
                        } else if (num === 'confirm') {
                            setSelectedField(null);
                        } else {
                            handleNumberClick(num);
                        }
                    }}
                >
                    {num}
                </button>
            ))}
        </div>
    );

    return (
        <div className="payment-interface">
            <div className="payment-section">
                <div className="payment-card">
                    <h3 className="payment-title">Notes</h3>
                    <div className="payment-content">
                        {Object.entries(values.notes).map(([denomination, value]) => (
                            <CurrencyRow
                                key={denomination}
                                section="notes"
                                label={`${denomination} PKR`}
                                value={value}
                                denomination={denomination}
                            />
                        ))}
                        <div className="payment-total">
                            {Object.entries(values.notes)
                                .reduce((sum, [denom, value]) =>
                                    sum + (parseFloat(value) * parseFloat(denom)), 0
                                ).toFixed(2)}
                        </div>
                    </div>
                </div>

                <div className="payment-card">
                    <h3 className="payment-title">Coins</h3>
                    <div className="payment-content">
                        {Object.entries(values.coins).map(([denomination, value]) => (
                            <CurrencyRow
                                key={denomination}
                                section="coins"
                                label={`${denomination} PKR`}
                                value={value}
                                denomination={denomination}
                            />
                        ))}
                        <div className="payment-total">
                            {Object.entries(values.coins)
                                .reduce((sum, [denom, value]) =>
                                    sum + (parseFloat(value) * parseFloat(denom)), 0
                                ).toFixed(2)}
                        </div>
                    </div>
                </div>

                <div className="payment-card">
                    <h3 className="payment-title">Card Payments and Vouchers</h3>
                    <table className="payment-table">
                        <tbody>
                            {Object.entries(values.payments).map(([method, value]) => (
                                <tr key={method}>
                                    <td className="payment-method">{method}</td>
                                    <td className="payment-value">
                                        <input
                                            type="text"
                                            className={`payment-input ${selectedField === `payments|${method}` ? 'payment-input-selected' : ''}`}
                                            value={value}
                                            readOnly
                                            onClick={() => setSelectedField(`payments|${method}`)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <button className="start-again-button" onClick={handleSaveData}>Add & Save</button>

            <div className="number-pad-container">
                <NumberPad />
            </div>

            <div className="save-button-container">
            </div>
        </div>
    );
};

export default PaymentInterface;
