import React, { useState, useEffect } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, Timestamp, setDoc } from 'firebase/firestore';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);

const PaymentMethodsTable = () => {
    const [paymentMethods, setPaymentMethods] = useState([]); 
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                const docRef = doc(firestore3, 'CreditCards', 'paymentMethodsDoc'); 
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setPaymentMethods(data.paymentMethods || []); 
                } else {
                    console.log('No payment methods found!');
                    setPaymentMethods([]); 
                }
            } catch (error) {
                console.error('Error fetching payment methods:', error);
                setPaymentMethods([]); 
            }
        };

        fetchPaymentMethods(); 
    }, []); 

    const handleCheckboxChange = (index, field) => {
        setPaymentMethods(methods => methods.map((method, i) =>
            i === index ? { ...method, [field]: !method[field] } : method
        ));
    };

    const handleAddRow = () => {
        const newRow = {
            code: '',
            typeCodeERP: '',
            description: '',
            logo: '',
            visible: true,
            active: true,
            ledger: ''
        };
        setPaymentMethods(prevMethods => [...prevMethods, newRow]);
    };

    const handleDeleteRow = (index) => {
        setPaymentMethods(prevMethods => prevMethods.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        try {
            const docRef = doc(firestore3, 'CreditCards', 'paymentMethodsDoc'); 

            await setDoc(docRef, {
                paymentMethods: paymentMethods,
                updatedAt: Timestamp.fromDate(new Date()) 
            }, { merge: true }); 

            alert('Payment methods saved successfully!');
        } catch (error) {
            console.error('Error saving payment methods:', error);
            alert('An error occurred while saving payment methods.');
        }
    };

    return (
        <div className="payment-methods-table-container">
            <table className="payment-methods-table">
                <thead>
                    <tr className="payment-methods-table-header">
                        <th className="payment-methods-table-column">Code</th>
                        <th className="payment-methods-table-column">Type code ERP</th>
                        <th className="payment-methods-table-column">Description</th>
                        <th className="payment-methods-table-column">Visible</th>
                        <th className="payment-methods-table-column">Active</th>
                        <th className="payment-methods-table-column">General ledger account</th>
                        <th className="payment-methods-table-column">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentMethods.map((method, index) => (
                        <tr key={method.code} className={index % 2 === 0 ? 'payment-methods-table-row-even' : 'payment-methods-table-row-odd'}>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="text"
                                    value={method.code}
                                    className="payment-methods-table-input"
                                    readOnly={!isEditing}
                                    onChange={e => {
                                        const updatedMethods = [...paymentMethods];
                                        updatedMethods[index].code = e.target.value;
                                        setPaymentMethods(updatedMethods);
                                    }}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="text"
                                    value={method.typeCodeERP}
                                    className="payment-methods-table-input"
                                    readOnly={!isEditing}
                                    onChange={e => {
                                        const updatedMethods = [...paymentMethods];
                                        updatedMethods[index].typeCodeERP = e.target.value;
                                        setPaymentMethods(updatedMethods);
                                    }}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="text"
                                    value={method.description}
                                    className="payment-methods-table-input"
                                    readOnly={!isEditing}
                                    onChange={e => {
                                        const updatedMethods = [...paymentMethods];
                                        updatedMethods[index].description = e.target.value;
                                        setPaymentMethods(updatedMethods);
                                    }}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="checkbox"
                                    checked={method.visible}
                                    onChange={() => handleCheckboxChange(index, 'visible')}
                                    className="payment-methods-table-checkbox"
                                    disabled={!isEditing}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="checkbox"
                                    checked={method.active}
                                    onChange={() => handleCheckboxChange(index, 'active')}
                                    className="payment-methods-table-checkbox"
                                    disabled={!isEditing}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                <input
                                    type="text"
                                    value={method.ledger}
                                    className="payment-methods-table-input"
                                    readOnly={!isEditing}
                                    onChange={e => {
                                        const updatedMethods = [...paymentMethods];
                                        updatedMethods[index].ledger = e.target.value;
                                        setPaymentMethods(updatedMethods);
                                    }}
                                />
                            </td>
                            <td className="payment-methods-table-cell">
                                {isEditing && (
                                    <button
                                        className="delete-btn"
                                        onClick={() => handleDeleteRow(index)}
                                    >
                                        Delete
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="checkout-bottom-bar">
                <button className="bottom-bar-action-btn">
                    <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                </button>
                <button
                    className="bottom-bar-action-btn edit-btn"
                    onClick={() => {
                        if (isEditing) {
                            handleSave(); 
                        }
                        setIsEditing(!isEditing);
                    }}
                >
                    {isEditing ? 'Save' : 'Edit'}
                </button>
                {isEditing ? (
                    <button className="bottom-bar-action-btn" onClick={handleAddRow}>
                        Add New Row
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default PaymentMethodsTable;
