import React, { useState, useEffect } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, Timestamp, getDocs, collection, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { useCheckoutContext } from '../../Context/Data';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom'; 

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore3 = getFirestore(checkoutApp);


const PaymentInterface = ({ openAmountWithCoupon, Tabs, Items, selectedTaxPercentage, setShowContextMenu3 }) => {
    const [selectedTab, setSelectedTab] = useState(Tabs);
    const [openAmount, ] = useState(String(openAmountWithCoupon));
    const [cashInput, setCashInput] = useState('0,00');
    const [change, setChange] = useState('0,00');
    const [card, setSelectedCard] = useState(null);
    const [terminalId, setTerminalId] = useState(null);

    const location = useLocation(); 
    console.log('amount is ', Tabs)
    const { setItems } = useCheckoutContext();


    useEffect(() => {
        const routeParts = location.pathname.split('/');
        const terminalIdFromRoute = routeParts[routeParts.length - 1];
        if (terminalIdFromRoute !== 'Terminal') {
            setTerminalId(terminalIdFromRoute);
        }
    }, [location.pathname]);


    const formatAsCurrency = (value) => {
        let numericValue = value.replace(/[^\d]/g, '');
        if (!numericValue) return '0,00';
        const amount = (parseInt(numericValue) / 100).toFixed(2);
        return amount.replace('.', ',');
    };

    useEffect(() => {
        if (selectedTab === 'CASH') {
            const totalAmount = parseFloat(openAmount.replace(',', '.'));
            const cashAmount = parseFloat(cashInput.replace(',', '.'));
            const changeAmount = (cashAmount - totalAmount).toFixed(2);
            setChange(changeAmount > 0 ? changeAmount.replace('.', ',') : '0,00');
        }
    }, [cashInput, openAmount, selectedTab]);

    const handleNumberClick = (value) => {
        if (value === ',' || selectedTab !== 'CASH') return;
        let currentNumeric = cashInput.replace(/[^\d]/g, '');
        if (value === '00' && currentNumeric === '0') {
            setCashInput('0,00');
            return;
        }
        currentNumeric = currentNumeric === '0' ? value : currentNumeric + value;
        setCashInput(formatAsCurrency(currentNumeric));
    };

    const handleBackspace = () => {
        if (selectedTab !== 'CASH') return;
        let currentNumeric = cashInput.replace(/[^\d]/g, '');
        currentNumeric = currentNumeric.slice(0, -1);
        setCashInput(formatAsCurrency(currentNumeric || '0'));
    };



    const printReceipt = (orderDetails) => {
        if (!orderDetails || !orderDetails.items || orderDetails.items.length === 0) {
            console.error("Invalid orderDetails:", orderDetails);
            alert("Error: Receipt data is missing.");
            return;
        }

        const receiptContent = `
        <html>
        <head>
            <title>Receipt</title>
            <style>
                body { font-family: Arial, sans-serif; width: 300px; }
                h2, p { text-align: center; }
                table { width: 100%; border-collapse: collapse; margin-top: 10px; }
                th, td { border: 1px solid #000; padding: 5px; text-align: left; }
                th { background-color: #f2f2f2; }
                .total-section { text-align: right; margin-top: 10px; width: 100%; }
                .footer { text-align: center; margin-top: 15px; font-size: 12px; }
            </style>
        </head>
        <body>
            <div>
                <h2>Hungrie - Order Receipt</h2>
                <p><strong>Location:</strong> DHA, Lahore, Punjab, Pakistan</p>
                <p><strong>Order ID:</strong> ${orderDetails.id || 'N/A'}</p>
                <p><strong>Date:</strong> ${new Date().toLocaleString()}</p>
                <hr>

                <table>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total</th>
                    </tr>
                    ${orderDetails.items.map(item => `
                        <tr>
                            <td>${item.title || 'Unknown Item'}</td>
                            <td>${item.quantity || 1}</td>
                            <td>PKR ${item.price || 0}</td>
                            <td>PKR ${(item.price * item.quantity).toFixed(2)}</td>
                        </tr>
                    `).join('')}
                </table>

                <table class="total-section">
                    <tr>
                        <td><strong>Subtotal:</strong></td>
                        <td>PKR ${orderDetails.subtotal || orderDetails.totalAmount || 0}</td>
                    </tr>
                    <tr>
                        <td><strong>Discount:</strong></td>
                        <td>PKR ${orderDetails.discount || 0}</td>
                    </tr>
                    <tr>
                        <td><strong>Tax:</strong></td>
                        <td>PKR ${orderDetails.tax || 0}</td>
                    </tr>
                    <tr>
                        <td><strong>Grand Total:</strong></td>
                        <td>PKR ${orderDetails.grandTotal || orderDetails.totalAmount || 0}</td>
                    </tr>
                    <tr>
                        <td><strong>Paid Status:</strong></td>
                        <td>${orderDetails.isPaid ? "Paid" : "Unpaid"}</td>
                    </tr>
                    <tr>
                        <td><strong>Payment Method:</strong></td>
                        <td>${orderDetails.paymentMethod || 'Cash'}</td>
                    </tr>
                </table>

                <hr>
                <p class="footer">Thank you for your order!</p>
            </div>
        </body>
        </html>
    `;

        const printFrame = document.createElement('iframe');
        printFrame.style.position = 'absolute';
        printFrame.style.top = '-10000px';
        document.body.appendChild(printFrame);

        const printDoc = printFrame.contentWindow || printFrame.contentDocument;
        printDoc.document.open();
        printDoc.document.write(receiptContent);
        printDoc.document.close();

        printDoc.focus();
        printDoc.print();

        setTimeout(() => document.body.removeChild(printFrame), 500);
    };





    const Confirmpayment = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore3, 'Orders'));
            const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const SequenceNumber = details.length > 0 ? details.length + 1 : 1;
            const pathname = window.location.pathname;
            let IsFrom = pathname === "/Terminal" ? "Take-Away" : pathname.startsWith("/Terminal/") ? "Dinning" : "Unknown";
            const email = localStorage.getItem('email') || "user@example.com";

            const newOrderId = `order_${SequenceNumber}_${Date.now()}`;
            const tableDocRef = doc(firestore3, 'Orders', newOrderId);

            const docSnapshot = await getDoc(tableDocRef);
            const orderData = {
                id: newOrderId, 
                From: IsFrom,
                SequenceNumber,
                AddedBy: email,
                createdAt: Timestamp.now(),
                items: Items || [], 
                status: 'processing',
                totalAmount: openAmountWithCoupon || 0,
                paymentMethod: 'Cash',
                orderNumber: Math.floor(10000 + Math.random() * 90000),

            };

            console.log("Order Data Before Printing:", orderData); 

            if (!terminalId) {
                if (docSnapshot.exists()) {
                    await updateDoc(tableDocRef, orderData);
                    toast.success('Order is placed');

                } else {
                    await setDoc(tableDocRef, orderData);
                    toast.success('Order is placed');
                    setItems([]);
                    setShowContextMenu3(false);
                }
            }
            if (terminalId) {
                setItems([]);
                setShowContextMenu3(false);
                await deleteDoc(doc(firestore3, 'Tables', terminalId));

            }
           

            printReceipt(orderData);

        } catch (error) {
            console.error("Error handling order:", error);
            alert("Error handling order.");
        }
    };

    const Confirmpayment2 = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore3, 'Orders'));
            const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const SequenceNumber = details.length > 0 ? details.length + 1 : 1;
            const pathname = window.location.pathname;
            let IsFrom = pathname === "/Terminal" ? "Take-Away" : pathname.startsWith("/Terminal/") ? "Dinning" : "Unknown";
            const email = localStorage.getItem('email') || "user@example.com";

            const newOrderId = `order_${SequenceNumber}_${Date.now()}`;
            const tableDocRef = doc(firestore3, 'Orders', newOrderId);

            const docSnapshot = await getDoc(tableDocRef);
            const orderData = {
                id: newOrderId, 
                From: IsFrom,
                SequenceNumber,
                AddedBy: email,
                createdAt: Timestamp.now(),
                items: Items || [], 
                status: 'processing',
                totalAmount: openAmountWithCoupon || 0, 
                paymentMethod: 'Card',
                orderNumber: Math.floor(10000 + Math.random() * 90000),

            };

            console.log("Order Data Before Printing:", orderData); 

            if (docSnapshot.exists()) {
                await updateDoc(tableDocRef, orderData);
                alert("Order updated in kitchen!");
            } else {
                await setDoc(tableDocRef, orderData);
                toast.success('Order is placed');
                setItems([]); 
                setShowContextMenu3(false);
            }

            printReceipt(orderData);

        } catch (error) {
            console.error("Error handling order:", error);
            alert("Error handling order.");
        }
    };
    const CashPaymentSection = () => (
        <>
            <Toaster /> 

            <div className="sec-cash-summary">
                <div className="sec-cash-row">
                    <span>Open amount</span>
                    <span>{openAmount} PKR</span>
                </div>
                <div className="sec-cash-row">
                    <span>GST</span>
                    <span>{selectedTaxPercentage}%</span>
                </div>
                <div className="sec-cash-row">
                    <span>Cash</span>
                    <span className="sec-cash-input">{cashInput} PKR</span>
                </div>
                <div className="sec-cash-row">
                    <span>Change</span>
                    <span className="sec-cash-change">{change} PKR</span>
                </div>
                <div className="sec-cash-row sec-cash-remaining">
                    <span>Remaining</span>
                    <span className="sec-cash-remaining-amount">
                        {((parseFloat(openAmount.replace(',', '.')) - parseFloat(cashInput.replace(',', '.'))) > 0
                            ? (parseFloat(openAmount.replace(',', '.')) - parseFloat(cashInput.replace(',', '.'))).toFixed(2).replace('.', ',')
                            : '0,00')} PKR
                    </span>
                </div>
            </div>

            <div className="sec-number-pad">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                    <button key={num} onClick={() => handleNumberClick(num.toString())} className="sec-number-button">
                        {num}
                    </button>
                ))}
                <button onClick={() => handleNumberClick('00')} className="sec-number-button">,00</button>
                <button onClick={() => handleNumberClick('0')} className="sec-number-button">0</button>
                <button className="sec-number-button" disabled>,</button>
                <div className="sec-action-buttons">
                    <button className="sec-confirm-button" onClick={Confirmpayment}>
                        ✔
                    </button>
                    <button onClick={handleBackspace} className="sec-backspace-button">
                        X
                    </button>
                </div>
            </div>
        </>
    );


    const CardPaymentSection = () => {
        const { creditCards, selectedTaxPercentage } = useCheckoutContext();
        const [cardData, setCardData] = useState([]);
        const [selectedCard, setSelectedCard] = useState(null); 

        useEffect(() => {
            if (!creditCards || creditCards.length === 0 || !creditCards[0].paymentMethods || creditCards[0].paymentMethods.length === 0) {
                return;
            }

            const activeCards = creditCards[0].paymentMethods
                .filter(card => card.active && card.visible)
                .map(card => ({
                    description: card.description,
                    code: card.code,
                }));

            setCardData(activeCards);
        }, [creditCards]);

        return (
            <div className="sec-card-selection">
                <div className="sec-cash-row">
                    <span>Open amount</span>
                    <span>{openAmount} PKR</span>
                </div>
                <div className="sec-cash-row">
                    <span>GST</span>
                    <span>{selectedTaxPercentage}%</span>
                </div>
                <h3 style={{ color: 'black' }}>Select a card</h3>
                <div className="sec-card-options">
                    {cardData.length > 0 ? (
                        cardData.map((card, index) => (
                            <div
                                key={index}
                                className={`sec-card-option ${selectedCard === card.code ? 'selected' : ''}`}
                                onClick={() => setSelectedCard(card.code)} 
                            >
                                {card.description}
                            </div>
                        ))
                    ) : (
                        <p>No cards available</p>
                    )}
                </div>

                {selectedCard && (
                    <div className="sec-payment-btn" >
                        <button className="sec-pay-button" onClick={Confirmpayment2 }>Pay</button>
                    </div>
                )}
            </div>
        );
    };







    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        setSelectedCard(null);  
    };

    return (
        <div className="sec-payment-container">
            <div className="sec-tabs">
                <button
                    onClick={() => handleTabChange('CASH')}
                    className={`sec-tab ${selectedTab === 'CASH' ? 'active' : ''}`}
                >
                    Cash
                </button>
                <button
                    onClick={() => handleTabChange('CARD')}
                    className={`sec-tab ${selectedTab === 'CARD' ? 'active' : ''}`}
                >
                    Card
                </button>
            </div>

            {selectedTab === 'CASH' && <CashPaymentSection />}
            {selectedTab === 'CARD' && <CardPaymentSection />}
        </div>
    );
};



export default PaymentInterface;
