import "../Pages-4/Signature.css";

const Signature = () => {
  return (
    <div className="h-signature-container">
      <h2>Signature:</h2>
    </div>
  );
};

export default Signature;
