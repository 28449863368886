import React, { useState } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';  // Import the uuid library to generate unique IDs

// import './AddItems.css'; // Import a CSS file for styling
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
  };
  
  // Initialize Firebase app for checkout with a unique name
  const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
  const firestore2 = getFirestore(checkoutApp);
const AddItems = () => {
    const categories = [ 'Pasta',  'Desserts', 'Hot Coffee',  'Peri Peri', 'Fried', 'Appetizers', 'Pizza', 'Sandwiches','Beverages','Steaks' ,'Burgers/Wrpas'];

    const defaultSizes = [
        { size: "small(7)", price: 675 },
        { size: "medium(10)", price: 1045 },
        { size: "large(13)", price: 1455 },
        { size: "x-large(16)", price: 1845 },
    ];
   
    // State for menu items
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [category, setCategory] = useState(categories[0]);
    const [isMenu, setIsMenu] = useState(false);
    const [isEXP, setisEXP] = useState(false);
    const [isEXP2, setIsEXP2] = useState(false);
    const [isPopular, setIsPopular] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [promoCategory, setPromoCategory] = useState(categories[0]); // New category state
    const [promoStartDateTime, setPromoStartDateTime] = useState('');
    const [promoEndDateTime, setPromoEndDateTime] = useState('');
    const [dealImageURL, setDealImageURL] = useState('');  // State for image URL or uploaded image URL
    const [image2, setImage2] = useState('');  // State for storing the image URL (not the file itself)

    // State for deals
    const [dealTitle, setDealTitle] = useState('');
    const [dealPrice, setDealPrice] = useState('');
    const [dealImage, setDealImage] = useState('');
    const [miniData, setMiniData] = useState(['', '', '', '', '', '']);
    const [crustOptions, setCrustOptions] = useState([]);
    const [changeText, setchangeText] = useState(true);
    const [changeText2, setchangeText2] = useState(true);
    const [changeText3, setchangeText3] = useState(true);

    // State for promo codes
    const [promoCode, setPromoCode] = useState('');
    const [promoPercent, setPromoPercent] = useState('');
    const handleCrustChange = (e, crustType) => {
        const newCrustOptions = { ...crustOptions };
        newCrustOptions[crustType] = e.target.value; // Dynamically update the crust price based on the crust type
        setCrustOptions(newCrustOptions);
    };
    
  
    const handleSubmit = async (e) => {
        setchangeText2(false);
        e.preventDefault();
    
        // Ensure that an image is selected
        if (image) {
            // Get a reference to Firebase Storage
            const storage = getStorage();
            const storageRef = ref(storage, 'menu-images/' + image.name); // Use the file name as a reference in Firebase Storage
        
            try {
                // Upload the image to Firebase Storage
                await uploadBytes(storageRef, image);
    
                // Get the download URL of the uploaded image
                const imageUrl = await getDownloadURL(storageRef);
                const uniqueId = uuidv4(); // Generates a unique ID using the uuid library

                // Prepare the new menu item object to send to Firestore
                const newItem = {
                    uniqueId,
                    title,
                    price: parseFloat(price),
                    image: imageUrl, // Store the image URL after upload
                    category,
                    isMenu,
                    isPopular,
                    sizes: category === 'Pizza' ? sizes : [],
    
                    // Add the crust prices, if it's a pizza
                    Crust: category === 'Pizza' ? {
                        'Pan Crust': crustOptions['Pan Crust'] || '',
                        'Deep Crust': crustOptions['Deep Crust'] || '',
                        'Stuffed Crust Cheesy': crustOptions['Stuffed Crust Cheesy'] || '',
                        'Stuffed Crust Special': crustOptions['Stuffed Crust Special'] || '',

                    } : [],
                };
    
                // Add the menu source (Hungrie or Hungrie-Exp)
                newItem.MenuFrom = isEXP ? 'Hungrie-Exp' : 'Hungrie';
    
                // Save the item data to Firestore
                await setDoc(doc(firestore2, 'Items', title), newItem);
    
                // If the item is marked as popular, save it to the popular items collection as well (optional)
                if (isPopular) {
                    // await setDoc(doc(firestore2, 'PopularItems', title), newItem);
                }
    
                alert('Item added successfully!');
                setchangeText2(true);
                setTitle('');                // Reset the title field
                setPrice('');                // Reset the price field
                setImage(null);              // Reset the image state
                setCategory(categories[0]);  // Reset the category to the first option
                setIsMenu(false);            // Reset the isMenu flag
                setIsPopular(false);         // Reset the isPopular flag
                setisEXP(false);             // Reset the isEXP flag
                setSizes([]);                // Reset the sizes array (only relevant for pizzas)
                setCrustOptions({});         // Reset crust options after submission (empty object)
            } catch (error) {
                setchangeText2(true);
                console.error('Error uploading image:', error);
                alert('Error uploading image. Please try again.');
            }
        } else {
            setchangeText2(true);
            alert('Please upload an image before submitting!');
        }
    
        // Reset the form for menu items after successful submission
       
    };
    
    

    const handleDealSubmit = async (e) => {
        setchangeText(false)
        e.preventDefault();
    
        // Access the file input directly
        const fileInput = e.target.querySelector('input[type="file"]'); // Get the file input element
        const file = fileInput?.files[0];  // Safely access the first file
    
        if (!file) {
            alert("Please select an image before submitting.");
            return;
        }
    
        // Upload the image to Firebase storage
        let uploadedImageURL = '';
        try {
            // Step 1: Create a reference to Firebase storage
            const storage = getStorage();
            const storageRef = ref(storage, 'images/' + file.name);  // Path where image will be stored
    
            // Step 2: Upload the file to Firebase storage
            await uploadBytes(storageRef, file);
    
            // Step 3: Get the download URL for the uploaded image
            uploadedImageURL = await getDownloadURL(storageRef);
        } catch (error) {
            console.error("Error uploading image: ", error);
            alert("Error uploading image.");
            return;  // Exit the function if image upload fails
        }
    
        // Prepare the miniData as individual strings
        const miniData1 = miniData[0] || '';  // "10x Pieces Chicken"
        const miniData2 = miniData[1] || '';  // "3x Reg. Fries"
        const miniData3 = miniData[2] || '';  // "2x Fillet Burger"
        const miniData4 = miniData[3] || '';  // "1.5 Ltr. Bottle"
        const miniData5 = miniData[4] || '';  // "1.5 Ltr. Bottle"
        const miniData6 = miniData[5] || '';  // "1.5 Ltr. Bottle"
        // Create the new deal object with the correct structure
        const uniqueId = uuidv4(); // Generates a unique ID using the uuid library

        const newDeal = {
            uniqueId: uniqueId,
            image: uploadedImageURL,  // Store the image URL from the upload
            miniData: miniData1,      // Store the first miniData as a string
            miniData1: miniData2,     // Store the second miniData as a string
            miniData2: miniData3,     // Store the third miniData as a string
            miniData3: miniData4,     // Store the fourth miniData as a string
            miniData4: miniData5, 
            miniData5: miniData6, 
            price: parseFloat(dealPrice),  // Convert price to a number
            title: dealTitle,  // The title of the deal
        };
    
        try {
            // Save the deal data to Firestore
            await setDoc(doc(firestore2, 'Deals', dealTitle), newDeal);
            alert('Deal added successfully!');
            setchangeText(true)

        } catch (error) {
            setchangeText(true)

            console.error('Error adding deal:', error);
            alert('Error adding deal. Please try again.');
        }
    
        // Reset form after submission
        setDealTitle('');
        setDealPrice('');
        setDealImageURL('');  // Reset the image URL
        setMiniData(['', '', '', '','','']);  // Reset miniData
        setIsEXP2(false);  // Reset checkbox state
    };
    
    
    

    const handlePromoSubmit = async (e) => {
        setchangeText3(false)
        e.preventDefault();
        const uniqueId = uuidv4(); // Generates a unique ID using the uuid library

        const newPromo = {
            uniqueId: uniqueId,
            code: promoCode,
            percent: parseFloat(promoPercent),
            // category: promoCategory,  // Include category in promo data
            startDateTime: promoStartDateTime,  // Full start datetime
            endDateTime: promoEndDateTime,      // Full end datetime
        };
    
        try {
            await setDoc(doc(firestore, 'Promo', promoCode), newPromo);
            alert('Promo added successfully!');
            setchangeText3(true)

        } catch (error) {
            setchangeText3(true)

            console.error('Error adding promo:', error);
            alert('Error adding promo. Please try again.');
        }
    
        // Reset the form for promos
        setPromoCode('');
        setPromoPercent('');
        setPromoCategory(categories[0]);
        setPromoStartDateTime('');
        setPromoEndDateTime('');
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);
        if (selectedCategory === 'Pizza') {
            setSizes(defaultSizes.map(size => ({ ...size, price: '' })));
        } else {
            setSizes([]);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            setImage(file); // Store the file in the state
            // alert('Image selected!');
        }
    };
    const handleFileChange2 = (e) => {
        // Just set the file preview or handle any file-related UI changes here if needed
        const file = e.target.files[0];
        if (file) {
            setDealImageURL(file.name);  // You can store the file name or handle any preview display
        }
    };
    
    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
      
       <div className='for-adding'>
       <div>
       <h2 style={{ color: '#c84347' }}>Add New Item</h2>
            <form onSubmit={handleSubmit} style={formStyle}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Title:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                {[
    'Pasta', 'Desserts', 'Hot Coffee', 'Peri Peri', 'Fried',
    'Appetizers', 'Sandwiches', 'Beverages', 'Steaks', 'Burgers/Wrpas'
].includes(category) && (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Price:</label>
        <input 
            type="number" 
            value={price} 
            onChange={(e) => setPrice(e.target.value)} 
            // required 
            style={inputStyle} 
        />
    </div>
)}

                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Image :</label>
                    <input 
                        type="file" 
                      
                        onChange={handleFileChange} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Category:</label>
                    <select 
                        value={category} 
                        onChange={handleCategoryChange}
                        style={inputStyle}
                    >
                        {categories.map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>
                {category === 'Pizza' && (
                    <div  style={{display:'flex',flexDirection:'column'}}>
                        <h3 style={{color:'black'}}>Sizes</h3>
                        {sizes.map((size, index) => (
                            <div key={index} style={{display:'flex',flexDirection:'column'}}>
                                <label>Size: {size.size}</label>
                                <label style={{paddingTop:'10px'}}>Price:</label>
                                <input 
                                    type="number" 
                                    value={size.price} 
                                    onChange={(e) => {
                                        const newSizes = [...sizes];
                                        newSizes[index].price = e.target.value;
                                        setSizes(newSizes);
                                    }} 
                                    required 
                                    style={inputStyle} 
                                />
                            </div>
                        ))}
                    </div>
                )}
{category === 'Pizza' && (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3 style={{ color: 'black' }}>Crust Options</h3>
        {['Pan Crust', 'Deep Crust', 'Stuffed Crust Cheesy','Stuffed Crust Special'].map((crustType, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                <label>{crustType} Price:</label>
                <input
                    type="number"
                    value={crustOptions[crustType] || ''} // Binding to the specific crust type price
                    onChange={(e) => handleCrustChange(e, crustType)} // Pass the crustType for the change handler
                    placeholder={`${crustType} price`}
                    required
                    style={inputStyle}
                />
            </div>
        ))}
        <button
            type="button"
            onClick={() => setCrustOptions({})} // Reset crust options to default (empty object)
            style={buttonStyle}
        >
            Reset Crust Prices
        </button>
    </div>
)}

                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isMenu} 
                            onChange={(e) => setIsMenu(e.target.checked)} 
                        />
                        Add to Menu Items
                    </label>
                </div>
                <div  style={{display:'flex',flexDirection:'column',marginTop:'5px',marginBottom:'5px'}}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isPopular} 
                            onChange={(e) => setIsPopular(e.target.checked)} 
                        />
                        Add to Popular Items
                    </label>
                </div>
                <div  style={{display:'flex',flexDirection:'column',marginTop:'20px'}}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isEXP} 
                            onChange={(e) => setisEXP(e.target.checked)} 
                        />
                        Add to Hungrie EXP
                    </label>
                </div>
                <button type="submit" style={buttonStyle}>
                    {
                        changeText2?
                        'Add Item'
                        :
                        'Adding...'
                    }
                    </button>
            </form>


            <h2 style={{ color: '#c84347' }}>Add New Promo Code</h2>
            <form onSubmit={handlePromoSubmit} style={formStyle}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Promo Code:</label>
            <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Discount Percentage:</label>
            <input
                type="number"
                value={promoPercent}
                onChange={(e) => setPromoPercent(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Category:</label>
            <select
                value={promoCategory}
                onChange={(e) => setPromoCategory(e.target.value)}
                style={inputStyle}
            >
                {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                ))}
            </select>
        </div> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Start Date and Time:</label>
            <input
                type="datetime-local"
                value={promoStartDateTime}
                onChange={(e) => setPromoStartDateTime(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>End Date and Time:</label>
            <input
                type="datetime-local"
                value={promoEndDateTime}
                onChange={(e) => setPromoEndDateTime(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <button type="submit" style={buttonStyle}>
            
        {
                        changeText3?
                        'Add Promo'
                        :
                        'Adding...'
                    }
            </button>
    </form>

</div>
<div>
<h2 style={{ color: '#c84347' }}>Add New Deal</h2>
<form onSubmit={handleDealSubmit} style={formStyle}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Deal Title:</label>
        <input
            type="text"
            value={dealTitle}
            onChange={(e) => setDealTitle(e.target.value)}
            required
            style={inputStyle}
        />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Price:</label>
        <input
            type="number"
            value={dealPrice}
            onChange={(e) => setDealPrice(e.target.value)}
            required
            style={inputStyle}
        />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Image URL or Upload Image:</label>
        <input
            type="text"
            value={dealImageURL}
            onChange={(e) => setDealImageURL(e.target.value)}
            placeholder="Enter Image URL"
            style={inputStyle}
        />
        {/* Option to input image URL */}

        {/* Option to upload an image file */}
        <input
            type="file"
            onChange={handleFileChange2}
            accept="image/*"
            style={inputStyle}
        />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 1:</label>
        <input
            type="text"
            value={miniData[0]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[0] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 2:</label>
        <input
            type="text"
            value={miniData[1]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[1] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 3:</label>
        <input
            type="text"
            value={miniData[2]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[2] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 4:</label>
        <input
            type="text"
            value={miniData[3]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[3] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 5:</label>
        <input
            type="text"
            value={miniData[4]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[4] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Item 6 :</label>
        <input
            type="text"
            value={miniData[5]}
            onChange={(e) => {
                const newMiniData = [...miniData];
                newMiniData[5] = e.target.value;
                setMiniData(newMiniData);
            }}
            style={inputStyle}
        />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <label>
            <input
                type="checkbox"
                checked={isEXP2}
                onChange={(e) => setIsEXP2(e.target.checked)}
            />
            Add to Hungrie EXP
        </label>
    </div>

    <button type="submit" style={buttonStyle}>
        {changeText?
            ' Add Deal'
            :
            ' Adding...'
}
       </button>
</form>

   </div>
        </div>     

           
         

           
      

              </div>
    );
}

// Styling objects
const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: '20px',
    
};

const inputStyle = {
    width: '98%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop:'10px',
};

const buttonStyle = {
    marginTop:'15px',
    backgroundColor: '#c84347',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

// CSS can be placed in a separate file (e.g., AddItems.css) for additional styles.

export default AddItems
