import "../Pages-4/PaymentItems.css";

const PaymentItems = () => {
  const paymentItems = [
    {
      position: 1,
      paymentMethod: "Credit Card",
      creditCardType: "Visa",
      amountInPaymentCurrency: 100,
      tipAmount: 5,
      paymentTerm: "Immediate",
      exchangeRate: 175,
      amountInPKR: 17500,
      note: "Paid in full",
    },
    {
      position: 2,
      paymentMethod: "Cash",
      creditCardType: "",
      amountInPaymentCurrency: 200,
      tipAmount: 10,
      paymentTerm: "Immediate",
      exchangeRate: 1,
      amountInPKR: 200,
      note: "Paid in full",
    },
    {
      position: 3,
      paymentMethod: "Credit Card",
      creditCardType: "MasterCard",
      amountInPaymentCurrency: 150,
      tipAmount: 7,
      paymentTerm: "On delivery",
      exchangeRate: 175,
      amountInPKR: 26250,
      note: "Pending approval",
    },
    {
      position: 4,
      paymentMethod: "Bank Transfer",
      creditCardType: "",
      amountInPaymentCurrency: 500,
      tipAmount: 0,
      paymentTerm: "On delivery",
      exchangeRate: 1,
      amountInPKR: 500,
      note: "Pending",
    },
  ];

  return (
    <div className="h-payment-items-container">
      <table className="h-payment-items-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Payment Method</th>
            <th>Credit Card Type</th>
            <th>Amount in Payment Currency</th>
            <th>Tip Amount</th>
            <th>Payment Term</th>
            <th>Exchange Rate</th>
            <th>Amount in PKR</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {paymentItems.map((item, index) => (
            <tr key={index}>
              <td>{item.position}</td>
              <td>{item.paymentMethod}</td>
              <td>{item.creditCardType || "N/A"}</td>
              <td>${item.amountInPaymentCurrency}</td>
              <td>${item.tipAmount}</td>
              <td>{item.paymentTerm}</td>
              <td>{item.exchangeRate}</td>
              <td>${item.amountInPKR}</td>
              <td>{item.note}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentItems;
