import { useState } from "react";
import "../Component-5/TableArea.css";
import Details from "./Pages-5/Details";
import OrgUnits from "./Pages-5/OrgUnits";

const TableArea = () => {
  const [tables, setTables] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [newTable, setNewTable] = useState({
    id: "",
    name: "",
    status: "active",
  });
  const [selectedTable, setSelectedTable] = useState(null);
  const [activePage, setActivePage] = useState("Details");

  // Define available tabs
  const subNavTabs = ["Details", "Org Units"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (!newTable.name || !newTable.id) return;
    setTables((prevTables) => [...prevTables, newTable]);
    setNewTable({ id: "", name: "", status: "active" });
    setFormVisible(false);
  };

  const handleTableClick = (table) => {
    setSelectedTable(table);
  };

  return (
    <div className="h-table-area-wrapper">
      <aside className="h-sidebar-container">
        <div className="h-table-list-container">
          {tables.map((table, index) => (
            <div
              key={index}
              className={`h-table-item-container ${
                selectedTable?.id === table.id ? "active" : ""
              }`}
              onClick={() => handleTableClick(table)}
            >
              {table.name}
            </div>
          ))}
        </div>
        <button className="h-add-table-btn" onClick={() => setFormVisible(true)}>
          Add New Table
        </button>
      </aside>

      <main className="h-main-content-container">
        {formVisible && (
          <div className="h-form-overlay-container">
            <div className="h-form-container">
              <h3>Create New Table Area</h3>
              <input
                type="text"
                name="id"
                value={newTable.id}
                placeholder="ID"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="name"
                value={newTable.name}
                placeholder="Name"
                onChange={handleInputChange}
                required
              />
              <select
                name="status"
                value={newTable.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="draft">Draft</option>
              </select>
              <div className="h-form-actions-container">
                <button
                  className="h-cancel-btn"
                  onClick={() => setFormVisible(false)}
                >
                  Cancel
                </button>
                <button className="h-save-btn" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedTable && (
          <>
            <nav className="h-navbar-container">
              <div className="h-navbar-content">
                <h2>{selectedTable.name}</h2>
                <div className="h-table-info-container">
                  <p>ID: {selectedTable.id}</p>
                  <p className={`status-indicator ${selectedTable.status}`}>
                    Status: {selectedTable.status}
                  </p>
                </div>
              </div>
            </nav>

            <div className="h-sub-navbar-container">
              {subNavTabs.map((tab) => (
                <div
                  key={tab}
                  className={`h-sub-nav-tab ${
                    activePage === tab ? "active" : ""
                  }`}
                  onClick={() => setActivePage(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>

            <div className="h-page-content-container">
              {activePage === "Details" && (
                <Details tableId={selectedTable.id} />
              )}
              {activePage === "Org Units" && (
                <OrgUnits tableId={selectedTable.id} />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default TableArea;
