import { useState } from "react";
import "./ReceiptDetails.css";
import Details from "./Pages-4/Details";
import SalesItems from "./Pages-4/SalesItems";
import TaxItems from "./Pages-4/TaxItems";
import PaymentItems from "./Pages-4/PaymentItems";
import Orders from "./Pages-4/Orders";
import Coupons from "./Pages-4/Coupons";
import Discounts from "./Pages-4/Discounts";
import FeesandServiceCharges from "./Pages-4/FeesandServiceCharges";
import DispatchStates from "./Pages-4/DispatchStates";
import ExportStates from "./Pages-4/ExportStates";
import AdditionalFields from "./Pages-4/AdditionalFields";
import Signature from "./Pages-4/Signature";

// Sub-navbar pages (components)

const ReceiptDetails = ({ receiptId, status }) => {
  const [activePage, setActivePage] = useState("Details"); // State to track active page

  const subNavTabs = [
    "Details",
    "Sales Items",
    "Tax Items",
    "Payment Items",
    "Orders",
    "Coupons",
    "Discounts",
    "Fees and Service Charges",
    "Signature",
    "Dispatch States",
    "Export States",
    "Additional Fields",
  ];

  return (
    <div className="h-receipt-details-container">
      {/* Main Navbar */}
      <div className="h-navbar">
        <h1>Receipt Management</h1>
      </div>

      {/* Header with Receipt ID and Status */}
      <div className="h-receipt-header">
        <h2>Receipt Details</h2>
        <p className="h-receipt-id">
          Receipt ID: <strong>{receiptId}</strong>
        </p>
        <p className="h-receipt-status">
          Status: <strong>{status}</strong>
        </p>
      </div>

      {/* Sub-navbar */}
      <div className="h-sub-navbar">
        {subNavTabs.map((tab) => (
          <div
            key={tab}
            className={`sub-nav-tab ${activePage === tab ? "active" : ""}`}
            onClick={() => setActivePage(tab)} // Update activePage state on click
          >
            {tab}
          </div>
        ))}
      </div>

      {/* Conditional Rendering of Pages */}
      <div className="h-page-content">
        {activePage === "Details" && <Details receiptId={receiptId} />}
        {activePage === "Sales Items" && <SalesItems />}
        {activePage === "Tax Items" && <TaxItems />}
        {activePage === "Payment Items" && <PaymentItems />}
        {activePage === "Orders" && <Orders />}
        {activePage === "Coupons" && <Coupons />}
        {activePage === "Discounts" && <Discounts />}
        {activePage === "Fees and Service Charges" && <FeesandServiceCharges />}
        {activePage === "Signature" && <Signature />}
        {activePage === "Dispatch States" && <DispatchStates />}
        {activePage === "Export States" && <ExportStates />}
        {activePage === "Additional Fields" && <AdditionalFields />}
      </div>
    </div>
  );
};

export default ReceiptDetails;
