import React, { useState } from 'react';
import { useCheckoutContext } from '../../../Context/Data';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

function ParentComponent({  }) {
    return (
        <div>
            <TaxRates />
            <reciptDiscounty />
            <ChildComponentThree />
        </div>
    );
}

function TaxRates({ setActivePopup }) {
    const { taxRates } = useCheckoutContext();
    const [taxData, setTaxData] = useState([]);
    const { setSelectedTaxPercentage, selectedTaxPercentage } = useCheckoutContext();
    console.log(()=>selectedTaxPercentage)
    useEffect(() => {
        if (!taxRates || taxRates.length === 0) return;

        const activeTaxes = taxRates[0].rates
            .filter(rate => rate.active && rate.percentage !== -1)
            .map(rate => ({
                percentage: rate.percentage,
                taxChar: rate.taxChar,
            }));

        setTaxData(activeTaxes);
    }, [taxRates]);

    return (
        <>
            <Toaster/>

            <div className='main-tax-rates'>
                {taxData.length > 0
                    ? taxData.map((tax, index) => (
                        <div key={index} className='tax-rates' onClick={() => { setSelectedTaxPercentage(tax.percentage); setActivePopup(null); toast.success('Updated') }}>
                            {`${tax.percentage}% Sales Tax - ${tax.taxChar} (${tax.percentage}%)`}
                        </div>
                    ))
                    : 'No active tax rates'}
            </div>
        </>
    );
}



function DiscountRecipit({ setActivePopup }) {
    const { openAmountWithCoupon, Coupons, setCoupons } = useCheckoutContext(); 

    const [amount, setAmount] = useState(openAmountWithCoupon || 7950); 
    const [discountType, setDiscountType] = useState("percentage");
    const [discount, setDiscount] = useState(Coupons || ""); 
    const [numpadInput, setNumpadInput] = useState("");

    const calculateNewAmount = () => {
        const baseAmount = parseFloat(amount) || 0;
        const discountValue = parseFloat(discount) || 0;

        if (discountType === "percentage") {
            return (baseAmount - (baseAmount * discountValue) / 100).toFixed(2); 
        } else {
            return (baseAmount - discountValue).toFixed(2); 
        }
    };

    const handleNumpadClick = (value) => {
        if (value === "backspace") {
            setNumpadInput((prev) => prev.slice(0, -1));
        } else if (value === "enter") {
            if (numpadInput) {
                let discountValue = parseFloat(numpadInput);

                if (discountType === "absolute") {
                    const percentageDiscount = (discountValue / amount) * 100; 
                    setDiscount(percentageDiscount.toFixed(2)); 
                    setCoupons(percentageDiscount.toFixed(2));  
                } else {
                    setDiscount(discountValue.toFixed(2)); 
                    setCoupons(discountValue.toFixed(2));  
                }
                toast.success('Discount Applied')
                setActivePopup(null)
                setNumpadInput(""); 
            }
        } else {
            setNumpadInput((prev) => prev + value);
        }
    };

    const NumberButton = ({ value }) => (
        <button className="discount-numpad-button" onClick={() => handleNumpadClick(value)}>
            {value}
        </button>
    );

    useEffect(() => {
        setCoupons(discount);
    }, [discount, setCoupons]);

    return (
        <div className="discount-card">
            <Toaster />

            <div className="discount-card-content">
                <div className="discount-form-group">
                    <label className="discount-label">Discountable Amount</label>
                    <input value={`${amount} PKR`} readOnly className="discount-input discount-text-right" />
                </div>

                <div className="discount-tabs">
                    <button
                        className={`discount-tab-button ${discountType === "absolute" ? "discount-tab-active" : ""}`}
                        onClick={() => setDiscountType("absolute")}
                    >
                        ABSOLUTE DISCOUNT
                    </button>
                    <button
                        className={`discount-tab-button ${discountType === "percentage" ? "discount-tab-active" : ""}`}
                        onClick={() => setDiscountType("percentage")}
                    >
                        PERCENTAGE DISCOUNT
                    </button>
                </div>

                <div className="discount-form-group">
                    <label className="discount-label">Discount</label>
                    <input
                        value={numpadInput || (discount ? `${discount}${discountType === "percentage" ? " %" : ""}` : "")}
                        readOnly
                        className="discount-input discount-text-right"
                    />
                </div>



                <div className="discount-form-group">
                    <label className="discount-label">Discount Purpose</label>
                    <select className="discount-select">
                        <option value="general">General</option>
                        <option value="promotion">Promotion</option>
                        <option value="loyalty">Loyalty</option>
                    </select>
                </div>

                <div className="discount-numpad">
                    <NumberButton value="1" />
                    <NumberButton value="2" />
                    <NumberButton value="3" />
                    <button className="discount-numpad-button discount-numpad-backspace" onClick={() => handleNumpadClick("backspace")}>
                        ←
                    </button>
                    <NumberButton value="4" />
                    <NumberButton value="5" />
                    <NumberButton value="6" />
                    <NumberButton value="7" />
                    <NumberButton value="8" />
                    <NumberButton value="9" />
                    <button className="discount-numpad-button discount-numpad-enter" onClick={() => handleNumpadClick("enter")}>
                        ✓
                    </button>
                    <NumberButton value=".00" />
                    <NumberButton value="0" />
                    <NumberButton value="." />
                    <button style={{ backgroundColor: '#c84347', color: 'white', border: 'none', borderRadius:'5px' }} onClick={() => { setCoupons(0); toast.success('Discount Removed'); setActivePopup(null) }} >
                        cancel all applied discount
                    </button>
                </div>
            </div>
        </div>
    );
}



function TaxRatesDefault({ setActivePopup }) {
    const { taxRates } = useCheckoutContext();
    const [taxData, setTaxData] = useState([]);
    const { setSelectedTaxPercentage, selectedTaxPercentage } = useCheckoutContext();
    console.log(() => selectedTaxPercentage)
    useEffect(() => {
        if (!taxRates || taxRates.length === 0) return;

        const activeTaxes = taxRates[0].rates
            .filter(rate => rate.isDefault && rate.percentage !== -1)
            .map(rate => ({
                percentage: rate.percentage,
                taxChar: rate.taxChar,
            }));

        setTaxData(activeTaxes);
    }, [taxRates]);

    return (
        <>
            <Toaster />

            <div className='main-tax-rates'>
                {taxData.length > 0
                    ? taxData.map((tax, index) => (
                        <div key={index} className='tax-rates' onClick={() => { setSelectedTaxPercentage(tax.percentage); setActivePopup(null); toast.success('Updated') }}>
                            {`${tax.percentage}% Sales Tax - ${tax.taxChar} (${tax.percentage}%)`}
                        </div>
                    ))
                    : 'No active tax rates'}
            </div>
        </>
    );
}






const ReducedTax = ({ setActivePopup }) => {
    const { setSelectedTaxPercentage } = useCheckoutContext();

    const handleTaxChange = (taxRate) => {
        setSelectedTaxPercentage(taxRate);
        toast.success('Updated')
        setActivePopup(null)
    };

    return (
        <>
            <Toaster /> 

            <div className='main-tax-rates'>
                <div className='tax-rates' onClick={() => handleTaxChange(5)}>
                    Punjab - ( 5% )
                </div>
                <div className='tax-rates' onClick={() => handleTaxChange(5)}>
                    Khyber Pakhtunkhwa (KPK) - ( 5% )
                </div>
                <div className='tax-rates' onClick={() => handleTaxChange(13)}>
                    Sindh - ( 13% )
                </div>
                <div className='tax-rates' onClick={() => handleTaxChange(5)}>
                    Islamabad (ICT) - ( 5% )
                </div>
                <div className='tax-rates' onClick={() => handleTaxChange(5)}>
                    Baloshistan (ICT) - ( 5% )
                </div>
            </div>
        </>
    );
};









function ChildComponentThree() {
    return <div>Component 333333</div>;
}

export { ParentComponent, DiscountRecipit, TaxRates, ChildComponentThree, TaxRatesDefault, ReducedTax };
