import React, { useState, useEffect } from 'react';
import ToolbarTerminal from '../Terminal/ToolbarTerminal';
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc ,setDoc , query , where} from 'firebase/firestore';
import toast, { Toaster } from 'react-hot-toast';
import './Receipt.css';

const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX"
};

const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Receipts = () => {
    const navigate = useNavigate();
    const [receipts, setReceipts] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddReceiptModal, setShowAddReceiptModal] = useState(false);
    const [newReceiptId, setNewReceiptId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const email = localStorage.getItem('email') || "user@example.com";

    useEffect(() => {
        const fetchReceipts = async () => {
            const email = localStorage.getItem('email'); // Get email from localStorage
            if (!email) return; // Exit if no email is found

            try {
                const receiptsCollection = collection(firestore, 'HoldReceipt');
                const q = query(receiptsCollection, where("AddedBy", "==", email)); // Filter by email
                const querySnapshot = await getDocs(q);
                const fetchedReceipts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setReceipts(fetchedReceipts);
            } catch (error) {
                console.error("Error fetching receipts: ", error);
            }
        };

        fetchReceipts();
    }, []);


    const filteredReceipts = receipts.filter(receipt =>
        receipt.ReceiptNo.toString().includes(searchQuery) && receipt.AddedBy === email
    );

    const handleReceiptClick = (receipt) => {
        setSelectedReceipt(receipt);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedReceipt(null);
    };

    const handleAddItem = (receipt) => {
        navigate(`/Terminal/${receipt.id}`);
    };

    const handleAddNewReceipt = async () => {
        if (newReceiptId) {
            setIsLoading(true);
            try {
                const currentTime = new Date().toISOString();
                const formattedReceiptId = `r-${newReceiptId}`; // Prepend "r-"

                const newReceipt = {
                    AddedBy: email,
                    AddedTime: currentTime,
                    Items: [],
                    ReceiptNo: parseInt(newReceiptId),
                    UpdatedTime: ""
                };

                // Create a reference with a custom ID
                const docRef = doc(collection(firestore, 'HoldReceipt'), formattedReceiptId);

                // Set the document with the custom ID
                await setDoc(docRef, newReceipt);

                setReceipts([...receipts, { id: formattedReceiptId, ...newReceipt }]);
                setNewReceiptId('');
                setShowAddReceiptModal(false);
                toast.success('Receipt added successfully!');
            } catch (error) {
                console.error("Error adding new receipt: ", error);
            } finally {
                setIsLoading(false);
            }
        }
    };


    const handleCloseReceipt = async (receipt) => {
        if (!receipt.id) return;
        try {
            await deleteDoc(doc(firestore, 'HoldReceipt', receipt.id));
            setReceipts(receipts.filter(t => t.id !== receipt.id));
            setShowPopup(false);
            setSelectedReceipt(null);
            toast.success('Receipt closed successfully!');
        } catch (error) {
            console.error("Error closing receipt: ", error);
        }
    };

    return (
        <>
            <Toaster />
            <ToolbarTerminal />
            <div style={{padding:'20px'} }>
            <div className="search-container-receipts">
                <input
                    type="text"
                    placeholder="Search by Receipt ID..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="receipt-container">
                    {filteredReceipts.map(receipt => (
                        <div key={receipt.id} className="receipt-box" onClick={() => handleReceiptClick(receipt)} style={{margin:'19px'} }>
                        {receipt.ReceiptNo}
                    </div>
                ))}
                    <button className="receipt-box add-new" style={{ margin: '19px' }} onClick={() => setShowAddReceiptModal(true)} >
                    <IoMdAdd size={40} />
                </button>
            </div>
            {showAddReceiptModal && (
                <div className="popup-receipts">
                    <div className="popup-content-receipts">
                            <h3>Add New Receipt</h3>
                            {/*<div className="search-container-receipts">*/}

                        <input
                                type="number"
                                placeholder="Enter Receipt Number"
                                value={newReceiptId}
                                onChange={(e) => setNewReceiptId(e.target.value)}
                                style={{ width: '80%' , height:'30px'} }
                                />
                        {/*</div>*/}
                        <button onClick={handleAddNewReceipt}>{isLoading ? 'Adding...' : 'Add Receipt'}</button>
                        <button onClick={() => setShowAddReceiptModal(false)}>Cancel</button>
                    </div>
                </div>
            )}
            {showPopup && selectedReceipt && (
                <div className="popup-receipts">
                    <div className="popup-content-receipts">
                        <h3>{`Receipt ${selectedReceipt.ReceiptNo}`}</h3>
                        <ul>
                            {selectedReceipt.Items.length === 0 ? (
                                <li>No items added yet.</li>
                            ) : (
                                selectedReceipt.Items.map((item, index) => (
                                    <li key={index}>{item.title}</li>
                                ))
                            )}
                        </ul>
                        <button onClick={() => handleAddItem(selectedReceipt)}>Add More Items</button>
                        <button onClick={handleClosePopup}>Close</button>
                        <button onClick={() => handleCloseReceipt(selectedReceipt)}>Close Receipt</button>
                    </div>
                </div>
                )}
            </div>
        </>
    );
};

export default Receipts;
