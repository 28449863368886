import React, { useState } from 'react';
import '../Styles/Login.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Query Firestore for the user by email
      const userRef = collection(firestore, 'AccessUsers');
      const q = query(userRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // If no user found with the given email
        Swal.fire('Error', 'No user found with that email', 'error');
        return;
      }

      // User found, check password and retrieve roles
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      // Check if password and roles exist in the user data
      if (!userData.password || !userData.roles) {
        Swal.fire('Error', 'User data is incomplete', 'error');
        return;
      }

      // Validate the password
      if (userData.password !== password) {
        Swal.fire('Error', 'Incorrect password', 'error');
        return;
      }

      // Successful login
      // Swal.fire('Success', 'Login successful', 'success');

      // Store the roles in localStorage or context (role-based access control)
      const userRoles = userData.roles;
      localStorage.setItem('roles', JSON.stringify(userRoles));
      localStorage.setItem('email', email); // Store email in localStorage
      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      Swal.fire('Error', 'An error occurred while logging in', 'error');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Login</h2>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default Login;
