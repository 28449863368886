import React from 'react';
import '../Pages-4/Coupons.css';

const CouponsPage = () => {
  const coupons = [
    { couponId: 'CPN001', title: 'Discount 10%', instanceId: 'INST123' },
    { couponId: 'CPN002', title: 'Buy One Get One', instanceId: 'INST124' },
    { couponId: 'CPN003', title: 'Free Shipping', instanceId: 'INST125' },
    { couponId: 'CPN004', title: 'Holiday Sale 20%', instanceId: 'INST126' },
  ];

  return (
    <div className="h-coupons-container">
      
      <table className="h-coupons-table">
        <thead>
          <tr>
            <th>Coupon ID</th>
            <th>Title</th>
            <th>Instance ID</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon, index) => (
            <tr key={index}>
              <td>{coupon.couponId}</td>
              <td>{coupon.title}</td>
              <td>{coupon.instanceId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CouponsPage;