import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

// Initialize Firebase app and Firestore
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const ShowUsers = ({ Clicked }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(firestore, 'AccessUsers'); // Replace 'users' with your actual collection name
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => doc.data());
      setUsers(userList);
    };

    fetchUsers();
  }, []);

  return (
    <div style={formStyle}>
      <h1 style={{ textAlign: 'center', color: '#c84347', marginBottom: '20px' }} onClick={Clicked}>
        All Users 
        {/* <strong style={{ fontSize: '20px', fontWeight: '400', textDecoration: 'underline', cursor: 'pointer' }}>
          Add users
        </strong> */}
      </h1>

      <div>
        {users.length > 0 ? (
          users.map((user, index) => (
            <div key={index} style={userCardStyle}>
              <h4>{user.email}</h4>
              <p>Roles: {user.roles ? user.roles.join(', ') : 'No roles assigned'}</p>
              <p>Created at: {user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleString() : 'N/A'}</p>
            </div>
          ))
        ) : (
          <p>No users found.</p>
        )}
      </div>
    </div>
  );
};

export default ShowUsers;

const formStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  marginBottom: '20px',
};

const userCardStyle = {
  backgroundColor: '#f9f9f9',
  padding: '15px',
  marginBottom: '10px',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
};
