import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp, getApps } from "firebase/app";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell } from "docx";
const checkoutFirebaseConfig = {
    apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
    authDomain: "hungrie-5ac9a.firebaseapp.com",
    projectId: "hungrie-5ac9a",
    storageBucket: "hungrie-5ac9a.firebasestorage.app",
    messagingSenderId: "289726512774",
    appId: "1:289726512774:web:0f7757539451129551db07",
    measurementId: "G-S5757JJMHX",
};

const checkoutApp =
    getApps().find((app) => app.name === "checkoutApp") ||
    initializeApp(checkoutFirebaseConfig, "checkoutApp");
const firestore = getFirestore(checkoutApp);

const SaleReport = () => {
    const [orders, setOrders] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [orderSource, setOrderSource] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            const querySnapshot = await getDocs(collection(firestore, "Orders"));
            const orderList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setOrders(orderList);
            setLoading(false);
        };

        fetchOrders();
    }, []);

    const filteredOrders = orders.filter((order) => {
        const orderDate = order.createdAt?.seconds
            ? new Date(order.createdAt.seconds * 1000)
            : null;

        const fromDate = startDate ? new Date(startDate) : null;
        const toDate = endDate ? new Date(endDate) : null;

        const isWithinDateRange =
            (!fromDate || (orderDate && orderDate >= fromDate)) &&
            (!toDate || (orderDate && orderDate <= toDate));

        const isMatchingSource =
            !orderSource || order.From?.toLowerCase() === orderSource.toLowerCase();

        return isWithinDateRange && isMatchingSource;
    });

    const totalSales = filteredOrders.reduce(
        (sum, order) => sum + (order.totalPrice || 0),
        0
    );

    const downloadReport = async () => {
        // Create a table structure
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [
                        // Sales Report Title
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Sales Report",
                                    bold: true,
                                    size: 48,  // Larger size for the title
                                    color: "#c84347",  // Title color
                                    underline: true,  // Underline the title for emphasis
                                }),
                            ],
                            alignment: "center", // Center-align the title
                            spacing: {
                                before: 300,  // Adds space before the title
                                after: 300,   // Adds space after the title
                            },
                        }),

                        // Table header
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph("Order No")],
                                            width: { size: 20, type: "percent" },
                                        }),
                                        new TableCell({
                                            children: [new Paragraph("Date")],
                                            width: { size: 30, type: "percent" },
                                        }),
                                        new TableCell({
                                            children: [new Paragraph("From")],
                                            width: { size: 20, type: "percent" },
                                        }),
                                        new TableCell({
                                            children: [new Paragraph("Total Price")],
                                            width: { size: 20, type: "percent" },
                                        }),
                                        new TableCell({
                                            children: [new Paragraph("Items")],
                                            width: { size: 30, type: "percent" },
                                        }),
                                    ],
                                }),
                                // Map over orders and create rows
                                ...filteredOrders.map((order, index) =>
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(`${index + 1}`)], // Order number
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph(
                                                        new Date(order.createdAt.seconds * 1000).toLocaleString()
                                                    ),
                                                ],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(order.From)],
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph(`PKR ${order.totalPrice || 0}`)
                                                ],
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph(order.items.map((item) => item.title).join(", "))
                                                ],
                                            }),
                                        ],
                                    })
                                ),
                            ],
                        }),

                        // Total Sales (added below the table)
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `Total Sales: PKR ${totalSales}`,
                                    bold: true,
                                    size: 24,
                                    color: "#c84347",  // Highlight total in the primary color
                                }),
                            ],
                            alignment: "center",
                            spacing: {
                                before: 300,  // Adds space before the total sales text
                            },
                        }),
                    ],
                },
            ],
        });

        // Convert the document to a Blob and prompt for download
        const blob = await Packer.toBlob(doc);
        saveAs(blob, "Sales_Report.docx");
    };



    return (
        <div className="bestEverContainer">
            <div style={{ padding: "20px", fontFamily: "Arial", color: "#c84347" }}>
                <h2 className="salesReportTitle">Sales Report</h2>

                <div className="filtersSection">
                    <label>From: </label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="filterInput"
                    />
                    <label style={{ marginLeft: "10px" }}>To: </label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="filterInput"
                    />
                    <label style={{ marginLeft: "10px" }}>Order Source: </label>
                    <select
                        value={orderSource}
                        onChange={(e) => setOrderSource(e.target.value)}
                        className="filterSelect"
                    >
                        <option value="">All</option>
                        <option value="Take-Away">Take-Away</option>
                        <option value="Dinning">Dinning</option>
                        <option value="website">Website</option>
                        <option value="app">App</option>
                    </select>
                </div>

                {loading && <p>Loading orders...</p>}

                {!loading && (
                    <>
                        <table className="ordersTable">
                            <thead>
                                <tr>
                                    <th>Order No</th>
                                    <th>Date</th>
                                    <th>From</th>
                                    <th>Total Price</th>
                                    <th>Items</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map((order, index) => (
                                    <tr key={order.id}>
                                        <td>{index + 1}</td>
                                        <td>{order.createdAt?.seconds ? new Date(order.createdAt.seconds * 1000).toLocaleString() : "N/A"}</td>
                                        <td>{order.From}</td>
                                        <td>PKR{order.totalPrice || 0}</td>
                                        <td>{order.items.map((item) => item.title).join(", ")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Total Sales */}
                        <h2 className="totalSales">Total Sales: PKR  {totalSales}</h2>

                        {/* Download Button */}
                        <button
                            onClick={downloadReport}
                            className="downloadButton"
                        >
                            Download Report
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default SaleReport;
